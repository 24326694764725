import { useContext } from 'react';
import { Icon } from '@mdi/react';
import { useSnackbar } from 'notistack';
import { flow } from 'lodash';
import { mdiAccountRemoveOutline, mdiAccountMultipleCheck, mdiFileReplaceOutline, mdiTrashCanOutline } from '@mdi/js';
import { List, ListItem, ListItemIcon, ListItemText, SvgIcon } from '@material-ui/core';
import * as accessUsersApi from '../../../../api/access-users';
import { useChatActions } from '../../../../utils/useChatActions';
import ChatSquaredSvg from '../../../icons/chat-squared.svg?react';
import { useModal } from '../../../ModalsProvider';
import { ReplaceMemberModal } from '../ReplaceMemberModal';
import { MembersContext } from '../MembersContext';

export const MemberMenu = ({ item, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const { removeMembers, updateMember } = useContext(MembersContext);
  const { openChatWithUser } = useChatActions();

  const handleUpdateUser = (data) => {
    accessUsersApi.updateUser(data).then(({ data: { data } }) => {
      updateMember(data);
      enqueueSnackbar('Member successfully updated!', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Something goes wrong!', { variant: 'error' });
    });
  };

  const replaceUser = (userID) => {
    handleUpdateUser({ ...item, user_id: userID });
  };

  const deactivateUserWithAccess = () => {
    handleUpdateUser({ ...item, is_active: false });
  };

  const activateUserWithoutAccess = () => {
    handleUpdateUser({ ...item, is_active: true });
  };

  const handleUserReplace = () => {
    openModal(ReplaceMemberModal, {
      payload: {
        user: item?.user
      },
      onModalResolved: (data) => {
        replaceUser(data.user_id);
      }
    });
  };

  const removeUserWithAccess = () => {
    accessUsersApi.removeUserWithAccess(item?.id).then(() => {
      removeMembers([ item?.id ]);
      enqueueSnackbar('User removed!', { variant: 'success' });
    });
  };

  const startChatWithUser = () => {
    openChatWithUser(item?.user?.id);
  };

  return (
    <List>
      <ListItem button onClick={flow(handleUserReplace, onClose)}>
        <ListItemIcon>
          <SvgIcon>
            <Icon path={mdiFileReplaceOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Replace User" />
      </ListItem>

      <ListItem button onClick={flow(startChatWithUser, onClose)}>
        <ListItemIcon>
          <SvgIcon><ChatSquaredSvg /></SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Send Message" />
      </ListItem>

      <ListItem
        button
        onClick={flow(item.is_active ? deactivateUserWithAccess : activateUserWithoutAccess, onClose)}
      >
        <ListItemIcon>
          <SvgIcon>
            <Icon path={item?.is_active ? mdiAccountMultipleCheck : mdiAccountRemoveOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary={item.is_active ? 'Deactivate User' : 'Activate User'} />
      </ListItem>

      <ListItem button onClick={flow(removeUserWithAccess, onClose)}>
        <ListItemIcon>
          <SvgIcon>
            <Icon path={mdiTrashCanOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Remove User" />
      </ListItem>
    </List>
  );
};
