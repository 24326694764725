import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { Page, PageBody } from '../../../components/Page';
import { SupportTicketsProvider } from '../../../components/support/SupportTicketsProvider';
import { filtersKeysMap } from '../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { MainContent } from './MainContent';

export const SupportPage = () => {
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('md');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <LayoutContextProvider>
      <SupportTicketsProvider>
        <Box display="flex" flexDirection="column" height="100%" p={isMobile ? 0 : 2}>
          <Page>
            <ActionsBar isMobile={isMobile} filterOpenButton={filterOpenButton} />

            <PageBody fullHeight disablePaddings disableScrollbars>
              <Box height="100%" display="flex" flexDirection="column">
                <ScrollWrapper {...scrollWrapperProps}>
                  <FilterBarWrapper { ...filterBarWrapperProps}>
                    <FiltersBar filterKey={filtersKeysMap.support_tickets} />
                  </FilterBarWrapper>
                </ScrollWrapper>

                <Box flexGrow={1}>
                  <MainContent />
                </Box>
              </Box>
            </PageBody>
          </Page>
        </Box>
      </SupportTicketsProvider>
    </LayoutContextProvider>
  );
};
