import { useState, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import cn from 'classnames';
import { makeStyles, Button, ButtonGroup, SvgIcon, useTheme, useMediaQuery, Box } from '@material-ui/core';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplitOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import { Icon } from '@mdi/react';
import { mdiAccountMultiplePlusOutline, mdiTrashCanOutline } from '@mdi/js';
import { api } from '../../../../../api';
import * as filesApi from '../../../../../api/files';
import { tagsActionsMap } from '../../../../../api/files/tags';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { addSocketJobID, jobsTypes } from '../../../../../store/socketJobs';
import { setFilesLastGlobalAction } from '../../../../../store/globalActions';
import { deleteFilesSuccess } from '../../../../../store/dashboard/files/recentlyOpenedFiles';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { SelectedItemsCount } from '../../../../../components/SelectedItemsCount';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { IconButton } from '../../../../../components/IconButton';
import { AppBar } from '../../../../../components/AppBar';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import * as types from '../../../files-common/FilesContext/types';
import LabelIcon from '../../../../../components/icons/tag-ouline.svg?react';
import {
  FilesFilterContext,
  ShareFileModal,
  ChangeFilesTagsModal,
  FileUploadModal,
  CreateDocumentModal,
  ShareByEmailModal
} from '../../../files-common';
import { RecordVoiceModal } from '../../LeftSidebar/RecordVoiceModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({
  isOpenSidebar = false,
  disableActions = false,
  toggleSideBar = () => {},
  filterOpenButton
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobileDevice = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { filter, selectedFilesIDs } = useContext(FilesFilterContext);
  const [ filesCount, setFilesCount ] = useState();
  const classes = useStyles();
  const cancelFetch = useRef(() => {});
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = width <= theme.breakpoints.values.md;
  const disableCloseButton = !isMobileDevice && isTablet && !isOpenSidebar;

  const uploadFile = () => {
    openModal(FileUploadModal);
  };

  const createDocument = () => {
    openModal(CreateDocumentModal);
  };

  const recordAudio = () => {
    openModal(RecordVoiceModal);
  };

  const fetchFilesCount = () => {
    cancelFetch.current();

    filesApi.fetchFiles({
      params: filter,
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(({ pagination }) => {
      setFilesCount(pagination.total);
    });
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const handleDeleteSelectedFiles = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        filesApi.deleteFiles(selectedFilesIDs).then(() => {
          dispatch(setFilesLastGlobalAction({ type: types.DELETE_FILES, payload: selectedFilesIDs }));
          dispatch(deleteFilesSuccess(selectedFilesIDs));
          enqueueSnackbar(`${selectedFilesIDs.length} file successfully deleted`);
        });
      }
    });
  };

  const shareFiles = () => {
    openModal(ShareFileModal, { payload: { filesIDs: selectedFilesIDs } });
  };

  const getMacros = () => {
    api.get('/macros').then(({ data: { macros } }) => {
      window.navigator.clipboard.writeText(macros);
      enqueueSnackbar('Macros was copied', { variant: 'success' });
    });
  };

  const shareByEmail = () => {
    openModal(ShareByEmailModal, {
      onModalResolved: (data) => {
        filesApi.shareByEmail({
          share_tag_id: data?.data?.additional_data?.tag?.id,
          files: selectedFilesIDs
        }).then(() => {
          dispatch(setFilesLastGlobalAction({
            type: types.CHANGE_FILES_TAGS,
            payload: {
              filesIDs: selectedFilesIDs,
              tags: [ data?.data?.additional_data?.tag ],
              actionType: tagsActionsMap.add
            }
          }));

          enqueueSnackbar(`File${selectedFilesIDs.length > 1 ? 's' : ''} successfully shared`, { variant: 'success' });
        });
      }
    });
  };

  const handleDownloadSelectedFiles = () => {
    filesApi.createArchive({ files: selectedFilesIDs }).then(({ job_id }) => {
      dispatch(addSocketJobID({ type: jobsTypes.archives, jobID: job_id }));
    });
  };

  const handleChangeTags = () => {
    openModal(ChangeFilesTagsModal, { payload: { filesIDs: selectedFilesIDs } });
  };

  useEffect(() => {
    selectedFilesIDs.length && fetchFilesCount();

    return () => {
      cancelFetch.current();
    };
  }, [ selectedFilesIDs, filter ]);

  return (
    <AppBar
      ref={rootRef}
      ToolbarProps={{
        className: cn(
          classes.root,
          { [classes.rootSM]: isMobile }
        )
      }}
    >
      {disableCloseButton && (
        <Box pr={2}>
          <IconButton color="primary" onClick={toggleSideBar}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      )}

      {!disableActions &&
        <ButtonGroup variant="outlined" color="primary">
          <Button
            className={classes.button}
            onClick={uploadFile}
            startIcon={!isTablet && <CloudUploadIcon />}
          >
            {isTablet ? <CloudUploadIcon /> : 'Upload file'}
          </Button>

          <Button
            className={classes.button}
            onClick={createDocument}
            startIcon={!isTablet && <AddIcon />}
          >
            {isTablet ? <AddIcon /> : 'Create document'}
          </Button>

          {!!navigator.mediaDevices?.getUserMedia &&
            <Button
              className={classes.button}
              onClick={recordAudio}
              startIcon={!isTablet && <RecordVoiceOverIcon />}
            >
              {isTablet ? <RecordVoiceOverIcon /> : 'Record audio'}
            </Button>
          }

          <Button
            className={classes.button}
            onClick={getMacros}
            startIcon={!isTablet && <SyncOutlinedIcon />}
          >
            {isTablet ? <SyncOutlinedIcon /> : 'Get macros'}
          </Button>
        </ButtonGroup>
      }

      <div className={cn(classes.rightSide, { [classes.rightSideSM]: isMobile })}>
        {!!selectedFilesIDs.length &&
          <div className={classes.multiActions}>
            <SelectedItemsCount total={filesCount} length={selectedFilesIDs.length} />

            <VerticalDivider verticalGutters={isTablet ? 0 : 1} horizontalGutters={2} />

            <IconButton
              edge="start"
              title="Download selected files"
              onClick={handleDownloadSelectedFiles}
            >
              <SvgIcon>
                <DownloadIcon />
              </SvgIcon>
            </IconButton>

            <IconButton
              title="Share"
              onClick={shareFiles}
            >
              <SvgIcon>
                <Icon path={mdiAccountMultiplePlusOutline} />
              </SvgIcon>
            </IconButton>

            <IconButton
              title="Share by email"
              onClick={shareByEmail}
            >
              <MailOutlinedIcon />
            </IconButton>

            <IconButton
              title="Change tags"
              onClick={handleChangeTags}
            >
              <SvgIcon>
                <LabelIcon />
              </SvgIcon>
            </IconButton>

            <IconButton
              edge="end"
              title="Delete selected files"
              onClick={handleDeleteSelectedFiles}
            >
              <SvgIcon>
                <Icon path={mdiTrashCanOutline} />
              </SvgIcon>
            </IconButton>

            {!isTablet && <VerticalDivider verticalGutters={1} horizontalGutters={2} />}
          </div>
        }

        {isTablet && (
          <Box py={0.25} display="flex" justifyContent="flex-end" alignItems="center" flexGrow={1} mr={-1}>
            {filterOpenButton}
          </Box>
        )}

        {!isTablet && (
          <>
            <IconButton
              edge="start"
              color={viewVariant === viewVariantsMap.groups ? 'primary' : 'default'}
              title="Groups view"
              onClick={toggleViewVariant(viewVariantsMap.groups)}
            >
              <HorizontalSplitIcon />
            </IconButton>

            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              title="List view"
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon />
            </IconButton>

            <IconButton
              color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
              title="Grid view"
              onClick={toggleViewVariant(viewVariantsMap.grid)}
            >
              <ViewModuleIcon />
            </IconButton>
          </>
        )}
      </div>
    </AppBar>
  );
};
