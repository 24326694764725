import cn from 'classnames';
import { LinearProgress, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { amFormatDuration } from '../../../../../helpers/dates';
import ChatSvg from '../../../../../components/icons/chat.svg?react';
import TimerClock from '../../../../../components/icons/timer-clock.svg?react';
import { AttachmentIndicator } from '../AttachmentIndicator';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ProgressBar = ({ isDueDateProgress, gridProgress, logged, estimate, files_count, comments_count }) => {
  const classes = useStyles();
  const log = logged ? amFormatDuration(logged, 'seconds', 'h[h] m[min]') : '0h';
  const hours = estimate ? amFormatDuration(estimate, 'seconds', 'h[h] m[min]') : '0h';
  const progress = ~~(logged / estimate * 100 );

  const estimateTime = hours.split(' ').filter((item) => parseInt(item) > 0).join(' ');
  const logTime = log.split(' ').filter((item) => parseInt(item) > 0).join(' ');

  return (
    <div className={classes.root} style={{ width: !gridProgress ? '90%' : 'inherit' }}>

      {gridProgress && (

        <div className={classes.title__gridProgress}>
          {comments_count > 0 &&
            <AttachmentIndicator
              icon={<ChatSvg />}
              title={comments_count}
              className={classes.attachmentIndicator}
            />
          }

          {files_count > 0 &&
            <AttachmentIndicator
              icon={<AttachFileIcon />}
              title={files_count}
              className={classes.attachmentIndicator}
              classes={{ icon: classes.attachmentIndicator__taskIcon }}
            />
          }
        </div>
      )}

      <Typography
        className={
          cn(
            classes.title,
            {
              [classes.title_initial]: progress < 70,
              [classes.title_medium]: (progress >= 70) && (progress < 100),
              [classes.title_full]: progress === 100,
              [classes.title_over]: progress > 100
            }
          )
        }
      >
        {!isDueDateProgress ?
          <>
            {gridProgress &&
              <SvgIcon className={classes.iconTimer}>
                <TimerClock />
              </SvgIcon>
            }

            {logTime ? logTime : 0 + 'h'} / {estimateTime ? estimateTime : 0 + 'h'}
          </>
          :
          progress + '%'
        }
      </Typography>

      <LinearProgress
        variant="determinate"
        value={progress < 100 ? progress : 100}
        className={classes.progress}
        classes={{
          barColorPrimary: cn(
            {
              [classes.bar_initial]: progress < 70,
              [classes.bar_medium]: (progress >= 70) && (progress < 100),
              [classes.bar_full]: progress === 100,
              [classes.bar_over]: progress > 100
            }
          )
        }}
      />
    </div>
  );
};
