import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_CASE_REQUEST]: (state) => {
    return {
      ...state,

      caseItem: {},
      isFetched: false
    };
  },

  [types.FETCH_CASE_SUCCESS]: ({ caseItem, ...state }, payload) => {
    return {
      ...state,

      caseItem: payload,
      isFetched: true
    };
  },

  [types.UPDATE_CASE]: ({ caseItem, ...state }, payload) => {
    return {
      ...state,

      caseItem: {
        ...caseItem,
        ...payload
      }
    };
  }
});
