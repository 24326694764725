import { Box } from '@material-ui/core';
import Changelog from '../CHANGELOG.md';

export const ChangelogPage = () => {
  return (
    <Box maxWidth={900} m="auto" p={2}>
      <Changelog />
    </Box>
  );
};
