import { withStyles } from '@material-ui/core';

export const GlobalCss = withStyles(({ palette, spacing, zIndex }) => {
  const light = palette.type === 'light';

  return {
    '@global': {
      '[class*="MuiFilledInput-root"]': {
        backgroundColor: light ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255, 255, 255, 0.09)'
      },

      '.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
        padding: spacing(0.5)
      },

      '.MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
        paddingBottom: 0
      },

      '.mdxeditor-popup-container': {
        zIndex: zIndex.modal
      }
    }
  };
})(() => null);
