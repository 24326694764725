export const getAddressComponents = (components) => {
  const result = {};

  components?.forEach(({ long_name, short_name, types }) => {
    if (types.includes('postal_code')) {
      result.zip = short_name;
    } else if (types.includes('country')) {
      result.country = short_name;
    } else if (types.includes('neighborhood')) {
      result.city = long_name;
    } else if (types.includes('administrative_area_level_3')) {
      result.city = long_name;
    } else if (types.includes('locality')) {
      result.city = long_name;
    } else if (types.includes('administrative_area_level_1')) {
      result.state = short_name;
    } else if (types.includes('route')) {
      result.street = long_name;
    } else if (types.includes('street_number')) {
      result.street_number = short_name;
    }
  });

  return result;
};

export const transformPlaceDetailsToAddress = (placeDetails) => {
  const location = placeDetails?.geometry?.location;

  return {
    street_number: '',
    street: '',
    state: '',
    country: '',
    city: '',
    zip: '',
    lat: location?.lat ? '' + location?.lat() : null,
    lng: location?.lng ? '' + location?.lng() : null,

    ...getAddressComponents(placeDetails?.address_components)
  };
};
