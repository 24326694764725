import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { downloadFile } from '../../../../../helpers/files';
import { getReport } from '../../../../../api/custom-report';
import { Button } from '../../../../../components/Button';
import { Loader } from '../../../../../components/Loader';

export const UsageReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [ isLoading, setIsLoading ] = useState(false);
  const { getInputProps, open } = useDropzone({
    multiple: false,
    accept: [ 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ],
    onDrop: ([ file ]) => {
      const formData = new FormData();

      setIsLoading(true);

      formData.append('file', file);

      getReport(formData).then(({ url }) => {
        downloadFile({ src: url });
      }).catch(() => {
        enqueueSnackbar('File is not valid, try another one please, or contact with the support', { variant: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  });

  return (
    <>
      <input {...getInputProps()} />

      <Loader surface fullWidth loading={isLoading} render={
        () => (
          <Button fullWidth variant="contained" color="secondary" onClick={open}>
            Usage report
          </Button>
        )}
      />
    </>
  );
};
