import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  SvgIcon,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Button, makeStyles
} from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { UserAvatar } from '../../../../../components/users';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { getUserFullName } from '../../../../../helpers/users';
import { hasRole } from '../../../../../utils/hasRole';
import { logout } from '../../../../../store/auth/actions';
import ChatSquaredSvg from '../../../../../components/icons/chat-squared.svg?react';
import { Popper } from '../../../../../components/Popper';
import { useModal } from '../../../../../components/ModalsProvider';
import { LoginAsModal } from './LoginAsModal';

const useStyles = makeStyles({
  userName: {
    textTransform: 'none'
  }
});

export const ProfileMenu = ({ toggleChatWidget }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { openModal } = useModal();
  const isMobileXs = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileXs));
  const isMobileSm = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileSm));
  const currentUser = useSelector(({ profile }) => profile.user);

  const handleLoginAs = () => {
    openModal(LoginAsModal, {
      onModalResolved: ({ token, user }) => {

      }
    });
  };

  const handleLogout = () => {
    dispatch(logout());
    history.push('/');
  };

  return (
    <Popper anchorRenderer={
      ({ anchorRef, handleToggle }) => isMobileSm ? (
        <UserAvatar
          disableOnlineIndicator
          ref={anchorRef}
          size="xs"
          user={currentUser}
          onClick={handleToggle}
        />
      ) : (
        <Button
          buttonRef={anchorRef}
          color="inherit"
          className={classes.userName}
          startIcon={
            <UserAvatar
              disableOnlineIndicator
              size="xs"
              user={currentUser}
            />
          }
          onClick={handleToggle}
        >
          {getUserFullName(currentUser)}
        </Button>
      )}
    >
      {({ handleClose }) => (
        <List onClick={handleClose}>
          <MenuItem
            component={Link}
            to="/profile"
          >
            <ListItemIcon>
              <AccountCircleOutlinedIcon/>
            </ListItemIcon>

            <ListItemText primary="Profile" />
          </MenuItem>

          {isMobileXs && (
            <MenuItem onClick={toggleChatWidget}>
              <ListItemIcon>
                <SvgIcon>
                  <ChatSquaredSvg />
                </SvgIcon>
              </ListItemIcon>

              <ListItemText primary="Chat" />
            </MenuItem>
          )}

          {hasRole(rolesMap.admin) &&
            <MenuItem onClick={handleLoginAs}>
              <ListItemIcon>
                <SupervisorAccountOutlinedIcon />
              </ListItemIcon>

              <ListItemText primary="Login As" />
            </MenuItem>
          }

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>

            <ListItemText primary="Logout" />
          </MenuItem>
        </List>
      )}
    </Popper>
  );
};
