import { useState, createContext, useContext, useEffect, useReducer } from 'react';
import { isEqual } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as types from '../FilesContext/types';
import { initialState } from './initialState';
import { orderByOptions } from './orderByOptions';
import { reducer } from './reducer';

export const FilesFilterContext = createContext();

const mapStateToProps = ({
  globalActions: { filesLastGlobalAction }
}) => ({
  filesLastGlobalAction
});

const enhancer = compose(
  connect(mapStateToProps)
);

export const FilesFilterContextProvider = enhancer(({
  children,
  disableFileSelection = false,
  isSingleSelection = false,
  filesLastGlobalAction,

  ...props
}) => {
  const filesFilterContext = useContext(FilesFilterContext);
  const [ { selectedFilesIDs }, dispatch ] = useReducer(reducer, { ...initialState, isSingleSelection });
  const [ filter, setFilter ] = useState({
    ...initialState.filter,
    ...props.filter
  });
  const [ relationsForFilter, setRelationsForFilter ] = useState({
    tags: [],
    cases: [],
    tasks: [],
    users: [],
    order_by: orderByOptions.find(({ value }) => value === filter.order_by)
  });

  const applyFilter = (newFilter) => {
    setFilter((filter) => ({ ...filter, ...newFilter }));
  };

  const addFilesToSelected = ((filesIDs) => {
    dispatch({ type: types.ADD_FILES_TO_SELECTED, payload: filesIDs });
  });

  const deleteFilesFromSelected = ((filesIDs) => {
    dispatch({ type: types.DELETE_FILES_FROM_SELECTED, payload: filesIDs });
  });

  const toggleFileSelection = (fileID) => {
    if (selectedFilesIDs.includes(fileID)) {
      deleteFilesFromSelected([ fileID ]);
    } else {
      addFilesToSelected([ fileID ]);
    }
  };

  const providerValue = {
    disableFileSelection,
    isSingleSelection,
    relationsForFilter,
    selectedFilesIDs,

    setFilter,
    applyFilter,
    setRelationsForFilter,
    addFilesToSelected,
    deleteFilesFromSelected,
    toggleFileSelection,

    ...filesFilterContext,

    filter
  };

  useEffect(() => {
    filesLastGlobalAction && dispatch(filesLastGlobalAction);
  }, [ filesLastGlobalAction ]);

  useEffect(() => {
    if (!isEqual(filter, { ...filter, ...props.filter })) {
      setFilter({ ...filter, ...props.filter });
    }
  }, [ props.filter ]);

  return (
    <FilesFilterContext.Provider value={providerValue}>
      {children}
    </FilesFilterContext.Provider>
  );
});
