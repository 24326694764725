import {
  Checkbox,
  makeStyles,
  SvgIcon,
  Typography
} from '@material-ui/core';
import UserIcon from '@material-ui/icons/PermIdentity';
import { preventDefault } from '../../../../../../../../helpers/dom';
import TrackerPlusSvg from '../../../../../../../../components/icons/chronometer.svg?react';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { Date } from '../../../../../../../../components/Date';
import { CaseModel } from '../../../../InvoicesPage/InvoicesContent/CaseModel';
import { minRowHeight, columnsWidths } from '../TracksList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  track,
  isSelected,
  handleItemSelection,
  trackTime,
  trackRate
}) => {
  const classes = useStyles();

  return (
    <ListRow selected={isSelected} className={classes.root} minHeight={minRowHeight}>
      <ListRowCell flexBasis={columnsWidths.action}>
        <Checkbox
          className={classes.checkbox}
          checked={isSelected}
          onClick={preventDefault(handleItemSelection(track))}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.type}>
        {track.type ?
          <UserIcon className={classes.trackTypeIcon} />
          :
          <SvgIcon className={classes.trackTypeIcon}>
            <TrackerPlusSvg />
          </SvgIcon>
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.range_date}>
        <UserLink
          noWrap
          variant="h5"
          user={track.user}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.caseName}>
        <CaseModel
          variant="body1"
          color="textPrimary"
          model={track?.models?.case || {}}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.category}>
        <Typography
          noWrap
          color={!track?.category?.name ? 'textSecondary' : 'textPrimary'}
        >
          {track?.category?.name || 'No info.'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.notes}>
        <Tooltip isExistTooltip={!!track?.description} title={track?.description}>
          <Typography
            noWrap
            color={!track?.description ? 'textSecondary' : 'textPrimary'}
          >
            {track?.description || 'No info.'}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.finished_at}>
        <Date disableIcon date={track.started_at} format="L LT" />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.finished_at}>
        <Date disableIcon date={track.finished_at} format="L LT" />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        <Typography className={classes.dateContent}>
          {trackTime}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.rate}>
        {trackRate
          ? <CurrencyFormat value={trackRate} />
          : 'n/a'
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.billing_rate}>
        {!!track.billing_total
          ? <CurrencyFormat value={track.billing_total} />
          : 'n/a'
        }
      </ListRowCell>
    </ListRow>
  );
};
