import moment from 'moment';
import { useState } from 'react';
import { useModal as useModalHook } from 'react-modal-hook';
import { saveAs } from 'file-saver';
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import * as aiApi from '../../../../api/ai';
import { Loader } from '../../../../components/Loader';
import { Tooltip } from '../../../../components/Tooltip';
import { FilePreviewModal } from '../FilePreviewModal';
import { typeLabelsMap } from './typesOptions';

const actionsMap = {
  downloadAsPdf: 'downloadAsPdf',
  downloadAsDocx: 'downloadAsDocx'
};

export const Result = ({ result }) => {
  const [ activeActions, setActiveActions ] = useState([]);
  const finishedAt = moment.utc(result.finished_at).local().format('L LT');
  const [ openTextFilePreview, closeTextFilePreview ] = useModalHook(({ in: open, onExited }) => {
    const blob = new Blob([ result.result ], {
      type : 'text/plain;charset=utf-8'
    });

    return (
      <FilePreviewModal
        DialogProps={{
          open,
          onExited,
          onCancel: closeTextFilePreview
        }}
        payload={{
          disableFetch: true,
          file: {
            id: {},
            extension: 'md',
            url: URL.createObjectURL(blob),
            aggregate_type: 'text'
          },
          singleView: true
        }}
      />
    );
  }, [ result.result ]);


  const addActionToActive = (action) => {
    setActiveActions((activeActions) => activeActions.concat(action));
  };

  const removeActionFromActive = (removableAction) => {
    setActiveActions((activeActions) => activeActions.filter((action) => action !== removableAction));
  };

  const downloadPdf = () => {
    addActionToActive(actionsMap.downloadAsPdf);

    aiApi.downloadPdf(result.id).then(({ data }) => {
      const blob = new Blob([ data ]);

      saveAs(blob, `${finishedAt}.pdf`);
    }).finally(() => {
      removeActionFromActive(actionsMap.downloadAsPdf);
    });
  };

  const downloadDocx = () => {
    addActionToActive(actionsMap.downloadAsDocx);

    aiApi.downloadDocx(result.id).then(({ data }) => {
      const blob = new Blob([ data ]);

      saveAs(blob, `${finishedAt}.docx`);
    }).finally(() => {
      removeActionFromActive(actionsMap.downloadAsDocx);
    });
  };

  return (
    <ListItem button disableGutters onClick={openTextFilePreview}>
      <ListItemText secondary={finishedAt}>
        {typeLabelsMap[result.type]}
      </ListItemText>

      <ListItemSecondaryAction>
        <Loader
          surface
          loading={activeActions.includes(actionsMap.downloadAsPdf)}
          render={() => (
            <Tooltip title="Download as PDF">
              <span>
                <IconButton
                  disabled={activeActions.includes(actionsMap.downloadAsPdf)}
                  onClick={downloadPdf}
                >
                  <PictureAsPdfOutlinedIcon/>
                </IconButton>
              </span>
            </Tooltip>
          )}
        />

        <Loader
          surface
          loading={activeActions.includes(actionsMap.downloadAsDocx)}
          render={() => (
            <Tooltip title="Download as DOCX">
              <span>
                <IconButton
                  disabled={activeActions.includes(actionsMap.downloadAsDocx)}
                  edge="end"
                  onClick={downloadDocx}
                >
                  <DescriptionOutlinedIcon/>
                </IconButton>
              </span>
            </Tooltip>
          )}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
