import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import * as chatsApi from '../../../api/chat';
import { Yup } from '../../../utils/validation';
import ChatSquaredSvg from '../../icons/chat-squared.svg?react';
import { ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { Loader } from '../../Loader';
import { AddChatModalBody } from './AddChatModalBody';

const initialValues = {
  name: null,
  description: null,
  users: null
};

const validationSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  description: Yup.string().nullable(),
  users: Yup.mixed().nullable().required()
});

export const AddChatModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    initialUsers = null
  }
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const createChat = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.users = values.users?.map((id) => ({ id }));

    return chatsApi.createThread(values).then((thread) => {
      enqueueSnackbar('Chat successfully created', { variant: 'success' });
      history.push(`/messenger/${thread.id}`);
      handleModalResolve(thread);
    }).catch(({ status, data: { errors } = {} }) => {
      enqueueSnackbar('Something goes wrong!', { variant: 'error' });

      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Dialog PaperProps={{ style: { maxWidth: 750 } }} {...DialogProps}>
      <Formik
        initialValues={{
          ...initialValues,

          users: initialUsers
        }}
        validationSchema={validationSchema}
        onSubmit={createChat}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader icon={<SvgIcon><ChatSquaredSvg /></SvgIcon>} onClose={handleModalReject}>
              Create a chat
            </ModalHeader>

            <AddChatModalBody />

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
