import { useContext, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import T from 'prop-types';
import { makeStyles, Toolbar, IconButton, Box } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { PrintConfirmationModal } from '../../../../../components/PrintConfirmationModal';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { FilePreviewContext } from '../FilePreviewContext';
import { Preview } from './Preview';
import { ActionsBar } from './ActionsBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  onModalClose: T.func.isRequired
};

export const PreviewWindow = ({
  singleView,
  files,
  previewMenuOptions = () => {},
  getFileMenuOptions = () => {},
  onModalClose
}) => {
  const modalIsOpened = useSelector(({ printFile: { isOpen } }) => isOpen);
  const { selectedFile, setViewWidth, handleFileSelect } = useContext(FilePreviewContext);
  const classes = useStyles();
  const fileIndex = files.findIndex(({ id }) => id === selectedFile?.id);
  const hasPreviousFile = fileIndex > 0;
  const hasNextFile = fileIndex + 1 < files.length;
  const rootRef = useRef();
  const actionsBarPortalRef = useRef();
  const topLayerPortalRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const menuList = getFileMenuOptions(selectedFile) || previewMenuOptions(selectedFile);
  const showFullScreenPreview = (
    selectedFile?.disk === 'sharepoint'
    || selectedFile.aggregate_type === 'image'
    || [ 'xls', 'xlsx', 'doc', 'docx' ].includes(selectedFile.extension)
  );

  const toggleToPreviousFile = () => {
    if (hasPreviousFile) {
      handleFileSelect(files[fileIndex - 1]);
    }
  };

  const toggleToNextFile = () => {
    if (hasNextFile) {
      handleFileSelect(files[fileIndex + 1]);
    }
  };

  useEffect(() => {
    width && setViewWidth(width);
  }, [ width ]);

  return (
    <Box ref={rootRef} height="100%" width="100%" display="flex" overflow="hidden">
      <ActionsBar
        actionsBarPortalRef={actionsBarPortalRef}
        singleView={singleView}
        menuList={menuList}
        onClickModalClose={onModalClose}
      />

      <Scrollbars autoHide>
        <div className={cn(classes.preview, showFullScreenPreview && classes.preview_image)}>
          <Toolbar />

          {actionsBarPortalRef.current && (
            <Preview
              actionsBarPortalElement={actionsBarPortalRef.current}
              topLayerPortalElement={topLayerPortalRef.current}
            />
          )}
        </div>
      </Scrollbars>

      <div ref={topLayerPortalRef} />

      {!singleView && hasPreviousFile &&
        <IconButton className={classes.prevButton} onClick={toggleToPreviousFile}>
          <KeyboardArrowLeftIcon/>
        </IconButton>
      }

      {!singleView && hasNextFile &&
        <IconButton className={classes.nextButton} onClick={toggleToNextFile}>
          <KeyboardArrowRightIcon/>
        </IconButton>
      }

      <PrintConfirmationModal open={modalIsOpened} />
    </Box>
  );
};

PreviewWindow.propTypes = propTypes;
