import { forwardRef } from 'react';
import cn from 'classnames';
import { Scrollbar } from 'react-scrollbars-custom';
import { makeStyles } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CustomScrollbars = forwardRef(({
  translateContentSizesToHolder,
  scrollerRef,
  scrollerProps: { elementRef: scrollerElementRef = () => {}, ...scrollerProps } = {},
  style: styleProp = {},

  ...props
}, ref) => {
  const classes = useStyles();
  const style = { height: null, ...styleProp };

  return (
    <Scrollbar
      ref={ref}
      style={style}
      translateContentSizesToHolder={translateContentSizesToHolder}
      contentProps={!translateContentSizesToHolder ? {} : {
        renderer: ({ key, elementRef, style, ...restProps }) => {
          return <div {...restProps} key={key} ref={elementRef} />;
        }
      }}

      wrapperProps={{
        renderer: ({ key, elementRef, ...restProps }) => {
          return <div {...restProps} key={key} ref={elementRef} />;
        }
      }}

      scrollerProps={{
        ...scrollerProps,

        elementRef: (ref) => {
          scrollerElementRef(ref);

          if (scrollerRef) {
            scrollerRef.current = ref;
          }
        }
      }}

      trackXProps={{
        renderer: ({ elementRef, ...restProps }) => {
          return <div {...restProps} ref={elementRef} className={cn(classes.track, classes.track_horizontal)} />;
        }
      }}

      trackYProps={{
        renderer: ({ elementRef, ...restProps }) => {
          return <div {...restProps} ref={elementRef} className={cn(classes.track, classes.track_vertical)} />;
        }
      }}

      thumbXProps={{
        renderer: ({ elementRef, ...restProps }) => {
          return <div {...restProps} ref={elementRef} className={classes.thumb} />;
        }
      }}

      thumbYProps={{
        renderer: ({ elementRef, ...restProps }) => {
          return <div {...restProps} ref={elementRef} className={classes.thumb} />;
        }
      }}

      {...props}
    />
  );
});
