import { Formik } from 'formik';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import * as tagsApi from '../../../api/files/tags';
import LabelEditSvg from '../../icons/label-edit.svg?react';
import { ModalFooter, ModalHeader, ModalBody } from '../../Modal';
import { TextField, ColorPicker } from '../../FormField';
import { Loader } from '../../Loader';
import { validationSchema } from '../validationSchema';

export const UpdateTagModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { tag }
}) => {
  const updateTag = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    tagsApi.updateTag({ ...values, position: 1, level: 1 }).then(({ data }) => {
      handleModalResolve(data);
    }).catch(({ errors }) => {
      setSubmitting(false);
      setErrors(errors);
    });
  };

  return (
    <Formik
      initialValues={tag}
      validationSchema={validationSchema}
      onSubmit={updateTag}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={tag.is_folder ? <FolderOutlinedIcon /> : <SvgIcon><LabelEditSvg /></SvgIcon>}
              onClose={handleModalReject}
            >
              Edit {tag.is_folder ? 'folder' : 'tag'}
            </ModalHeader>

            <ModalBody>
              <TextField
                required
                name="name"
                label={`${tag.is_folder ? 'Folder' : 'Tag'} name`}
                placeholder="Enter name..."
                margin="dense"
              />

              <ColorPicker
                required
                name="color"
                label="Color"
                placeholder="Select color..."
              />
            </ModalBody>

            <ModalFooter>
              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    autoFocus
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
