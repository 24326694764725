import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../InfiniteLoader';
import { SavedFiltersContext } from '../../../../saved-filters';
import { Scrollbars } from '../../../../Scrollbars';
import { Loader } from '../../../../Loader';
import { ListHeader } from './ListHeader';
import { Row } from './Row';

export const columnsNamesMap = {
  cases: 'cases',
  tasks: 'tasks',
  users: 'users',
  offices: 'offices',
  action: 'action'
};

export const columnsWidths = {
  [columnsNamesMap.cases]: 200,
  [columnsNamesMap.tasks]: 200,
  [columnsNamesMap.users]: 180,
  [columnsNamesMap.offices]: 200,
  [columnsNamesMap.action]: 52
};

export const List = ({ onApplySavedFilter, hiddenColumns }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No saved filters</Typography>
        ) : (
          <>
            <ListHeader hiddenColumns={hiddenColumns} />

            <MuiList disablePadding>
              <InfiniteListLoader
                items={savedFilters}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ onApplySavedFilter, hiddenColumns }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
