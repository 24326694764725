import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import * as casesApi from '../../../../../../../../api/cases';
import { CaseUsersSelect } from '../../../../../../../../components/cases/CaseUsersSelect';
import { setCasesLastGlobalAction } from '../../../../../../../../store/globalActions';
import { KeyboardDatePicker } from '../../../../../../../../components/FormField';
import EditIcon from '../../../../../../../../components/icons/square-edit-icone.svg?react';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../../../../../components/Modal';
import { Loader } from '../../../../../../../../components/Loader';
import * as types from '../../../../CasesContext/types';

export const CaseReOpenModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    caseItem = {},
    parent
  }
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    re_open: {
      case_id: caseItem.id,
      authorized_user_id: caseItem?.reopen_log?.authorized_user,
      reopened_at: caseItem?.reopen_log?.reopened_at || null
    },

    re_close: {
      id: caseItem.reopen_log && caseItem.reopen_log.id,
      case_id: caseItem.id,
      authorized_user_id: caseItem.reopen_log && caseItem.reopen_log.authorized_user.id,
      reclosed_user_id: caseItem?.reopen_log?.reclosed_user,
      reclosed_at: caseItem?.reopen_log?.reclosed_at || null
    }
  };
  const reOpen = 're_open';

  const createLog = (caseData, { setSubmitting, setErrors }) => {
    casesApi.createLog(caseData).then(({ data }) => {
      dispatch(setCasesLastGlobalAction({
        type: types.UPDATE_CASE_IN_LIST,
        payload: { ...caseItem, reopen_log: data }
      }));
      enqueueSnackbar('Case successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ data: { errors } = {} }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case not updated', { variant: 'error' });
      setSubmitting(false);
    });
  };

  const updateLog = (caseData, { setSubmitting, setErrors }) => {
    casesApi.updateLog(caseData).then(({ data }) => {
      dispatch(setCasesLastGlobalAction({
        type: types.UPDATE_CASE_IN_LIST,
        payload: { ...caseItem, reopen_log: data }
      }));
      enqueueSnackbar('Case successfully updated', { variant: 'success' });
      handleModalResolve();
    }).catch(({ data: { errors } = {} }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case not updated', { variant: 'error' });
      setSubmitting(false);
    });
  };

  const updateCase = (caseData, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return reOpen === parent ?
      createLog(caseData, { setSubmitting, setErrors })
      :
      updateLog(caseData, { setSubmitting, setErrors });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={initialValues[parent]}
        onSubmit={updateCase}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              {parent === reOpen
                ? 'Edit Case Re-Opened information'
                : 'Edit Case Re-Closed information'
              }
            </ModalHeader>

            <ModalBody>
              <KeyboardDatePicker
                name={parent === reOpen ? 'reopened_at' : 'reclosed_at'}
                label={parent === reOpen ? 'Date Case Re-Opened' : 'Date Case Re-Closed'}
                margin="dense"
              />

              <CaseUsersSelect
                name={parent === reOpen ? 'authorized_user_id' : 'reclosed_user_id'}
                label={parent === reOpen ? 'Authorized By (Adjuster Name)' : 'Case Re-Closed By (Employee Name)'}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
