import { forwardRef, useEffect, useRef } from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import { ScrollbarsCustom } from '../../ScrollbarsCustom';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export const ListBox = forwardRef(({
  children,
  className,
  onMount,
  onScroll,

  ...props
}, ref) => {
  const classes = useStyles();
  const innerRef = useRef(null);

  const setRef = (node) => {
    innerRef.current = node;
    ref?.(node);
  };

  useEffect(() => {
    onMount(innerRef?.current);
  }, []);

  return (
    <div ref={setRef} className={cn(classes.root, className)} {...props}>
      <ScrollbarsCustom autoHeight ref={ref} onScroll={onScroll} style={{ flexGrow: 1 }}>
        {children}
      </ScrollbarsCustom>
    </div>
  );
});
