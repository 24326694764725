import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { caseInsuranceMap } from '../../../../../../../dataMaps';
import { setCasesLastGlobalAction } from '../../../../../../../store/globalActions';
import * as casesApi from '../../../../../../../api/cases';
import EditIcon from '../../../../../../../components/icons/square-edit-icone.svg?react';
import { Loader } from '../../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../../components/Modal';
import * as types from '../../../CasesContext/types';
import { validationSchema } from './validationSchema';

export const WidgetModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    maxWidth = 500,
    caseItem = {},
    onUpdate = () => {},
    Component,
    title,
    parentPage
  }
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = { substituted_out: Array.isArray(caseItem.substituted_out) ? {} : caseItem.substituted_out };

  const handleUpdate = (value, setErrors, setSubmitting, setValues) => {
    casesApi.updateCase({ id: caseItem.id, ...value }).then((data) => {
      dispatch(setCasesLastGlobalAction({ type: types.UPDATE_CASE_IN_LIST, payload: data }));
      onUpdate(data);
      setValues(data);
      enqueueSnackbar('Case successfully updated', { variant: 'success' });
      handleModalResolve(data);
      setSubmitting(true);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case not updated', { variant: 'error' });
      setSubmitting(false);
    });
  };

  const updateCase = (caseData, { isSubmitting, setSubmitting, setErrors, setValues, resetForm }) => {
    if (isSubmitting) {
      return;
    }

    caseItem.dirreff_attorney = ~~caseItem.dirreff_attorney;
    caseData.subbed_out = !!+caseData.subbed_out;

    if (parentPage && caseData.subbed_out) {
      if (caseData.substituted_out) {
        // eslint-disable-next-line max-len
        if (!caseData.substituted_out.firm_name || !caseData.substituted_out.address || !caseData.substituted_out.phone) {
          enqueueSnackbar('You need set substituted out information', { variant: 'warning' });
          setSubmitting(false);
          resetForm();
        } else {
          handleUpdate(caseData, setSubmitting, setErrors, setValues);
        }
      }
    } else {
      handleUpdate(caseData, setSubmitting, setErrors, setValues);
    }
  };

  return (
    <Formik
      initialValues={{
        claim_insurance: {
          claims_insurance_company_id: null,

          ...caseItem.claim_insurance_information,

          examiner_id: caseItem?.claim_insurance_information?.examiner,
          phone: caseItem?.claim_insurance_information?.examiner?.phone,
          email: caseItem?.claim_insurance_information?.examiner?.email,
          office_id: caseItem?.claim_insurance_information?.office,
          billing_address: caseItem?.claim_insurance_information?.office?.billing_full_address,
          office_phone: caseItem?.claim_insurance_information?.office?.phone,
          client_id: caseItem?.claim_insurance_information?.client,
          mail_address: caseItem?.claim_insurance_information?.client?.work_full_address,
          direct_phone: caseItem?.claim_insurance_information?.client?.phone,
          client_email: caseItem?.claim_insurance_information?.client?.email,
          claim_insurance_type: caseInsuranceMap?.find(
            ({ value }) => value === caseItem?.claim_insurance_information?.claim_insurance_type
          ),
          insurance_company_id: caseItem?.claim_insurance_information?.insurance_company,
          amount: caseItem?.claim_insurance_information?.amount,
          policy_period_start: caseItem?.claim_insurance_information?.policy_period_start,
          policy_period_finish: caseItem?.claim_insurance_information?.policy_period_finish,
          claim_info_address: caseItem?.claim_insurance_information?.insurance_company?.full_address,
          claim_info_phone: caseItem?.claim_insurance_information?.insurance_company?.phone,
          insurance_carrier: caseItem?.claim_insurance_information?.insurance_carrier,
          claims_insurance_info: null
        }
      }}
      validationSchema={validationSchema}
      onSubmit={updateCase}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog PaperProps={{ style: { maxWidth } }} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              {title}
            </ModalHeader>

            <ModalBody>
              <Component caseItem={caseItem} initialValues={initialValues} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
