import { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import AddIcon from '@material-ui/icons/Add';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { useModal } from '../../../../components/ModalsProvider';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../components/Page';
import { Fab } from '../../../../components/Fab';
import { CreateSupportTicketModal } from '../../../../components/support/CreateSupportTicketModal';
import { SupportTicketsContext } from '../../../../components/support/SupportTicketsProvider';
import { Tooltip } from '../../../../components/Tooltip';

export const ActionsBar = ({ isMobile, filterOpenButton }) => {
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { addTicket } = useContext(SupportTicketsContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const createSupportTicket = () => {
    openModal(CreateSupportTicketModal, {
      onModalResolved: (ticket) => {
        addTicket(ticket);
      }
    });
  };

  return (
    <PageHeader>
      <PageHeaderTitle>
        Tickets
      </PageHeaderTitle>

      <PageHeaderActions disablePosition>
        <Fab
          color="primary"
          variant="extended"
          startIcon={<AddIcon />}
          onClick={createSupportTicket}
        >
          Add ticket
        </Fab>
      </PageHeaderActions>

      <PageHeaderActions disableSpacing endPosition>
        {!isMobile ? (
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <PageHeaderActions>
            {filterOpenButton}
          </PageHeaderActions>
        )}
      </PageHeaderActions>
    </PageHeader>
  );
};
