import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Checkbox, Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { stopPropagation } from '../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../dataMaps/apiModels';
import { PreviewTaskMember } from '../../../../../../app/Dashboard/TasksPage/PreviewTaskMember';
import { DueDate } from '../../../../../../app/Dashboard/TasksPage';
import { ProgressBar } from '../../../../../../app/Dashboard/TasksPage/MainWindow/ProgressBar';
import { Label } from '../../../../../../app/Dashboard/TasksPage/MainWindow/Label';
import { AttachmentIndicator } from '../../../../../../app/Dashboard/TasksPage/MainWindow/AttachmentIndicator';
import ChatSvg from '../../../../../icons/chat.svg?react';
import { ListRow, ListRowCell } from '../../../../../ListRow';
import { Members } from '../../../../../Members';
import { TasksContext } from '../../../../TasksProvider';
import { columnsWidths, minRowHeight } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ task, onClose }) => {
  const classes = useStyles();
  const tasksContext = useContext(TasksContext);
  const isSelected = tasksContext.selectedTasksIDs.indexOf(task.id) !== -1;
  const owner = task?.users?.find(({ id }) => id === task.owner_id);

  const handleItemSelection = () => {
    tasksContext.toggleItemSelection(task.id);
  };

  return (
    <ListRow
      selected={isSelected}
      minHeight={minRowHeight}
      title="Open task page"
      component={Link}
      onClick={onClose}
      to={itemsRoutesByModels.task(task.id)}
      className={classes.root}
    >
      <Checkbox
        className={classes.checkbox}
        checked={isSelected}
        onClick={stopPropagation(handleItemSelection)}
      />

      <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
        <div className={classes.taskCard}>
          <Typography noWrap className={classes.taskName} title={task.name}>
            {task.name}
          </Typography>

          <div className={classes.taskInfo}>
            {task.comments_count > 0 &&
              <AttachmentIndicator
                icon={<ChatSvg />}
                title={task.comments_count}
                className={classes.attachmentIndicator}
              />
            }

            {task.files_count > 0 &&
              <AttachmentIndicator
                icon={<AttachFileIcon />}
                title={task.files_count}
                className={classes.attachmentIndicator}
                classes={{ icon: classes.attachmentIndicator__taskIcon }}
              />
            }

            <Typography noWrap color="textSecondary" title={task.description}>
              {task.description}
            </Typography>
          </div>
        </div>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.parent}>
        <Typography noWrap title={task.parent && task.parent.name}>
          {task.parent && task.parent.name}
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.type}>
        {task.typeLabel &&
          <Typography noWrap title={task.typeLabel}>
            {task.typeLabel}
          </Typography>
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        <Typography noWrap title={task.status && task.status.name}>
          {task.status && task.status.name}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.progress}>
        <ProgressBar
          logged={task.logged_time}
          estimate={task.estimated_time}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.activity}>
        {task.due_at && <DueDate task={task}/>}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.assigned}>
        <Members
          isLink
          isParent
          noWrap
          maxVisible={4}
          owner={owner}
          users={task.users || []}
          MenuComponent={PreviewTaskMember}
          menuProps={{
            parent: task
          }}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.priority}>
        {task.priorityLabel && <Label name={task.priorityLabel} color={task.priority}/>}
      </ListRowCell>
    </ListRow>
  );
};
