import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { fieldsKeysMap, filterLabels } from '../../filterKeysMap';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell grow noWrap flexBasis={columnsWidths.search}>
        {filterLabels[fieldsKeysMap.search]}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.status}>
        {filterLabels[fieldsKeysMap.status]}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.office}>
        {filterLabels[fieldsKeysMap.office_id]}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.assignee}>
        {filterLabels[fieldsKeysMap.assignee_id]}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.creator}>
        {filterLabels[fieldsKeysMap.creator_id]}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.category}>
        {filterLabels[fieldsKeysMap.category]}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
