import * as types from './types';
import { createReduxReducer } from '../../helpers/createReduxReducer';
import { initialState as layoutInitialState, mobileInitialState } from './initialState';

const initialState = {
  isFetching: false,
  isFetched: false,
  data: {
    cases: [],
    users: [],
    hiddenFilterFields: {},
    layout: layoutInitialState,
    mobileLayout: mobileInitialState
  }
};

const transformLayout = (layout) => {
  if (!layout) {
    return initialState.data.layout;
  }

  return Object.entries(layout).reduce((layout, [ page, options ]) => {
    const remoteLayouts = options?.cardsLayoutOptions?.layouts;
    const localLayouts = initialState.data.layout[page].cardsLayoutOptions.layouts;

    return {
      ...layout,

      [page]: Object.assign(options, !remoteLayouts ? {} : {
        ...options,

        cardsLayoutOptions: {
          breakpoints: options?.cardsLayoutOptions?.breakpoints,
          cols: options?.cardsLayoutOptions?.cols,
          toolbox: options?.cardsLayoutOptions?.toolbox,
          layouts: Object.entries(localLayouts).reduce((layouts, [ breakpoint, widgets ]) => ({
            ...layouts,

            [breakpoint]: widgets.map((localWidget) => {
              const remoteWidget = remoteLayouts?.[breakpoint]?.find((widget) => widget?.i === localWidget.i);

              return remoteWidget || localWidget;
            })
          }), {})
        }
      })
    };
  }, {});
};

export const reducer = createReduxReducer(initialState, {
  [types.FETCH_STORAGE_DATA]: () => {
    return {
      isFetching: true,
      isFetched: false,
      data: initialState.data
    };
  },

  [types.FETCH_STORAGE_SUCCESS]: (state, data) => {
    return {
      ...state,

      isFetching: true,
      isFetched: true,
      data: !data ? state?.data : {
        ...data,

        layout: transformLayout(data.layout)
      }
    };
  },

  [types.FETCH_STORAGE_ERROR]: (state) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true
    };
  },

  [types.UPDATE_STORAGE_DATA]: (state, payload) => {
    return {
      ...state,

      isFetched: true,
      data: {
        ...state.data,
        ...payload
      }
    };
  },

  [types.SET_CARDS_LAYOUTS]: (state, {
    page,
    layouts,
    toolbox
  }) => {
    return {
      ...state,

      data: {
        ...state.data,

        layout: {
          ...state.data.layout,

          [page]: {
            ...state.data.layout[page],

            cardsLayoutOptions: {
              ...state.data.layout[page]?.cardsLayoutOptions,
              layouts,
              toolbox
            }
          }
        }
      }
    };
  },

  [types.SET_PREV_LAYOUTS]: (state, { page, prevLayoutsOptions }) => {
    return {
      ...state,

      data: {
        ...state.data,

        layout: {
          ...state.data.layout,

          [page]: { ...state.data.layout[page], prevLayoutsOptions }
        }
      }
    };
  },

  [types.SET_CHECKED_WIDGETS]: (state, { page, ...payload }) => {
    return {
      ...state,

      data: {
        ...state.data,

        mobileLayout: {
          ...state.data.mobileLayout,

          checkedWidgets: {
            ...(state.data?.mobileLayout?.checkedWidgets || {}),

            [page]: payload
          }
        }
      }
    };
  },

  [types.SET_OPEN_WIDGETS]: (state, { page, ...payload }) => {
    return {
      ...state,

      data: {
        ...state.data,

        mobileLayout: {
          ...state.data.mobileLayout,

          openWidgets: {
            ...(state?.data?.mobileLayout?.openWidgets || {}),

            [page]: payload
          }
        }
      }
    };
  }
});
