import { useContext } from 'react';
import { Box, SvgIcon, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { Fab } from '../../../../../../../components/Fab';
import { CreateNegotiationModal } from './CreateNegotiationModal';
import { NegotiationsContext } from './NegotiationsProvider';
import { List } from './List';
import NegotiationsSvg from '../../../../../../../components/icons/negotiations.svg?react';

export const Negotiations = ({
  isMovable,
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,

  ...props
}) => {
  const { openModal } = useModal();
  const { caseItem, addNegotiation, onCaseItemUpdate } = useContext(NegotiationsContext);

  const createNegotiations = () => {
    openModal(CreateNegotiationModal, {
      payload: {
        caseItem,
        onCaseItemUpdate
      },
      onModalResolved: (negotiation) => {
        addNegotiation(negotiation);
      }
    });
  };

  return (
    <ContentCard
      disableContentPaddings
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      title="Negotiations*"
      leftActions={[
        <SvgIcon fontSize="small"><NegotiationsSvg /></SvgIcon>
      ]}
      icon={(
        <Tooltip title="Add Negotiation">
          <Fab
            color="primary"
            size="small"
            onClick={createNegotiations}
          >
            <AddIcon fontSize="small" />
          </Fab>
        </Tooltip>
      )}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          type={props?.item?.i}
          page={page}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box height={height || '100%'}>
        <List />
      </Box>
    </ContentCard>
  );
};
