import { useEffect } from 'react';
import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { useReminderEvent } from '../../../../../../../components/cases/reminders/events';
import { CardHeader, CardItem } from '../../../../../../../components/Cards';
import { periodDirectionsMap } from '../../../../../../../components/cases/reminders/events/ReminderEventForm';
import { ColorPreview } from '../../../../../../../components/ColorPreview';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { Body } from './Body';
import { Menu } from './Menu';

const useStyles = makeStyles(({ palette }) => ({
  headerTitle: {
    lineHeight: 1
  }
}));

export const Card = ({ item: reminderEvent, recalculateHeight }) => {
  const classes = useStyles();
  const {
    groupTitle,
    shortGroupTitle,
    openRelativeReminderEventPreview
  } = useReminderEvent(reminderEvent);

  useEffect(() => {
    recalculateHeight();
  }, [ reminderEvent ]);

  return (
    <CardItem disableHover py={1} px={2}>
      <CardHeader
        disableCheckBox
        childrenWidth={12}
        menuComponent={Menu}
        menuComponentProps={{
          reminderEvent
        }}
      >
        <Box display="flex" alignItems="center">
          {!!reminderEvent?.group &&
            <Box display="flex" mr={1}>
              <ColorPreview color={reminderEvent?.group?.color}/>
            </Box>
          }

          <div>
            <Tooltip title={groupTitle}>
              <Typography variant="subtitle1" className={classes.headerTitle}>
                {shortGroupTitle}
              </Typography>
            </Tooltip>

            <Typography variant="subtitle1" color="textSecondary" className={classes.headerTitle}>
              {reminderEvent.type}
            </Typography>
          </div>
        </Box>
      </CardHeader>

      <Box py={2}>
        <Body reminderEvent={reminderEvent} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="subtitle1">
                {reminderEvent.position || '-'}
                &nbsp;/&nbsp;
                {reminderEvent.period_direction === periodDirectionsMap.back && '-'}
                {reminderEvent.interval} {reminderEvent.period}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Event # / Interval
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              {!reminderEvent.previousEvent ? 'No' : (
                <Link onClick={openRelativeReminderEventPreview}>
                  {reminderEvent.previousEvent?.position} {reminderEvent.previousEvent?.type}
                </Link>
              )}
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Relative
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardItem>
  );
};
