import { useEffect } from 'react';
import moment from 'moment';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { CardHeader, CardItem } from '../../../../../../components/Cards';
import { Label } from '../../../../../../components/Label';
import { categoryLabelsMap } from '../../../../../../components/support/categoriesOptions';
import { statusColorsMap, statusLabelsMap } from '../../../../../../components/support/statusesOptions';
import { useSupportTicket } from '../../../../../../components/support/useSupportTicket';
import { UserLink } from '../../../../../../components/UserLink';
import { Menu } from '../../Menu';
import { Body } from './Body';

const useStyles = makeStyles(({ palette }) => ({
  headerTitle: {
    lineHeight: 1
  }
}));

export const Card = ({ item: ticket, recalculateHeight }) => {
  const classes = useStyles();
  const { filesLength, openSupportTicketPreviewModal } = useSupportTicket(ticket);

  useEffect(() => {
    recalculateHeight();
  }, [ ticket ]);

  return (
    <CardItem py={1} px={2} onClick={openSupportTicketPreviewModal}>
      <CardHeader
        disableCheckBox
        childrenWidth={12}
        menuComponent={Menu}
        menuComponentProps={{
          supportTicket: ticket
        }}
      >
        <Typography variant="subtitle1"className={classes.headerTitle}>
          #{ticket.number}
        </Typography>
      </CardHeader>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <Label label={statusLabelsMap[ticket?.status]} color={statusColorsMap[ticket?.status]} />
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Status
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="subtitle1">
                {categoryLabelsMap[ticket.category] || '-'}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Category
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="subtitle1">
                {moment.utc(ticket?.created_at).local().format('L LT')}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Latest updates
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="subtitle1">
                {moment.utc(ticket?.changed_at).local().format('L LT')}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Creation date
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box py={1}>
        <Body ticket={ticket} filesLength={filesLength} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <UserLink size="sm" user={ticket.creator} />
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Creator
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <UserLink size="sm" user={ticket.assignee} />
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Assignee
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardItem>
  );
};
