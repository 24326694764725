import { useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, IconButton, Typography, Link, Box } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../helpers/dom';
import { hasRole } from '../../../../../utils/hasRole';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { AttachmentIndicator } from '../../../../../app/Dashboard/TasksPage/MainWindow/AttachmentIndicator';
import { PreviewTaskMember } from '../../../../../app/Dashboard/TasksPage/PreviewTaskMember';
import { ProgressBar } from '../../../../../app/Dashboard/TasksPage/MainWindow/ProgressBar';
import { Label } from '../../../../../app/Dashboard/TasksPage/MainWindow/Label';
import { TaskMenu } from '../../../../../app/Dashboard/TasksPage';
import { DueDate } from '../../../../../app/Dashboard/TasksPage';
import { ColorPreview } from '../../../../ColorPreview';
import ChatSvg from '../../../../icons/chat.svg?react';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { Members } from '../../../../Members';
import { Popper } from '../../../../Popper';
import { Loader } from '../../../../Loader';
import { Date } from '../../../../Date';
import { Tooltip } from '../../../../Tooltip';
import { TasksContext } from '../../../TasksProvider';
import { columnsWidths, minRowHeight, widthBreakpointLarge } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  item: task = {},
  width,
  isLoaded,
  recalculateHeight
}) => {
  const tasksContext = useContext(TasksContext);
  const isSelected = tasksContext.selectedTasksIDs.indexOf(task.id) !== -1;
  const owner = task?.users?.find(({ id }) => id === task.owner_id);
  const classes = useStyles();

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, task.users, width ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow
      selected={isSelected}
      minHeight={minRowHeight}
      className={classes.row}
    >
      <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
        <Link
          noWrap
          className={classes.taskCard}
          component={RouterLink}
          to={`/tasks/${task.id}`}
        >
          <Typography noWrap className={classes.taskName} title={task.name}>
            {task.name}
          </Typography>

          <div className={classes.root}>
            {task.comments_count > 0 &&
              <AttachmentIndicator
                icon={<ChatSvg />}
                title={task.comments_count}
                className={classes.attachmentIndicator}
              />
            }

            {task.files_count > 0 &&
              <AttachmentIndicator
                icon={<AttachFileIcon />}
                title={task.files_count}
                className={classes.attachmentIndicator}
                classes={{ icon: classes.attachmentIndicator__taskIcon }}
              />
            }

            <Typography noWrap color="textSecondary" title={task.description}>
              {task.description}
            </Typography>
          </div>
        </Link>
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell actions flexBasis={columnsWidths.type}>
          {task.type?.name &&
            <>
              <Box display="flex" alignItems="center">
                <ColorPreview color={task.type?.color} />
              </Box>

              <Tooltip title={task.type?.name}>
                <Typography noWrap>
                  {task.type?.name}
                </Typography>
              </Tooltip>
            </>
          }
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.status}>
        <Typography noWrap title={task.status && task.status.name}>
          {task.status && task.status.name}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.progress}>
        <ProgressBar
          logged={task.logged_time}
          estimate={task.estimated_time}
        />
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.activity}>
          <Date disableIcon date={task.created_at} />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.activity}>
        {task.due_at && <DueDate task={task} />}
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.assigned}>
          <Members
            isLink
            isParent
            noWrap
            maxVisible={4}
            owner={owner}
            users={task.users || []}
            MenuComponent={PreviewTaskMember}
            menuProps={{
              parent: task
            }}
          />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.priority}>
        {task.priorityLabel && <Label name={task.priorityLabel} color={task.priority} />}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        {!hasRole(rolesMap.client, rolesMap.patient) &&
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                  <MoreVertIcon />
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <TaskMenu task={task} onClose={handleClose} />
            )}
          </Popper>
        }
      </ListRowCell>
    </ListRow>
  );
};
