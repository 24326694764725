import moment from 'moment-timezone';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, useTheme, useMediaQuery } from '@material-ui/core';
import * as eventsApi from '../../../api/schedule-events';
import { transformTimeZoneToOption } from '../../FormField';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../Modal';
import { Loader } from '../../Loader';
import { ScheduleEventForm, validationSchema } from '../ScheduleEventForm';

export const CreateScheduleEventModal = ({
  DialogProps,
  payload: {
    hideApplyFields,
    initialValues
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const createEvent = (event, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    eventsApi.createEvent({
      ...event,

      users: event?.users?.map(({ id }) => id)
    }).then((event) => {
      enqueueSnackbar(`Event "${event.title}" successfully created`, { variant: 'success' });
      handleModalResolve(event);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        setErrors(errors);
      }

      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={{
        is_duration_corresponds_to_type: false,
        title: null,
        timezone: transformTimeZoneToOption(moment.tz.guess(true)),
        email_notification: true,
        started_at: moment().startOf('day').unix(),
        finished_at: moment().endOf('day').unix(),
        type_id: null,

        ...initialValues
      }}
      validationSchema={validationSchema}
      onSubmit={createEvent}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog disableEnforceFocus fullScreen={isMobile} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Add to schedule
            </ModalHeader>

            <ModalBody>
              <ScheduleEventForm hideApplyFields={hideApplyFields} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Add
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
