import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, List, ListItemIcon, ListItemText, MenuItem, SvgIcon } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import TemplateIcon from '../../../../../../../components/icons/medical/edit-template-icon.svg?react';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { TemplatesContext } from '../../TemplatesProvider';
import { UpdateTemplateReport } from '../../UpdateTemplateReport';

export const TemplatesMenu = ({ template, onClose }) => {
  const { pathname } = useLocation();
  const { openModal } = useModal();
  const { deleteTemplate, updateReport } = useContext(TemplatesContext);

  const handleDeleteTemplate = () => {
    deleteTemplate(template.id);
    onClose();
  };

  const handleUpdateInitialValues = () => {
    openModal(UpdateTemplateReport, {
      payload: {
        id: template.id
      },
      onModalResolved: (data) => {
        updateReport(data);
        onClose();
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={handleUpdateInitialValues}>
        <ListItemIcon>
          <EditIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit initial values" />
      </MenuItem>

      <MenuItem component={Link} to={`${pathname}/${template.id}/edit`}>
        <ListItemIcon>
          <Box color="info.main">
            <SvgIcon fontSize="inherit" color="inherit">
              <TemplateIcon />
            </SvgIcon>
          </Box>
        </ListItemIcon>

        <ListItemText primary="Edit template" />
      </MenuItem>

      <MenuItem onClick={handleDeleteTemplate}>
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete template" />
      </MenuItem>
    </List>
  );
};
