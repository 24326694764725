import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, useTheme, useMediaQuery } from '@material-ui/core';
import * as supportTicketsApi from '../../../api/support';
import { jsonToFormData } from '../../../helpers/jsonToFormData';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../Modal';
import { Loader } from '../../Loader';
import { SupportTicketForm, validationSchema, initialValues } from '../SupportTicketForm';

export const CreateSupportTicketModal = ({
  DialogProps,
  payload: {
    initialValues: initialValuesProp
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const createTicket = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    supportTicketsApi.createSupportTicket(jsonToFormData(values)).then((ticket) => {
      enqueueSnackbar(`Ticket "#${ticket.id}" successfully created`, { variant: 'success' });
      handleModalResolve(ticket);
    }).catch(({ data: { errors } = {} }) => {
      setErrors(errors);
      setSubmitting(false);

      enqueueSnackbar('Ticket not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...initialValuesProp
      }}
      validationSchema={validationSchema}
      onSubmit={createTicket}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog disableEnforceFocus fullScreen={isMobile} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Create support request
            </ModalHeader>

            <ModalBody>
              <SupportTicketForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
