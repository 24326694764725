import { useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Icon } from '@mdi/react';
import { mdiCogClockwise } from '@mdi/js';
import { Box, Button, Divider, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import * as loginApi from '../../../api/auth';
import { Loader } from '../../../components/Loader';
import { TextField, PasswordField } from '../../../components/FormField';
import { Page, PageBody, PageHeader } from '../../../components/Page';
import LogoSVG from '../../Dashboard/Layout/Sidebar/SidebarContent/logo.svg?react';
import { palette } from '../../../themes/palette';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ResetPassword = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const params = location?.search && new URLSearchParams(location?.search);
  const { enqueueSnackbar } = useSnackbar();

  const resetPassword = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return loginApi.confirmPassword(values).then(() => {
      history.push('/login');
      enqueueSnackbar('Password successfully renewed', { variant: 'success' });
    }).catch(({ data: { message, errors } }) => {
      errors && setErrors(errors);
      enqueueSnackbar(message, { variant: 'error' });
    });
  };

  return (
    <Page>
      <PageHeader disablePaddings color={palette().grey[600]}>
        <Box py={0.75} fontSize={60} display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
          <SvgIcon fontSize="inherit">
            <LogoSVG />
          </SvgIcon>
        </Box>
      </PageHeader>

      <PageHeader>
        <Box pt={2} pb={1} display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
          <Typography variant="h3" className={classes.titleIcon}>
            <SvgIcon><Icon path={mdiCogClockwise} /></SvgIcon>
          </Typography>

          <Typography variant="h3">Reset Password</Typography>
        </Box>
      </PageHeader>

      <PageBody>
        <Formik
          initialValues={{
            email: params ? params.get('email') : null,
            token: params ? params.get('token') : null
          }}
          validationSchema={validationSchema}
          onSubmit={resetPassword}
        >
          {({ errors, handleSubmit, isSubmitting }) => (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              {errors.login &&
                <>
                  <Typography paragraph color="error">
                    You have entered an invalid email
                  </Typography>

                  <Divider />
                </>
              }

              <TextField
                autoFocus
                required
                type="email"
                name="email"
                label="Email"
                autoComplete="email"
                margin="dense"
              />

              <PasswordField
                required
                name="password"
                label="Password"
                margin="dense"
              />

              <PasswordField
                required
                name="password_confirmation"
                label="Confirm password"
                margin="dense"
              />

              <div className={classes.submit}>
                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />
              </div>
            </form>
          )}
        </Formik>
      </PageBody>
    </Page>
  );
};
