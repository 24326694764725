import { useState, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { flow } from 'lodash';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { makeStyles, SvgIcon, Button, useTheme, useMediaQuery, Box } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import AddIcon from '@material-ui/icons/Add';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplitOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { api } from '../../../../../api';
import * as filesApi from '../../../../../api/files';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { stopPropagation } from '../../../../../helpers/dom';
import { addSocketJobID, jobsTypes } from '../../../../../store/socketJobs';
import { setFilesLastGlobalAction } from '../../../../../store/globalActions';
import { deleteFilesSuccess } from '../../../../../store/dashboard/files/recentlyOpenedFiles';
import LabelIcon from '../../../../../components/icons/tag-ouline.svg?react';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { SelectedItemsCount } from '../../../../../components/SelectedItemsCount';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { AppBar } from '../../../../../components/AppBar';
import { Popper } from '../../../../../components/Popper';
import { Fab } from '../../../../../components/Fab';
import * as types from '../../FilesContext/types';
import { SelectAvailableFilesModal } from '../../SelectAvailableFilesModal';
import { ChangeFilesTagsModal } from '../../ChangeFilesTagsModal';
import { CreateDocumentModal } from '../../CreateDocumentModal';
import { FilesFilterContext } from '../../FilesFilterContext';
import { FileUploadModal } from '../../FileUploadModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({ owner, ownerType, ownerID, filterOpenButton }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { filter, selectedFilesIDs } = useContext(FilesFilterContext);
  const [ filesCount, setFilesCount ] = useState();
  const classes = useStyles();
  const cancelFetch = useRef(() => {});
  const dispatchRedux = useDispatch();
  const { openModal } = useModal();

  const openAvailableFilesModal = () => {
    openModal(SelectAvailableFilesModal, {
      payload: {
        owner_type: ownerType,
        owner_id: ownerID
      }
    });
  };

  const fetchFilesCount = () => {
    cancelFetch.current();

    filesApi.fetchFiles({
      params: filter,
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(({ pagination }) => {
      setFilesCount(pagination.total);
    });
  };

  const uploadFile = () => {
    openModal(FileUploadModal, {
      payload: {
        owner_type: ownerType,
        owner_id: ownerID
      }
    });
  };

  const createDocument = () => {
    openModal(CreateDocumentModal, {
      payload: {
        model: ownerType,
        model_id: ownerID,
        owner: owner
      }
    });
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const handleDeleteSelectedFiles = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        filesApi.deleteFiles(selectedFilesIDs).then(() => {
          dispatchRedux(setFilesLastGlobalAction({ type: types.DELETE_FILES, payload: selectedFilesIDs }));
          enqueueSnackbar(`${selectedFilesIDs.length} file successfully deleted`);
          dispatchRedux(deleteFilesSuccess(selectedFilesIDs));
        });
      }
    });
  };

  const handleDownloadSelectedFiles = () => {
    const data = { files: selectedFilesIDs };

    if (ownerType === 'profile') {
      data.user_profile_id = ownerID;
    }

    filesApi.createArchive(data).then(({ job_id }) => {
      dispatchRedux(addSocketJobID({ type: jobsTypes.archives, jobID: job_id }));
    });
  };

  const handleChangeTags = () => {
    openModal(ChangeFilesTagsModal, { payload: { filesIDs: selectedFilesIDs } });
  };

  useEffect(() => {
    if (isMobile) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ isMobile ]);

  useEffect(() => {
    selectedFilesIDs.length && fetchFilesCount();

    return () => {
      cancelFetch.current();
    };
  }, [ selectedFilesIDs, filter ]);

  return (
    <AppBar ToolbarProps={{ className: classes.root }}>
      <div className={classes.actionButtons}>
        <Popper
          placement="bottom-start"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <Fab
              variant="extended"
              color="primary"
              startIcon={<AddIcon />}
              buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}
            >
              Add new
            </Fab>
          )}
        >
          {({ handleClose }) => (
            <Box display="flex" flexDirection="column" p={2}>
              <Box mb={1}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                  onClick={flow(uploadFile, handleClose)}
                >
                  Upload file
                </Button>
              </Box>

              <Box mb={1}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<FileCopyIcon />}
                  onClick={openAvailableFilesModal}
                >
                  Copy from available
                </Button>
              </Box>

              <Button
                variant="contained"
                color="secondary"
                className={classes.actionButton}
                startIcon={<AddIcon />}
                onClick={createDocument}
              >
                Create document
              </Button>
            </Box>
          )}
        </Popper>
      </div>

      {!!selectedFilesIDs.length &&
        <div className={classes.multiActions}>
          <SelectedItemsCount total={filesCount} length={selectedFilesIDs.length} />

          <VerticalDivider verticalGutters={1} horizontalGutters={2} />

          <IconButton
            edge="start"
            title="Download selected files"
            onClick={handleDownloadSelectedFiles}
          >
            <SvgIcon>
              <DownloadIcon />
            </SvgIcon>
          </IconButton>

          <IconButton
            title="Change tags"
            onClick={handleChangeTags}
          >
            <SvgIcon>
              <LabelIcon />
            </SvgIcon>
          </IconButton>

          {!hasRole(rolesMap.patient) && (
            <IconButton
              edge="end"
              title="Delete selected files"
              onClick={handleDeleteSelectedFiles}
            >
              <SvgIcon>
                <Icon path={mdiTrashCanOutline} />
              </SvgIcon>
            </IconButton>
          )}

          <VerticalDivider verticalGutters={1} horizontalGutters={2} />
        </div>
      }

      {isMobile && filterOpenButton}

      <IconButton
        edge="start"
        title="Groups view"
        color={viewVariant === viewVariantsMap.groups ? 'primary' : 'default'}
        onClick={toggleViewVariant(viewVariantsMap.groups)}
      >
        <HorizontalSplitIcon />
      </IconButton>

      <IconButton
        title="List view"
        color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
        onClick={toggleViewVariant(viewVariantsMap.list)}
      >
        <ViewListIcon />
      </IconButton>

      <IconButton
        edge="end"
        title="Grid view"
        color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
        onClick={toggleViewVariant(viewVariantsMap.grid)}
      >
        <ViewModuleIcon />
      </IconButton>
    </AppBar>
  );
};
