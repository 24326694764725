import { Autocomplete } from '../../../../components/FormField';
import { typesOptions } from './typesOptions';

const getOptionLabel = (option) => {
  return option?.label || typesOptions.find(({ value }) => option === value)?.label;
};

export const TypesSelect = (props) => {
  return (
    <Autocomplete
      name="type"
      label="Type"
      placeholder="Select type..."
      options={typesOptions}
      getOptionLabel={getOptionLabel}

      {...props}
    />
  );
};
