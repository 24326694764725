import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as casesApi from '../../../../../../../../api/cases';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../../../components/Modal';
import EditIcon from '../../../../../../../../components/icons/square-edit-icone.svg?react';
import { Loader } from '../../../../../../../../components/Loader';
import { validationSchema } from './validationSchema';
import { Body } from './Body';

export const EditSubstitutedInModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    substitutedInfo = {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateCase = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.updateCase({ id: substitutedInfo.caseItemID, ...values }).then((data) => {
      enqueueSnackbar('Case successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        substituted_in: null,
        substituted_in_company_id: substitutedInfo?.name ? substitutedInfo : null,
        substituted_in_company_address: substitutedInfo?.full_address,
        substituted_in_company_phone: substitutedInfo?.phone
      }}
      validationSchema={validationSchema}
      onSubmit={handleUpdateCase}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Edit Case Substituted in form
            </ModalHeader>

            <ModalBody>
              <Body />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
