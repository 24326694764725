export const styles = ({ spacing, palette, transitions }) => ({
  expanded: {
    paddingBottom: spacing(3)
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(),
    borderBottom: `1px solid ${palette.divider}`,
    cursor: 'pointer',
    transition: transitions.create([ 'backgroundColor' ]),

    '&:hover': {
      backgroundColor: palette.grey[50]
    }
  },

  filesCount: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: spacing(2)
  },

  filesCount__icon: {
    marginRight: spacing(0.5),
    fontSize: '1rem'
  },

  expandIcon: {
    marginLeft: 'auto'
  },

  body: {
    display: 'block',
    height: 320,
    overflow: 'hidden',
    padding: 0
  },

  body_hidden: {
    display: 'none'
  }
});
