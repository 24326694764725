import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import { truncate } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ ticket, filesLength }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>
        {truncate(ticket.description || '-', { length: 240 })}
      </Typography>

      {!!(filesLength || ticket?.comments_count) &&
        <Typography variant="body2" color="textSecondary">
          {!!filesLength &&
            <Typography component="span" variant="body2" color="textSecondary">
              <AttachFileOutlinedIcon fontSize="small" color="inherit" />{filesLength}
            </Typography>
          }
          &nbsp;
          &nbsp;
          {!!ticket?.comments_count &&
            <Typography component="span" variant="body2" color="textSecondary">
              <CommentOutlinedIcon fontSize="small" color="inherit" /> {ticket?.comments_count}
            </Typography>
          }
        </Typography>
      }
    </div>
  );
};
