import { useRef, useState } from 'react';
import { Box, SvgIcon, Tab, Tabs } from '@material-ui/core';
import { mdiCommentMultipleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { TimeTracking as WidgetTimeTracking } from '../../../Widgets/TimeTracking';
import { useResizeObserver } from '../../../../helpers/hooks/useResizeObserver';
import TimerIcon from '../../../icons/chronometer.svg?react';
import { Comments, CommentsContextProvider } from '../../../Comments';
import { TimeTrackingProvider } from '../../../TimeTracking';

const tabsMap = {
  eventComments: 'сomments',
  tracks: 'tracks'
};

export const TabsContent = ({ event }) => {
  const [ activeTab, setActiveTab ] = useState(tabsMap.eventComments);
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const commentsInitialState = {
    filter: {
      parent_type: event.is_recurring_dummy ? 'schedule_recurring_template' : 'schedule_event',
      parent_id: event.recurring_template_id || event.id
    }
  };

  const handleTabIndexChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box ref={rootRef} height="100%">
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabIndexChange}
        component={Box}
        border={1}
        borderTop={0}
        borderRight={0}
        borderLeft={0}
        borderColor="grey.200"
      >
        <Tab
          value={tabsMap.eventComments}
          icon={<SvgIcon fontSize="small"><Icon path={mdiCommentMultipleOutline} /></SvgIcon>}
          label="Comments"
        />

        <Tab
          icon={<SvgIcon fontSize="small"><TimerIcon/></SvgIcon>}
          value={tabsMap.tracks}
          label="Time tracking"
        />
      </Tabs>

      <Box py={2}>
        {activeTab === tabsMap.eventComments &&
          <CommentsContextProvider initialState={commentsInitialState}>
            <Comments
              maxHeight="500px"
              parentType={commentsInitialState.filter.parent_type}
              parentID={commentsInitialState.filter.parent_id}
            />
          </CommentsContextProvider>
        }

        {activeTab === tabsMap.tracks &&
          <TimeTrackingProvider filter={{ model: 'schedule_event', model_id: event.id }}>
            <Box flexGrow={1} height={350}>
              <WidgetTimeTracking event={event} width={width} />
            </Box>
          </TimeTrackingProvider>
        }
      </Box>
    </Box>
  );
};
