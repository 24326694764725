import { useContext } from 'react';
import { useModal } from '../ModalsProvider';
import { SupportTicketsContext } from './SupportTicketsProvider';
import { ViewSupportTicketModal } from './ViewSupportTicketModal';

export const useSupportTicket = (ticket) => {
  const { openModal } = useModal();
  const {
    updateSupportTicket,
    resetSupportTickets,
    deleteSupportTicket
  } = useContext(SupportTicketsContext);
  const filesLength = ticket?.files?.length;

  const openSupportTicketPreviewModal = () => {
    openModal(ViewSupportTicketModal, {
      payload: {
        ticket,
        onEdit: updateSupportTicket,
        onDelete: deleteSupportTicket
      },
      onModalResolved: resetSupportTickets
    });
  };

  return {
    filesLength,
    openSupportTicketPreviewModal
  };
};
