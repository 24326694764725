import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, SvgIcon, Dialog, List, Typography, Box } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiRobotOutline } from '@mdi/js';
import * as aiApi from '../../../../api/ai';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../../components/Modal';
import { Loader } from '../../../../components/Loader';
import { Result } from './Result';
import { typesMap } from './typesOptions';
import { TypesSelect } from './TypesSelect';

const initialValues = {
  type: typesMap.summary
};

export const AiAssistanceModal = ({
  DialogProps,
  payload: { fileId },
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ results, setResults ] = useState(null);

  const handleSubmit = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return aiApi.sendFile(values).then((result) => {
      setResults((results) => [ result, ...results ]);
      enqueueSnackbar('Files successfully shared', { variant: 'success' });
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        setErrors(errors);
      }
    });
  };

  useEffect(() => {
    aiApi.fetchFilesList(fileId).then((results) => {
      setResults(results);
    });
  }, []);

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ ...initialValues, file_id: fileId }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiRobotOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              AI Assistance
            </ModalHeader>

            <ModalBody>
              <TypesSelect
                disableClearable
              />

              <Box pt={2} pb={1}>
                <Typography variant="h3">
                  Previous results
                </Typography>
              </Box>

              <Loader loading={!results} render={
                () => !results?.length ? (
                  <Box p={2}>
                    <Typography align="center">
                      No results
                    </Typography>
                  </Box>
                ) : (
                  <List dense disablePadding>
                    {results?.map((result) => (
                      <Result key={result.id} result={result} />
                    ))}
                  </List>
                )}
              />
            </ModalBody>

            <ModalFooter>
              <Button disabled={isSubmitting} onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
