import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import {
  makeStyles,
  List,
  SvgIcon,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box
} from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { AssignInvoiceModal } from '../../../../../../../../components/billing/AssignInvoiceModal';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { hasRole } from '../../../../../../../../utils/hasRole';
import CheckIcon from '../../../../../../../../components/icons/check-icon.svg?react';
import { expensesTypesMap } from '../../../../../../../../components/billing';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { ExpensesPreviewModal } from '../../../../ExpensesPreviewModal';
import { ExpensesModal } from '../../../../Expenses/ExpensesModal';
import { ExpensesContext } from '../../../../Expenses';

const styles = () => ({
  root: {
    minWidth: 172
  }
});

const useStyles = makeStyles(styles);

export const ExpenseMenu = ({ invoiceId, expense, onClose }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { updateExpense, fetchExpenses, ...expensesContext } = useContext(ExpensesContext);

  const deleteExpense = () => {
    expensesContext.deleteExpense(expense.id);
  };

  const handleExpenseApprove = () => {
    expensesContext.approveExpense(expense.id);
  };

  const handleExpenseDisapprove = () => {
    expensesContext.disapproveExpense(expense.id);
  };

  const openExpensePreviewModal = () => {
    openModal(ExpensesPreviewModal, {
      payload: { id: expense.id }
    });
  };

  const openAssignInvoiceModal = () => {
    openModal(AssignInvoiceModal, {
      payload: { expense_id: expense.id },
      onModalResolved: () => {
        enqueueSnackbar('Successfully attached', { variant: 'success' });
        fetchExpenses();
      }
    });
  };

  const editExpense = () => {
    openModal(ExpensesModal, {
      payload: {
        initialValues: {
          title: 'Edit Expense',
          invoice_id: invoiceId,
          expense
        }
      }
    });
  };

  return (
    <List className={classes.root} onClick={onClose}>
      <MenuItem onClick={openExpensePreviewModal}>
        <ListItemIcon>
          <EyeIcon color="action" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {hasRole('admin') && expense?.status?.name === expensesTypesMap.submit &&
        <MenuItem onClick={handleExpenseApprove}>
          <ListItemIcon>
            <SvgIcon color="disabled" fontSize="inherit">
              <CheckIcon />
            </SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Approve" />
        </MenuItem>
      }

      {hasRole('admin') && expense?.status?.name === expensesTypesMap.approved &&
        <MenuItem onClick={handleExpenseDisapprove}>
          <ListItemIcon>
            <SvgIcon color="secondary" fontSize="inherit">
              <CheckIcon />
            </SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Disapprove" />
        </MenuItem>
      }

      <Tooltip
        isExistTooltip={expense?.status?.name !== expensesTypesMap.approved || expense?.invoice?.id}
        title={expense?.invoice?.id ? 'Already attached' : 'Expense Status must be Approved.'}
      >
        <div>
          <MenuItem
            disabled={expense?.status?.name !== expensesTypesMap.approved}
            onClick={openAssignInvoiceModal}
          >
            <ListItemIcon>
              <Box color="success.main" component="span">
                <AttachmentIcon color="inherit" fontSize="inherit" />
              </Box>
            </ListItemIcon>

            <ListItemText primary="Attach to Invoice" />
          </MenuItem>
        </div>
      </Tooltip>

      <MenuItem onClick={editExpense}>
        <ListItemIcon>
          <EditIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={deleteExpense}>
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete expense" />
      </MenuItem>
    </List>
  );
};
