import moment from 'moment-timezone';
import { transformTimeZoneToOption } from '../../../FormField/selects/TimeZoneSelect';

export const initialValues = {
  title: null,
  name: null,
  description: null,
  is_public: null,
  is_duration_corresponds_to_type: false,
  office_id: null,
  users: null,
  timezone: transformTimeZoneToOption(moment.tz.guess(true)),
  email_notification: true,
  type_id: null
};
