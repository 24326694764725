import { useContext, useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import filesize from 'filesize';
import { makeStyles, IconButton, Typography, Checkbox } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Tooltip } from '../../../../../components/Tooltip';
import { UserLink } from '../../../../../components/UserLink';
import { preventDefault } from '../../../../../helpers/dom';
import { Date } from '../../../../../components/Date';
import { Popper } from '../../../../../components/Popper';
import { Loader } from '../../../../../components/Loader';
import { Tag } from '../../../../../components/Tag';
import { FilePreviewIcon } from '../../FilePreviewIcon';
import { FileOwner } from '../../FileOwner';
import { FileMenu } from '../../FileMenu';
import { FilePreviewModal } from '../../FilePreviewModal';
import { FilesFilterContext } from '../../FilesFilterContext';
import { getFileMenuOptions as getDefaultFileMenuOptions } from '../../getFileMenuOptions';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FileRowMobile = ({
  file = {},
  isLoaded,
  getFileMenuOptions = getDefaultFileMenuOptions,
  filesContext,
  recalculateHeight = () => {}
}) => {
  const classes = useStyles();
  const { disableFileSelection, selectedFilesIDs, toggleFileSelection } = useContext(FilesFilterContext);
  const isSelected = selectedFilesIDs.indexOf(file.id) !== -1;
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      getFileMenuOptions={getFileMenuOptions(filesContext)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        files: filesContext.files,
        isFetched: filesContext.isFetched,
        isFetching: filesContext.isFetching,
        filter: filesContext.filter,
        onNeedNextPage: filesContext.loadNextPage
      }}
    />
  ), [ file, filesContext ]);

  const handleItemSelection = () => {
    toggleFileSelection(file.id);
  };

  const openFileMenu = (callback) => (event) => {
    event.stopPropagation();

    callback(event);
  };

  const handlePreventShareByEmail = (tag) => () => {
    filesContext.preventShareByEmail({ file, tag });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, file.tags ]);

  return (
    <div className={classes.root} onClick={openFilesPreview}>
      <div className={classes.main}>
        {!isLoaded ? <Loader /> :
          <>
            {!disableFileSelection &&
              <Checkbox
                checked={isSelected}
                edge="start"
                onClick={preventDefault(handleItemSelection)}
              />
            }

            <div className={classes.preview}>
              <FilePreviewIcon file={file} className={classes.preview__picture} />

              <Typography className={classes.preview__fileSize}>
                {filesize(file.size)}
              </Typography>
            </div>

            <div className={classes.mainInfo}>
              <Typography noWrap className={classes.mainInfo__name} title={file.original_filename}>
                {file.original_filename || file.file_name}
              </Typography>

              <Date
                disableIcon
                component="span"
                format="L LT"
                date={file.created_at}
                color="textSecondary"
              />

              {file.owner_type && file.owner &&
                <FileOwner owner_type={file.owner_type} owner={file.owner}/>
              }

              {!file.creator ? 'System' : (
                <UserLink
                  size="xs"
                  variant="body1"
                  user={file.creator}
                />
              )}
            </div>

            <Popper
              placement="left-start"
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <IconButton
                    buttonRef={anchorRef}
                    edge="end"
                    onClick={openFileMenu(handleToggle)}
                  >
                    <MoreVertIcon/>
                  </IconButton>
                )
              }
            >
              {({ handleClose }) => (
                <FileMenu
                  file={file}
                  menuList={getFileMenuOptions(filesContext)(file)}
                  onClose={handleClose}
                />
              )}
            </Popper>
          </>
        }
      </div>

      {file.tags &&
        <div className={classes.tags}>
          {file.tags && file.tags.map((tag) => (
            <Tag
              cutTagLabel
              key={tag.id}
              tag={tag}
              className={classes.tags__tag}
              deleteIcon={
                <div>
                  <Tooltip title="Prevent access by email">
                    <CancelIcon/>
                  </Tooltip>
                </div>
              }
              onDelete={tag.type === 'public_sharing' ? handlePreventShareByEmail(tag) : undefined}
            />
          ))}
        </div>
      }
    </div>
  );
};
