import * as Sentry from '@sentry/react';
import { Route as ReactRouterDomRoute } from 'react-router-dom';
import { ErrorBoundary } from '../../ErrorBoundary';

const SentryRoute = Sentry.withSentryRouting(ReactRouterDomRoute);

export const Route = (props) => {
  return (
    <ErrorBoundary>
      <SentryRoute {...props} />
    </ErrorBoundary>
  );
};
