export const styles = ({ spacing }) => ({
  logo: {
    padding: spacing(1.25)
  },

  logo_full: {
    padding: spacing(2)
  },

  logo__icon: {
    width: '100%',
    maxWidth: 172,
    margin: 'auto'
  }
});
