import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import * as featureFlagsApi from '../../../../../../../api/feature-flags';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../../components/Modal';
import { Loader } from '../../../../../../../components/Loader';
import FeatureFlagSvg from '../../../../../../../components/icons/lock-user-icon.svg?react';
import { Form } from './Form';

export const UpdateFeatureFlagModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { featureFlagId }
}) => {
  const [ featureFlag, setFeatureFlag ] = useState();

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return featureFlagsApi.updateFeatureFlag({
      ...values,

      users: values?.users?.map(({ id }) => id)
    }).then((featureFlag) => {
      handleModalResolve(featureFlag);
    });
  };

  useEffect(() => {
    featureFlagsApi.fetchFeatureFlag(featureFlagId).then((featureFlag) => {
      setFeatureFlag(featureFlag);
    });
  }, []);

  return (
    <Dialog {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={featureFlag}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer component="form" onSubmit={handleSubmit}>
            {!featureFlag ? <Loader p={2} /> : (
              <>
                <ModalHeader icon={<SvgIcon><FeatureFlagSvg /></SvgIcon>} onClose={handleModalReject}>
                  Edit Feature Flag
                </ModalHeader>

                <ModalBody>
                  <Form />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>Cancel</Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </>
            )}
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
