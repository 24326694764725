import { createHookReducer } from '../../../../helpers/createHookReducer';
import { tagsActionsMap } from '../../../../api/files/tags';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_FILES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_FILES_SUCCESS]: (
    { filter, files, ...state },
    { data: newFiles, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      files: (page > 1 ?
        files.filter(({ id }) => !newFiles.find((loadedFile) => id === loadedFile.id)).concat(newFiles)
        :
        newFiles
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_FILES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_FILES]: (state, payload) => {
    const {
      files,
      pagination,
      filter: {
        user_profile_id,
        tasks,
        cases,
        appointments,
        owner_type,
        owner_id,

        ...filter
      },

      ...otherState
    } = state;
    let suitableFiles = payload;

    if (owner_type && owner_id) {
      suitableFiles = suitableFiles.filter((file) => {
        return owner_type === file.owner_type && owner_id === file.owner_id;
      });
    }

    if (tasks?.length) {
      suitableFiles = suitableFiles.filter(({ owner_type, owner_id }) => {
        return tasks.find((id) => {
          return (owner_type === 'task') && (id === owner_id);
        });
      });
    }

    if (cases?.length) {
      suitableFiles = suitableFiles.filter(({ owner_type, owner_id, owner }) => {
        return cases.find((id) => {
          return (owner_type === 'case') && ((id === owner_id) || (id === owner?.id));
        });
      });
    }

    if (appointments?.length) {
      suitableFiles = suitableFiles.filter(({ owner_type, owner_id }) => {
        return appointments.find((id) => {
          return (owner_type === 'appointment') && (id === owner_id);
        });
      });
    }

    if (user_profile_id) {
      suitableFiles = suitableFiles.filter(({ owner_type, owner_id }) => {
        return (owner_type === 'profile') && (user_profile_id === owner_id);
      });
    }

    if (!suitableFiles.length) {
      return state;
    }

    const total = pagination.total + suitableFiles.length;
    const page = Math.ceil((files.length + suitableFiles.length) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, tasks, cases, appointments, user_profile_id, page },
      files: suitableFiles.filter((file) => !files.find(({ id }) => id === file.id)).concat(files)
    };
  },

  [types.DELETE_FILES]: ({ pagination, filter, files, ...state }, filesIDs) => {
    const deletedFiles = files.filter((file) => filesIDs.find((id) => id === file.id));
    const total = pagination.total - deletedFiles.length;
    const page = Math.ceil((files.length - deletedFiles.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      files: files.filter((file) => !deletedFiles.find(({ id }) => id === file.id))
    };
  },

  [types.UPDATE_FILE]: ({ files, ...state }, updatedFile) => {
    return {
      ...state,

      files: files.map((file) => updatedFile.id === file.id ? updatedFile : file)
    };
  },

  [types.ADD_FILE_SHARED_USERS]: ({ files, ...state }, { fileID, users }) => {
    return {
      ...state,

      files: files.map((file) => fileID === file.id ? {
        ...file,
        shared_users: (file.shared_users || []).filter(({ id }) => {
          return !users.find((user) => id === user.id);
        }).concat(users)
      } : file)
    };
  },

  [types.DELETE_FILE_SHARED_USERS]: ({ files, ...state }, { fileID, users }) => {
    return {
      ...state,

      files: files.map((file) => fileID === file.id ? {
        ...file, shared_users: file.shared_users.filter(({ id }) => users.find((user) => id === user.id))
      } : file)
    };
  },

  [types.CHANGE_FILES_TAGS]: ({ files, ...state }, { filesIDs, tags, actionType }) => {
    switch (actionType) {
      case tagsActionsMap.add:
        return {
          ...state,

          files: files.map((file) => {
            if (filesIDs.find((id) => file.id === id)) {
              file.tags = tags.concat(file.tags?.filter((tag) => !tags.find(({ id }) => tag.id === id)));
            }

            return file;
          })
        };

      case tagsActionsMap.delete:
        return {
          ...state,

          files: files.map((file) => {
            if (filesIDs.find((id) => file.id === id)) {
              file.tags = file.tags.filter((tag) => !tags.find(({ id }) => tag.id === id));
            }

            return file;
          })
        };

      case tagsActionsMap.replace:
        return {
          ...state,

          files: files.map((file) => {
            if (filesIDs.find((id) => file.id === id)) {
              file.tags = tags;
            }

            return file;
          })
        };

      default:
        throw new Error(`Actions type is "${actionType}", but expected one of ${Object.keys(tagsActionsMap)}`);
    }
  },

  [types.UPDATE_TAG]: ({ files, ...state }, updatedTag) => {
    return {
      ...state,

      files: files.map((file) => {
        file.tags = file.tags.map((tag) => tag.id === updatedTag.id ? updatedTag : tag);

        return file;
      })
    };
  },

  [types.DELETE_TAG]: ({ files, ...state }, deletedTagID) => {
    return {
      ...state,

      files: files.map((file) => {
        file.tags = file.tags.filter((tag) => tag.id !== deletedTagID);

        return file;
      })
    };
  }
});
