import { Box, Grid, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import UserIcon from '@material-ui/icons/PermIdentity';
import TrackerPlusSvg from '../../../../../../../../components/icons/chronometer.svg?react';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { CardHeader, CardItem } from '../../../../../../../../components/Cards';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { Date } from '../../../../../../../../components/Date';
import { CaseModel } from '../../../../InvoicesPage/InvoicesContent/CaseModel';
import { styles } from '../Row/styles';

const useStyles = makeStyles(styles);

export const Card = ({
  track,
  isSelected,
  handleItemSelection,
  trackTime,
  trackRate
}) => {
  const classes = useStyles();

  return (
    <CardItem selected={isSelected} py={1} px={2}>
      <CardHeader
        disableCardMenu
        isSelected={isSelected}
        onItemSelect={handleItemSelection}
      >
        <Box display="flex">
          <Typography>Type:</Typography>

          <Box display="flex" alignItems="center" ml={2}>
            {track.type ?
              <UserIcon className={classes.trackTypeIcon} />
              :
              <SvgIcon className={classes.trackTypeIcon}>
                <TrackerPlusSvg />
              </SvgIcon>
            }
          </Box>
        </Box>
      </CardHeader>

      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Typography noWrap>
            Employee name:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center">
            <UserLink
              noWrap
              variant="h5"
              user={track.user}
            />
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap>
            Parent:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center">
            <CaseModel
              variant="body1"
              color="textPrimary"
              model={track?.models?.case || {}}
            />
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap>
            Category:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography
            noWrap
            color={!track?.category?.name ? 'textSecondary' : 'textPrimary'}
          >
            {track?.category?.name || 'No info.'}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap>
            Notes:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Tooltip isExistTooltip={!!track?.description} title={track?.description}>
            <Typography
              noWrap
              color={!track?.description ? 'textSecondary' : 'textPrimary'}
            >
              {track?.description || 'No info.'}
            </Typography>
          </Tooltip>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap>
            Started at:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Date disableIcon date={track.started_at} format="L LT" />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap>
            Finished at:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Date disableIcon date={track.finished_at} format="L LT" />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap>
            Total, h:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography className={classes.dateContent}>
            {trackTime}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap>
            Rate:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography className={classes.dateContent}>
            {trackRate
              ? <CurrencyFormat value={trackRate} />
              : 'n/a'
            }
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap>
            Total, $:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography className={classes.dateContent}>
            {!!track.billing_total
              ? <CurrencyFormat value={track.billing_total} />
              : 'n/a'
            }
          </Typography>
        </Grid>
      </Grid>
    </CardItem>
  );
};
