export const categoriesMap = {
  cases: 'cases',
  task: 'task',
  profile: 'profile',
  other: 'other'
};

export const categoryLabelsMap = {
  [categoriesMap.cases]: 'Cases',
  [categoriesMap.task]: 'Tasks',
  [categoriesMap.profile]: 'Profile',
  [categoriesMap.other]: 'Other'
};

export const categoriesOptions = [
  {
    value: categoriesMap.cases,
    label: categoryLabelsMap[categoriesMap.cases]
  },
  {
    value: categoriesMap.task,
    label: categoryLabelsMap[categoriesMap.task]
  },
  {
    value: categoriesMap.profile,
    label: categoryLabelsMap[categoriesMap.profile]
  },
  {
    value: categoriesMap.other,
    label: categoryLabelsMap[categoriesMap.other]
  }
];
