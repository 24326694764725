import { makeStyles, ButtonBase, SvgIcon, Typography } from '@material-ui/core';
import OfficeLogoIcon from '../../../../../../components/icons/office-logo.svg?react';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmptyAvatar = ({ size, onUploadAvatar }) => {
  const classes = useStyles({ size });

  return (
    <div className={classes.imageInfo} onClick={onUploadAvatar}>
      <SvgIcon className={classes.imageInfo__icon}>
        <OfficeLogoIcon />
      </SvgIcon>

      <ButtonBase className={classes.imageInfo__button}>
        <Typography
          component="span"
          variant="body2"
          color="primary"
          className={classes.imageInfo__buttonTitle}
        >
          Upload an image
        </Typography>
      </ButtonBase>
    </div>
  );
};
