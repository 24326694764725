import { Formik } from 'formik';
import { pick } from 'lodash';
import moment from 'moment';
import { Dialog, useTheme, useMediaQuery, Typography, Grid } from '@material-ui/core';
import { AttachmentsContainer } from '../../../app/Dashboard/TasksPage/AttachmentsContainer';
import { Comments, CommentsContextProvider } from '../../Comments';
import { Label } from '../../Label';
import { LinkableText } from '../../LinkableText';
import { ModalHeader, ModalBody, ModalContainer } from '../../Modal';
import { OfficeLink } from '../../OfficeLink';
import { UserLink } from '../../UserLink';
import { categoryLabelsMap } from '../categoriesOptions';
import { statusColorsMap, statusLabelsMap } from '../statusesOptions';

export const ViewSupportTicketModal = ({
  DialogProps,
  payload: {
    ticket
  },
  handleModalReject
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));
  const filesInitialFilter = {
    owner_type: 'support_ticket',
    owner_id: ticket?.id
  };
  const commentsInitialState = {
    filter: {
      parent_type: 'support_ticket',
      parent_id: ticket?.id
    }
  };

  return (
    <Formik
      initialValues={pick(ticket, [ 'id', 'files' ])}
      onSubmit={null}
    >
      <Dialog disableEnforceFocus fullScreen={isMobile} {...DialogProps}>
        <ModalContainer noValidate>
          <ModalHeader onClose={handleModalReject}>
            Ticket #{ticket.id}
          </ModalHeader>

          <ModalBody>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Creation date</Typography>
                <Typography>{moment.utc(ticket?.created_at).local().format('L LT')}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">Latest updates</Typography>
                <Typography>{moment.utc(ticket?.changed_at).local().format('L LT')}</Typography>
                <UserLink size="xs" user={ticket?.modifier} />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">Status</Typography>
                <br />
                <Label label={statusLabelsMap[ticket?.status]} color={statusColorsMap[ticket?.status]} />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">Category</Typography>
                <Typography>{categoryLabelsMap[ticket.category] || '-'}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">Creator</Typography>
                <UserLink user={ticket.creator} />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">Assignee</Typography>
                <UserLink user={ticket.assignee} />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">Office</Typography>
                <OfficeLink office={ticket.office} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Description</Typography>

                <LinkableText target="_blank" color="inherit">
                  <Typography>{ticket.description}</Typography>
                </LinkableText>
              </Grid>

              <Grid item xs={12}>
                <AttachmentsContainer
                  disableFetching
                  disableVirtual
                  ownerType="support_ticket"
                  ownerID={ticket.id}
                  files={ticket.files}
                  filter={filesInitialFilter}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography gutterBottom variant="subtitle1">Comments</Typography>

                <CommentsContextProvider initialState={commentsInitialState}>
                  <Comments
                    maxHeight="500px"
                    parentType={commentsInitialState.filter.parent_type}
                    parentID={commentsInitialState.filter.parent_id}
                  />
                </CommentsContextProvider>
              </Grid>
            </Grid>
          </ModalBody>
        </ModalContainer>
      </Dialog>
    </Formik>
  );
};
