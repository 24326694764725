import { Box, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as reminderGroupsApi from '../../../../../api/cases/reminders/groups';
import { ColorPreview } from '../../../../ColorPreview';
import { Autocomplete } from '../../../../FormField';
import { useModal } from '../../../../ModalsProvider';
import { Tag } from '../../../../Tag';
import { CreateReminderGroupModal } from '../CreateReminderGroupModal';

const fetchCaseReminderGroups = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return reminderGroupsApi.fetchCaseReminderGroups({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => {
  return !option?.isCreatableOption ? (
    <Grid container wrap="nowrap" spacing={1} alignItems="center">
      <Grid item>
        <ColorPreview color={option?.color} />
      </Grid>

      <Grid item>
        {option?.name}
      </Grid>
    </Grid>
  ) : (
    <>
      <Box display="flex" mr={1}>
        <AddIcon color="primary" />
      </Box>

      Add group
    </>
  );
};

export const CaseReminderGroupsSelect = ({ multiple = false, params = {}, creatablePayload, ...props }) => {
  const { openModal } = useModal();

  const addGroup = () => {
    return new Promise((resolve, reject) => {
      openModal(CreateReminderGroupModal, {
        DialogProps: {
          disableRestoreFocus: true
        },
        payload: creatablePayload,
        onModalResolved: resolve,
        onModalRejected: reject
      });
    });
  };

  return (
    <Autocomplete
      isAsync
      multiple={multiple}
      label="Group"
      placeholder="Search group..."
      onNeedFetch={fetchCaseReminderGroups(params)}
      renderOption={renderOption}
      getOptionLabel={(group) => group?.name}
      getOptionValue={(group) => group?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}
      getInputProps={(value) => !(!multiple && value) ? null : ({
        startAdornment: (!multiple && value) && (
          <ColorPreview color={value.color} />
        )
      })}
      renderTags={(tags, getTagProps) => (
        tags.map((tag, index) => (
          <Tag
            cutTagLabel
            tag={tag}

            {...getTagProps({ index })}
          />
        ))
      )}
      onCreate={addGroup}

      {...props}
    />
  );
};
