import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, useTheme, useMediaQuery } from '@material-ui/core';
import * as eventsApi from '../../../api/schedule-events';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../Modal';
import { Loader } from '../../Loader';
import {
  transformTimeZoneToOption,
  transformScheduleEventTypeToOption
} from '../../FormField';
import { eventsParentsTypesOptions } from '../eventsParentsTypesOptions';
import { validationSchema } from '../ScheduleEventForm/validationSchema';
import { ScheduleEventForm } from '../ScheduleEventForm';

const getInitialValues = (event) => {
  return {
    ...event,

    type_id: transformScheduleEventTypeToOption(event.type),
    parent_type: eventsParentsTypesOptions.find(({ value }) => value === event.parent_type),
    parent_id: event.parent,
    office_id: event.office,
    timezone: transformTimeZoneToOption(event.timezone)
  };
};

export const EditScheduleEventModal = ({
  DialogProps,
  payload: {
    hideApplyFields,
    event
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const handleParentTypeChange = (initialParentType, setFieldValue) => (parentType) => {
    if (initialParentType !== parentType) {
      setFieldValue('parent_id', null);
    }
  };

  const updateEvent = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return eventsApi.updateEvent({
      ...values,

      users: values?.users?.map(({ id }) => id),
      parent_type: values?.parent_type?.value || values?.parent_type || null
    }).then((event) => {
      enqueueSnackbar(`Event "${event.title}" successfully updated`, { variant: 'success' });
      handleModalResolve(event);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={getInitialValues(event)}
      validationSchema={validationSchema}
      onSubmit={updateEvent}
    >
      {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
        <Dialog disableEnforceFocus fullScreen={isMobile} {...DialogProps}>
          <ModalContainer component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Edit event
            </ModalHeader>

            <ModalBody>
              <ScheduleEventForm
                hideApplyFields={hideApplyFields}
                values={values}
                onParentTypeChange={handleParentTypeChange(values.parent_type, setFieldValue)}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
