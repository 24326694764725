import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  tablePlugin
} from '@mdxeditor/editor';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles, Paper } from '@material-ui/core';
import { Loader } from '../../../../../../../components/Loader';
import { FilePreviewContext } from '../../../FilePreviewContext';
import { PreviewContent } from '../PreviewContent';
import { styles } from '../Text/styles';

const useStyles = makeStyles(styles);

const plugins = [
  headingsPlugin(),
  listsPlugin(),
  quotePlugin(),
  tablePlugin(),
  thematicBreakPlugin()
];

export const Markdown = () => {
  const classes = useStyles();
  const [ text, setText ] = useState('');
  const { selectedFile } = useContext(FilePreviewContext);

  useEffect(() => {
    setText('');

    axios.get(selectedFile?.url).then(({ data }) => {
      setText(data);
    });
  }, [ selectedFile?.url ]);

  return (
    <PreviewContent>
      <Paper className={classes.root}>
        <Loader loading={!text} render={
          () => (
            <MDXEditor readOnly markdown={text} plugins={plugins} />
          )}
        />
      </Paper>
    </PreviewContent>
  );
};
