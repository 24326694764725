import { api } from '../api';

export const fetchSupportTickets = (config = {}) => {
  return api.get('/supportTickets', config).then(({ data: { data, pagination } }) => {
    return { data, pagination };
  }).catch((thrown) => {
    throw thrown;
  });
};

export const createSupportTicket = (ticket) => {
  return api.post('/supportTickets', ticket).then(({ data }) => data);
};

export const fetchSupportTicket = (id) => {
  return api.get(`/supportTickets/${id}`).then(({ data }) => data);
};

export const updateSupportTicket = (id, data) => {
  return api.put(`/supportTickets/${id}`, data).then(({ data }) => data);
};

export const deleteSupportTicket = (id) => {
  return api.delete(`/supportTickets/${id}`).then(({ data }) => data);
};
