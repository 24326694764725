export const statusesMap = {
  open: 'open',
  in_progress: 'in_progress',
  awaiting_information: 'awaiting_information',
  under_review: 'under_review',
  testing: 'testing',
  resolved: 'resolved',
  closed: 'closed',
  reopened: 'reopened'
};

export const statusLabelsMap = {
  [statusesMap.open]: 'Open',
  [statusesMap.in_progress]: 'In Progress',
  [statusesMap.awaiting_information]: 'Awaiting Information',
  [statusesMap.under_review]: 'Under Review',
  [statusesMap.testing]: 'Testing',
  [statusesMap.resolved]: 'Resolved',
  [statusesMap.closed]: 'Closed',
  [statusesMap.reopened]: 'Reopened'
};

export const statusColorsMap = {
  [statusesMap.open]: '#F44336',
  [statusesMap.in_progress]: '#FF9800',
  [statusesMap.awaiting_information]: '#F57C00',
  [statusesMap.under_review]: '#4DB6AC',
  [statusesMap.testing]: '#00BCD4',
  [statusesMap.resolved]: '#689F38',
  [statusesMap.closed]: '#8BC34A',
  [statusesMap.reopened]: '#C62828'
};

export const statusesOptions = [
  {
    value: statusesMap.open,
    label: statusLabelsMap[statusesMap.open]
  },
  {
    value: statusesMap.in_progress,
    label: statusLabelsMap[statusesMap.in_progress]
  },
  {
    value: statusesMap.awaiting_information,
    label: statusLabelsMap[statusesMap.awaiting_information]
  },
  {
    value: statusesMap.under_review,
    label: statusLabelsMap[statusesMap.under_review]
  },
  {
    value: statusesMap.testing,
    label: statusLabelsMap[statusesMap.testing]
  },
  {
    value: statusesMap.resolved,
    label: statusLabelsMap[statusesMap.resolved]
  },
  {
    value: statusesMap.closed,
    label: statusLabelsMap[statusesMap.closed]
  },
  {
    value: statusesMap.reopened,
    label: statusLabelsMap[statusesMap.reopened]
  }
];
