export const columnsWidths = {
  number: 220,
  category: 124,
  status: 160,
  creationDate: 160,
  latestUpdates: 160,
  creator: 160,
  office: 160,
  assignee: 160,
  action: 54
};
