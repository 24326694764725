import { Formik } from 'formik';
import { isObject } from 'lodash';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import * as casesApi from '../../../../../../../../api/cases';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../../../components/Modal';
import EditIcon from '../../../../../../../../components/icons/square-edit-icone.svg?react';
import { Loader } from '../../../../../../../../components/Loader';
import { initialValues } from './initialValues';
import { PlaintiffsPDForm } from './PlaintiffsPDForm';
import { validationSchema } from './validationSchema';

export const EditPlaintiffsPDModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    caseID,
    plaintiffsPD = {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateCase = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.updateCasePlaintiff(caseID, values).then((caseItem) => {
      enqueueSnackbar('Case successfully updated', { variant: 'success' });
      handleModalResolve(caseItem);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...plaintiffsPD,

        towing_co_id: isObject(plaintiffsPD?.towing_co) ? plaintiffsPD?.towing_co : null,
        auto_body_shop_id: isObject(plaintiffsPD?.auto_body_shop) ? plaintiffsPD?.auto_body_shop : null,
        rental_co_id: isObject(plaintiffsPD?.rental_co) ? plaintiffsPD?.rental_co : null
      }}
      validationSchema={validationSchema}
      onSubmit={handleUpdateCase}
    >
      {({ isSubmitting,  handleSubmit }) => (
        <Dialog maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Edit Plaintiff's PD
            </ModalHeader>

            <ModalBody>
              <PlaintiffsPDForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
