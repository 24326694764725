import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import moment from 'moment';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import { getUserFullName } from '../../helpers/users';
import * as eventsApi from '../../api/schedule-events';
import LogoSVG from '../Dashboard/Layout/Sidebar/SidebarContent/logo.svg?react';
import { Loader } from '../../components/Loader';
import { Divider } from '../../components/Divider';
import { Page, PageBody } from '../../components/Page';
import { AppointmentInfo } from './AppointmentInfo';
import { ConfirmationSection } from './ConfirmationSection';

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    padding: spacing(1.5, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.grey[600]
  },

  logo: {
    width: 64,
    verticalAlign: 'bottom'
  }
}));

export const AppointmentConfirmationPage = () => {
  const classes = useStyles();
  const [ eventData, setEventData ] = useState({});
  const [ isLoading, setIsLoading ] = useState(true);
  const { token } = useParams();
  const isExpired = moment().isAfter(moment(eventData?.expired_at));
  const event = eventData?.event || {};

  const handleConfirm = (confirmation) => {
    setEventData((data) => ({ ...data, confirmation }));
  };

  useEffect(() => {
    eventsApi.fetchEventData(token).then((data) => {
      setEventData(data);
      setIsLoading(false);
    });
  }, []);

  return (
    <Page>
      <Loader loading={isLoading} p={2} render={
        () => (
          <PageBody fullHeight disablePaddings>
            <div className={classes.header}>
              <NavLink to="/">
                <LogoSVG className={classes.logo} />
              </NavLink>

              <Box mt={1} color="white">
                <Typography variant="h2" color="inherit">{event.title}</Typography>
              </Box>
            </div>

            <Box maxWidth={600} mx="auto">
              <Box my={4}>
                <Typography variant="h2">Hi {getUserFullName(event.patient)},</Typography>

                {!isExpired && (
                  <Box mt={1.5}>
                    <Typography variant="h4">You have a new appointment</Typography>
                  </Box>
                )}
              </Box>

              <Divider gutter={1} />

              <Box py={2}>
                <AppointmentInfo appointment={event} />
              </Box>

              <Divider gutter={1} />

              <ConfirmationSection
                isExpired={isExpired}
                event={event}
                token={token}
                onConfirm={handleConfirm}
              />

              <Box mt={6} mb={4}>
                <Typography variant="h4" color="textSecondary">
                  Please call if conflicts <Link href="tel:(818) 884-5480">(818) 884-5480</Link>.
                </Typography>

                <Typography variant="h4" color="textSecondary">
                  If you have fever or flu symptoms, follow local recommended regulations.
                </Typography>
              </Box>

              <Divider />
            </Box>
          </PageBody>
        )}
      />
    </Page>
  );
};
