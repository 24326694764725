import { Icon } from '@mdi/react';
import { mdiCashMultiple, mdiFileDocumentEditOutline } from '@mdi/js';
import { SvgIcon } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import InvoiceSvg from '../../../components/icons/invoice-icon.svg?react';
import { Dashboard } from './Dashboard';
import { ClaimsPage } from './ClaimsPage';
import { Invoices } from './Invoices';
import { Payments } from './Payments';

export const routes = [
  {
    path: '/billing/dashboard',
    icon: <DashboardIcon />,
    label: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/billing/invoices',
    icon: <SvgIcon><InvoiceSvg /></SvgIcon>,
    label: 'Invoices',
    component: Invoices
  },
  {
    path: '/billing/payments',
    icon: <SvgIcon><Icon path={mdiCashMultiple} /></SvgIcon>,
    label: 'Payments',
    component: Payments
  },
  {
    path: '/billing/claims',
    icon: <SvgIcon><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>,
    label: 'Claims',
    component: ClaimsPage
  }
];
