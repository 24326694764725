import * as Sentry from '@sentry/react';

Sentry.init({
  enabled: process.env.NODE_ENV !== 'development',
  dsn: 'https://c758975e339211e31c36ed315e4b8be6@o4508200970289152.ingest.us.sentry.io/4508200971730944',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history: window.history }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [ /^https:\/\/caseark\.com/, /^https:\/\/test\.caseark\.com/ ],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});
