import { useContext, useRef } from 'react';
import { List as MuiList, Box, Typography } from '@material-ui/core';
import { ScrollbarsCustom } from '../../../../../components/ScrollbarsCustom';
import { Sticky } from '../../../../../components/Sticky';
import { Loader } from '../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { SupportTicketsContext } from '../../../../../components/support/SupportTicketsProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const List = () => {
  const {
    supportTickets,
    isFetched,
    isFetching,
    loadNextPage,
    meta
  } = useContext(SupportTicketsContext);
  const scrollElementRef = useRef();

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} style={{ flexGrow: 1, height: '100%' }}>
      <Box py={2}>
        <Loader loading={!isFetched} p={2} render={
          () => !supportTickets?.length ? (
            <Box p={3}>
              <Typography align="center">No tickets found</Typography>
            </Box>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={supportTickets}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={meta}
                  RowComponent={Row}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
