import { Route, Redirect } from 'react-router-dom';
import { Activity } from './Activity';
import { ClaimsPage } from './BillingPage/ClaimsPage';
import { CallReportsPage } from './CallReportsPage';
import { Contacts } from './Contacts';
import { Diagnosis } from './Diagnosis';
import { FaxPage } from './FaxPage';
import { Insurances } from './Insurances';
import { MedicalFormsContent } from './MedicalFormsContent';
import { Prescriptions } from './Prescriptions';
import { PatientsPage } from './PatientsPage';
import { ProfilePage } from './ProfilePage';
import { FilesPage } from './FilesPage';
import { CasesPage } from './CasesPage';
import { CaseReminders } from './CaseReminders';
import { ServicesPage } from './ServicesPage';
import { SupportPage } from './SupportPage/SupportPage';
import { TasksPage } from './TasksPage';
import { Members } from './Members';
import { CodesPage } from './CodesPage';
import { VideosPage } from './VideosPage';
import { EmailsPage } from './EmailsPage';
import { Analytics } from './Analytics';
import { AppointmentsPage } from './AppointmentsPage';
import { SchedulePage } from './SchedulePage';
import { BillingPage } from './BillingPage';
import { MessengerPage } from './MessengerPage';
import { WorkSessionsPage } from './WorkSessionsPage';
import { SIP } from './SIP';

export const routes = [
  {
    path: '/',
    exact: true,
    component: () => (
      <Route exact path="/">
        <Redirect to="/profile" />
      </Route>
    )
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/services',
    component: ServicesPage
  },
  {
    path: '/sip',
    component: SIP
  },
  {
    path: '/work-sessions',
    component: WorkSessionsPage
  },
  {
    path: '/call-reports',
    component: CallReportsPage
  },
  {
    path: '/insurances',
    component: Insurances
  },
  {
    path: '/patients',
    component: PatientsPage
  },
  {
    path: '/appointments',
    component: AppointmentsPage
  },
  {
    path: '/schedule',
    component: SchedulePage
  },
  {
    path: '/files',
    component: FilesPage
  },
  {
    path: '/faxes',
    component: FaxPage
  },
  {
    path: '/cases',
    component: CasesPage
  },
  {
    path: '/case-reminders',
    component: CaseReminders
  },
  {
    path: '/codes',
    component: CodesPage
  },
  {
    path: '/tasks',
    component: TasksPage
  },
  {
    path: '/emails',
    component: EmailsPage
  },
  {
    path: '/contacts',
    component: Contacts
  },
  {
    path: '/videos',
    component: VideosPage
  },
  {
    path: '/analytics',
    component: Analytics
  },
  {
    path: '/members',
    component: Members
  },
  {
    path: '/billing',
    component: BillingPage
  },
  {
    path: '/claims',
    component: ClaimsPage
  },
  {
    path: '/messenger/:threadId?',
    component: MessengerPage
  },
  {
    path: '/medical-forms',
    component: MedicalFormsContent
  },
  {
    path: '/diagnosis',
    component: Diagnosis
  },
  {
    path: '/prescriptions',
    component: Prescriptions
  },
  {
    path: '/activity',
    component: Activity
  },
  {
    path: '/support',
    component: SupportPage
  }
];
