import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import CallIcon from '@material-ui/icons/Call';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabledOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { mdiCalendarClockOutline, mdiFileDocumentEditOutline } from '@mdi/js';
import { CommentOutlined as CommentOutlinedIcon } from '@material-ui/icons';
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { SvgIcon } from '@material-ui/core';
import Icon from '@mdi/react';
import NegotiationsSvg from '../../icons/negotiations.svg?react';
import BillingSvg from '../../icons/billing-icon.svg?react';
import { activityActionMap, activitySessionTypesMap, activityTypesMap } from './activityMap';
import { commentsActionsMap } from './commentsActivityMap';

export const activityIconsMap = {
  [activityActionMap.updateTask]: {
    icon: PlaylistAddCheckIcon,
    className: 'activityIcon_task'
  },
  [activityActionMap.createTask]:  {
    icon: PlaylistAddCheckIcon,
    className: 'activityIcon_task'
  },
  [activityActionMap.createTaskFile]:  {
    icon: PlaylistAddCheckIcon,
    className: 'activityIcon_task'
  },
  [activityActionMap.createTaskTodoItem]:  {
    icon: PlaylistAddCheckIcon,
    className: 'activityIcon_task'
  },
  [activityActionMap.updateTaskTodoItem]:  {
    icon: PlaylistAddCheckIcon,
    className: 'activityIcon_task'
  },
  [activityActionMap.deleteTaskTodoItem]:  {
    icon: PlaylistAddCheckIcon,
    className: 'activityIcon_task'
  },
  [activityActionMap.createCaseUser]: {
    icon: PersonAddIcon,
    className: 'activityIcon_person'
  },
  [activityActionMap.deleteCaseUser]: {
    icon: PersonAddDisabledIcon,
    className: 'activityIcon_disablePerson'
  },
  [commentsActionsMap.restoreComment]: {
    icon: CommentOutlinedIcon,
    className: 'activityIcon_comment'
  },
  [activityActionMap.createEmail]: {
    icon: MailOutlineIcon,
    className: 'activityIcon_email'
  },
  [activityActionMap.expense]: {
    icon: (props) => (
      <SvgIcon fontSize="small" {...props}>
        <BillingSvg />
      </SvgIcon>
    ),
    className: 'activityIcon_invoice'
  },
  [activityActionMap.claim]: {
    icon: (props) => (
      <SvgIcon fontSize="small" {...props}>
        <Icon path={mdiFileDocumentEditOutline} />
      </SvgIcon>
    ),
    className: 'activityIcon_claim'
  },
  [activityActionMap.task]:  {
    icon: PlaylistAddCheckIcon,
    className: 'activityIcon_task'
  },
  [activityActionMap.appointment]: {
    icon: InsertInvitationOutlinedIcon,
    className: 'activityIcon_event'
  },
  [activityActionMap.case]: {
    icon: WorkIcon,
    className: 'activityIcon_work'
  },
  [activityActionMap.caseDeposition]: {
    icon: (props) => (
      <SvgIcon fontSize="small" {...props}>
        <Icon path={mdiCalendarClockOutline} />
      </SvgIcon>
    ),
    className: 'activityIcon_negotiat'
  },
  [activityActionMap.caseDiscovery]: {
    icon: (props) => (
      <SvgIcon fontSize="small" {...props}>
        <Icon path={mdiCalendarClockOutline} />
      </SvgIcon>
    ),
    className: 'activityIcon_negotiat'
  },
  [activityActionMap.caseNegotiat]: {
    icon: (props) => (
      <SvgIcon fontSize="small" {...props}>
        <NegotiationsSvg />
      </SvgIcon>
    ),
    className: 'activityIcon_negotiat'
  },
  [activityActionMap.income]: {
    icon: (props) => (
      <SvgIcon fontSize="small" {...props}>
        <BillingSvg />
      </SvgIcon>
    ),
    className: 'activityIcon_invoice'
  },
  [activityActionMap.invoice]: {
    icon: (props) => (
      <SvgIcon fontSize="small" {...props}>
        <BillingSvg />
      </SvgIcon>
    ),
    className: 'activityIcon_invoice'
  },
  [activityActionMap.payment]: {
    icon: (props) => (
      <SvgIcon fontSize="small" {...props}>
        <BillingSvg />
      </SvgIcon>
    ),
    className: 'activityIcon_invoice'
  },
  [activityActionMap.profile]: {
    icon: AccountCircleOutlinedIcon,
    className: 'activityIcon_person'
  },
  [activityActionMap.createApptFile]:  {
    icon: EventAvailableIcon,
    className: 'activityIcon_event'
  },
  [activityActionMap.deleteApptFile]:  {
    icon: EventAvailableIcon,
    className: 'activityIcon_event'
  },
  [activityActionMap.scheduleEvent]: {
    icon: EventAvailableIcon,
    className: 'activityIcon_event'
  },
  [activityActionMap.scheduleRecurringTemplate]: {
    icon: DateRangeIcon,
    className: 'activityIcon_event'
  },
  [activityActionMap.sharedMedia]: {
    icon: PermMediaOutlinedIcon,
    className: 'activityIcon_media'
  },
  [activityActionMap.userEmailMessage]: {
    icon: MailOutlineIcon,
    className: 'activityIcon_email'
  },
  [activityActionMap.default]: {
    icon: AssignmentTurnedInOutlinedIcon,
    className: 'activityIcon_default'
  },
  [activitySessionTypesMap.workSession]: {
    icon: AlarmOnOutlinedIcon,
    className: 'activityIcon_comment'
  },
  [activitySessionTypesMap.workClock]: {
    icon: AlarmOnOutlinedIcon,
    className: 'activityIcon_comment'
  },
  [activitySessionTypesMap.workClockReport]: {
    icon: AlarmOnOutlinedIcon,
    className: 'activityIcon_comment'
  },
  [activityTypesMap.timeTrack]: {
    icon: AlarmOnOutlinedIcon,
    className: 'activityIcon_comment'
  },
  [activitySessionTypesMap.workClockClockIn]: {
    icon: AlarmOnOutlinedIcon,
    className: 'activityIcon_comment'
  },
  [activitySessionTypesMap.workClockClockOut]: {
    icon: AlarmOnOutlinedIcon,
    className: 'activityIcon_comment'
  },
  [activitySessionTypesMap.sipCallAttachParent]: {
    icon: CallIcon,
    className: 'activityIcon_default'
  },
  [activitySessionTypesMap.sipCallDetachParent]: {
    icon: CallIcon,
    className: 'activityIcon_default'
  }
};
