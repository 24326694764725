import { useFormikContext } from 'formik';
import { makeStyles, Box, Paper, SvgIcon, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { TextField } from '../../../../../../../components/FormField';
import TemplateIcon from '../../../../../../../components/icons/medical/edit-template-icon.svg?react';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = () => {
  const classes = useStyles();
  const { values, handleBlur } = useFormikContext();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box px={isTablet ? 1 : 3}>
      <Paper variant="outlined" square className={classes.header}>
        {!isTablet && (
          <div className={classes.mainIcon}>
            <SvgIcon fontSize="large" color="primary">
              <TemplateIcon />
            </SvgIcon>
          </div>
        )}

        <div className={classes.mainInfo}>
          <Box display="flex" alignItems="flex-start" color="info.main">
            <Typography variant="h5" color="inherit">
              {values.report_type}
            </Typography>
          </Box>

          <Typography variant="h1">
            <TextField
              fullWidth
              autoFocus
              disableUnderline
              name="name"
              onBlur={handleBlur}
              InputProps={{
                className: classes.input
              }}
            />
          </Typography>
        </div>

        <Box display="flex" alignItems="center" textAlign="end" flexGrow={0}>
          <Typography color="textSecondary">DOB</Typography>

          <Box ml={1} width={120}>
            <Typography>
              {values.age || '-'}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
