import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { merge } from 'lodash';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../api';
import * as categoriesApi from '../../../../../api/billing';
import { useModal } from '../../../../../components/ModalsProvider';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import * as types from './types';
import { initialState as initialStateConfig } from './initialState';
import { reducer } from './reducer';

export const BillingSettingsContext = createContext(null);

export const BillingSettingsContextProvider = ({
  children,
  initialState = {}
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialStateConfig, initialState));
  const categoriesLastGlobalAction = useSelector(({ globalActions }) => globalActions.categoriesLastGlobalAction);
  const {
    categories,
    pagination,
    isFetching,
    isFetched,
    filter
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchCategories = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.CATEGORIES_FETCH_REQUEST });

    categoriesApi.fetchExpensesCategories({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then((data) => {
      dispatch({ type: types.CATEGORIES_FETCH_SUCCESS, payload: data });
    });
  };

  const deleteCategories = (category) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        categoriesApi.deleteExpensesCategory(category.id).then(() => {
          fetchCategories();
          enqueueSnackbar('Category successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Category is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const activateExpenseCategory = (category) => {
    categoriesApi.activateExpensesCategory(category.id).then(() => {
      dispatch({ type: types.TOGGLE_ACTIVATE_CATEGORY, payload: category });
      enqueueSnackbar('Category successfully activated', { variant: 'success' });
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  const deactivateExpenseCategory = (category) => {
    categoriesApi.deactivateExpensesCategory(category.id).then(() => {
      dispatch({ type: types.TOGGLE_ACTIVATE_CATEGORY, payload: category });
      enqueueSnackbar('Category successfully deactivated', { variant: 'success' });
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  const resetCategories = (newFilter) => {
    dispatch({ type: types.RESET_CATEGORIES, payload: newFilter });

    fetchCategories({ page: 1, ...newFilter });
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  const providerValue= {
    categories,
    pagination,
    isFetching,
    isFetched,
    filter,
    meta: {
      ...filter,
      ...pagination
    },

    fetchCategories,
    deleteCategories,
    resetCategories,
    activateExpenseCategory,
    deactivateExpenseCategory
  };

  useEffect(() => {
    categoriesLastGlobalAction && dispatch(categoriesLastGlobalAction);
  }, [ categoriesLastGlobalAction ]);

  return (
    <BillingSettingsContext.Provider value={providerValue}>
      {children}
    </BillingSettingsContext.Provider>
  );
};
