import { useEffect } from 'react';
import moment from 'moment';
import { Link } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { IconButton } from '../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import { Tooltip } from '../../../../../../components/Tooltip';
import { transcribeStatusLabelsMap } from '../../transcribeStatusesMap';
import { useTranscribe } from '../../useTranscribe';
import { columnsWidths } from '../List';

export const Row = ({ item: transcribe = {}, isLoaded, recalculateHeight }) => {
  const {
    text,
    openAudioFilePreview,
    openTextFilePreview,
    editTranscribe
  } = useTranscribe(transcribe);

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow px={0}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Link onClick={openAudioFilePreview}>{transcribe.filename}</Link>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.name}>
        {!transcribe.text ? '-' : <Link noWrap onClick={openTextFilePreview}>{transcribe.text}</Link>}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {moment.unix(transcribe.created_at).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        {transcribeStatusLabelsMap[transcribe.status]}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        <Tooltip isExistTooltip={!text} title="No text found">
          <div>
            <IconButton disabled={!text} onClick={editTranscribe}>
              <EditIcon />
            </IconButton>
          </div>
        </Tooltip>
      </ListRowCell>
    </ListRow>
  );
};
