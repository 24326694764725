import { useEffect } from 'react';
import { pick } from 'lodash';
import { makeStyles, Grid, FormControl, FormLabel, Typography, Link } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { TextField, SimpleSelect, PasswordField } from '../../../../../../../../components/FormField';
import { emailConfigs, emailConfigType } from './emailConfigs';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmailAccountForm = ({ mailboxType, disableAdvancedSettings = false }) => {
  const classes = useStyles();
  const { values, setValues } = useFormikContext();

  useEffect(() => {
    if (!mailboxType) {
      return;
    }

    setValues({
      ...emailConfigs[mailboxType].config,
      ...pick(values, [ 'name', 'username', 'password' ])
    });
  }, [ mailboxType ]);

  return (
    <>
      <TextField
        required
        fullWidth
        name="name"
        label="Name"
        placeholder="Enter name..."
        margin="dense"
      />

      <TextField
        required
        fullWidth
        type="email"
        name="username"
        label="Email"
        placeholder="Enter email..."
        margin="dense"
      />

      <PasswordField
        required
        name="password"
        label="Password"
        placeholder="Enter password..."
        margin="dense"
      />

      {mailboxType === emailConfigType.gmail &&
        <Typography>
          Create your password here:&nbsp;

          <Link target="_blank" href="https://myaccount.google.com/apppasswords">
            https://myaccount.google.com/apppasswords
          </Link>
        </Typography>
      }

      {!disableAdvancedSettings &&
        <>
          <FormControl fullWidth component="fieldset" className={classes.otherFields}>
            <FormLabel component="legend" className={classes.formLegend}>Incoming</FormLabel>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  name="incoming.port"
                  label="Port"
                  placeholder="Enter port..."
                  margin="dense"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  name="incoming.host"
                  label="Host"
                  placeholder="Enter host..."
                  margin="dense"
                />
              </Grid>

              <Grid item xs={6}>
                <SimpleSelect
                  required
                  name="incoming.encryption"
                  label="Encryption"
                  options={[ { value: 'ssl', label: 'SSL' }, { value: 'tls', label: 'TLS' } ]}
                  margin="dense"
                />
              </Grid>
            </Grid>
          </FormControl>

          <FormControl fullWidth component="fieldset" className={classes.otherFields}>
            <FormLabel component="legend" className={classes.formLegend}>Outgoing (SMTP)</FormLabel>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  name="outgoing.port"
                  label="Port"
                  placeholder="Enter port..."
                  margin="dense"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  name="outgoing.host"
                  label="Host"
                  placeholder="Enter host..."
                  margin="dense"
                />
              </Grid>

              <Grid item xs={6}>
                <SimpleSelect
                  required
                  name="outgoing.encryption"
                  label="Encryption"
                  options={[ { value: 'ssl', label: 'SSL' }, { value: 'tls', label: 'TLS' } ]}
                  margin="dense"
                />
              </Grid>
            </Grid>
          </FormControl>
        </>
      }
    </>
  );
};
