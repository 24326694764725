import { useContext } from 'react';
import { Box } from '@material-ui/core';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { CaseItemContext } from '../../CaseItemProvider';
import { ChecklistContent } from './ChecklistContent';
import { EditChecklistModal } from './EditChecklistModal';

export const Checklist = ({
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,

  ...props
}) => {
  const { openModal } = useModal();
  const {
    caseItem,
    updateCase
  } = useContext(CaseItemContext);

  const updateCaseItemChecklist = (checklist) => {
    updateCase({ checklist });
  };

  const editChecklist = () => {
    openModal(EditChecklistModal, {
      payload: {
        caseID: caseItem.id,
        checklist: caseItem.checklist,
        onChecklistUpdate: updateCaseItemChecklist
      },
      onModalResolved: (checklist) => {
        updateCaseItemChecklist((checklist));
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      title="Initial Checklist"
      rightActions={[
        <WidgetsActionButtons
          isEditable
          isMinimizable
          type={props?.item?.i}
          page={page}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
          openWidgetModal={editChecklist}
        />
      ]}

      {...props}
    >
      <Box height={height || '100%'}>
        <ChecklistContent
          checklist={caseItem.checklist}
          caseID={caseItem.id}
          onCaseItemUpdate={updateCaseItemChecklist}
        />
      </Box>
    </ContentCard>
  );
};
