import { makeStyles, SvgIcon } from '@material-ui/core';
import ActivityIcon from '../../../../../components/icons/activity.svg?react';
import { ContentCard } from '../../../../../components/ContentCard';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CaseActivity = ({ parent, parentType }) => {
  const classes = useStyles();

  return (
    <ContentCard
      fullHeight
      disableContentPaddings
      disableAutoHeight
      title="Activity"
      leftActions={[
        <SvgIcon color="inherit" fontSize="small" className={classes.icon}>
          <ActivityIcon/>
        </SvgIcon>
      ]}
      className={classes.contentCard}
      CollapseProps={{
        classes: {
          wrapperInner: classes.contentCardWrapperInner
        }
      }}
    >
      <List parentType={parentType} parent={parent} />
    </ContentCard>
  );
};
