import moment from 'moment';
import { useSnackbar } from 'notistack';
import printJS from 'print-js';
import { saveAs } from 'file-saver';
import { Box, FormControlLabel, SvgIcon, Switch, useMediaQuery } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as formsApi from '../../../../../../api/profile';
import { IconButton } from '../../../../../../components/IconButton';
import {
  ApproveConfirmationModal
} from '../../../../../../components/medical/forms';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Loader } from '../../../../../../components/Loader';
import { Fab } from '../../../../../../components/Fab';
import CheckIcon from '../../../../../../components/icons/check-icon.svg?react';
import { actionsMap } from './FormPreviewModal';

export const PreviewActionButtons = ({
  isOpenSwitches,
  form,
  isDeleted = false,
  activeAction,
  setActiveAction,
  onSwitchChange,
  onOpenEditPage,
  onApprove,
  onRestore
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleApprove = async () => {
    openModal(ApproveConfirmationModal, {
      payload: { form },
      onModalResolved: () => {
        onApprove();
      }
    });
  };

  const printForm = () => {
    setActiveAction(actionsMap.print);

    formsApi.downloadPdf(form.id).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      printJS({ printable: url, type: 'pdf' });
    }).finally(() => {
      setActiveAction(null);
    });
  };

  const saveToPatientFiles = () => {
    setActiveAction(actionsMap.save);

    formsApi.savePdfToProfileFiles(form.id).then(() => {
      enqueueSnackbar('Form saved to profile files as PDF', { variant: 'success' });
    }).finally(() => {
      setActiveAction(null);
    });
  };

  const downloadPDF = () => {
    setActiveAction(actionsMap.download);

    formsApi.downloadPdf(form.id).then((data) => {
      const blob = new Blob([ data ]);

      saveAs(blob, `${form.form_type}-${form.doi ? moment.unix(form.doi).format('L')  : ''}.pdf`);
    }).finally(() => {
      setActiveAction(null);
    });
  };

  return (
    <>
      {isDeleted ? (
        <Box p={1}>
          <Fab
            variant="extended"
            color="info"
            size="small"
            startIcon={<BackupIcon />}
            onClick={onRestore}
          >
            Restore
          </Fab>
        </Box>
      ) : !isTablet && (form?.is_confirmed ? (
        <>
          <Loader
            surface
            loading={activeAction === actionsMap.save}
            render={() => (
              <Tooltip title="Save to patient files">
                <IconButton onClick={saveToPatientFiles}>
                  <SaveOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          />

          <Loader
            surface
            loading={activeAction === actionsMap.print}
            render={() => (
              <Tooltip title="Print form">
                <IconButton onClick={printForm}>
                  <PrintOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          />

          <Loader
            surface
            loading={activeAction === actionsMap.download}
            render={() => (
              <Tooltip title="Download form as PDF">
                <IconButton onClick={downloadPDF}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            )}
          />
        </>
      ) : (
        <Loader
          surface loading={activeAction === actionsMap.approve} render={
            () => (
              <Tooltip title="Approve form">
                <IconButton onClick={handleApprove}>
                  <SvgIcon color="secondary" fontSize="inherit">
                    <CheckIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            )}
        />
      ))}

      <Box ml={1} py={1}>
        <FormControlLabel
          label="Open all info"
          control={
            <Switch
              size={isTablet ? 'small' : 'medium'}
              color="primary"
              checked={isOpenSwitches}
              onChange={onSwitchChange}
            />
          }
        />
      </Box>

      {!isDeleted && (
        <Tooltip title="Edit form">
          <IconButton color="primary" onClick={onOpenEditPage}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
