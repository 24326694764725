import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import moment from 'moment';
import {
  Grid,
  Box,
  Button,
  Collapse,
  SvgIcon,
  Typography,
  Dialog,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import * as timeApi from '../../../api/time-tracks';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import TrackerPlusSvg from '../../icons/chronometer-add.svg?react';
import { TextField } from '../../FormField';
import { TimeTrackingProvider } from '../../TimeTracking';
import { Loader } from '../../Loader';
import { TimeContent } from './TimeContent';
import { UsedTimeTracks } from './UsedTimeTracks';
import { validationSchema } from './validationSchema';

export const transformInitialValues = (initialValues) => {
  const hasStartAndEndDates = initialValues.started_at && initialValues.finished_at;
  const duration = moment.duration(moment.unix(initialValues.finished_at).diff(moment.unix(initialValues.started_at)));

  return {
    ...initialValues,

    model: initialValues.time_track_model || null,
    model_id: initialValues.time_track_model_id || null,
    hours: hasStartAndEndDates ? Math.floor(duration.asHours()) : initialValues.hours,
    minutes: hasStartAndEndDates ? Math.floor(duration.asMinutes()) % 60 : initialValues.minutes
  };
};

export const LogTimeModal = ({
  DialogProps,
  payload: {
    caseItem = {},
    event = {},
    onCaseItemUpdate = () => {},
    initialValues = {}
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(({ profile: { user } }) => user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isModelFields = !initialValues.time_track_model;
  const isAdmin = hasRole(rolesMap.admin, rolesMap.supervisor);

  const createTrack = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.billing_time = moment.duration(`${values.hours || 0}:${values.minutes || 0}`).asSeconds() || null;
    values.time_track_model_id = values?.time_track_model_id?.id || values?.time_track_model_id;
    values.model_id = values?.model_id?.id || values?.model_id;
    values.user_id = values?.user_id?.id || values?.user_id;
    values.isAdmin = currentUser?.id === values?.user_id;

    const api = values.isAdmin ? timeApi.createAdminTrack : timeApi.createTrack;

    return api(values).then((track) => {
      enqueueSnackbar('Log Time successfully created', { variant: 'success' });
      handleModalResolve(track);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        enqueueSnackbar('Time range conflict!', { variant: 'error' });
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        isAdmin,
        time_track_model: null,
        time_track_model_id: null,
        model: null,
        model_id: null,
        category: null,
        started_at: null,
        finished_at: null,
        company_id: currentUser?.work?.company,
        hours: null,
        minutes: null,
        description: null,
        user_id: currentUser,

        ...transformInitialValues(initialValues)
      }}
      validationSchema={validationSchema}
      onSubmit={createTrack}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><TrackerPlusSvg /></SvgIcon>}
              onClose={handleModalReject}
            >
              Log Time
            </ModalHeader>

            <ModalBody>
              <Grid container spacing={3} alignItems="flex-end">
                <TimeContent
                  event={event}
                  caseItem={caseItem}
                  isModelFields={isModelFields}
                  onCaseItemUpdate={onCaseItemUpdate}
                />

                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    name="description"
                    label="Note"
                    margin="normal"
                    variant="filled"
                    InputProps={{
                      disableUnderline: true
                    }}
                    rows={4}
                  />
                </Grid>
              </Grid>

              <Box mt={3}>
                <Collapse in={moment(values.started_at, 'X', true)?.isValid()}>
                  <Typography variant="h6" gutterBottom>List of tracks</Typography>

                  <TimeTrackingProvider>
                    <UsedTimeTracks />
                  </TimeTrackingProvider>
                </Collapse>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
