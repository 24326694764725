import { useContext, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  Button,
  IconButton,
  Typography,
  Collapse,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import EditIcon from '@material-ui/icons/EditOutlined';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useSelector } from 'react-redux';
import { preventDefault } from '../../../helpers/dom';
import { LinkableText } from '../../LinkableText';
import { UserLink } from '../../UserLink';
import { CommentsContext, CommentsContextProvider } from '../CommentsContext';
import { widthBreakpointSmall } from '../Comments';
import { SubComments } from '../SubComments';
import { TimeCounter } from '../TimeCounter';
import { ReplyForm } from '../ReplyForm';
import { EditForm } from '../EditForm';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  isSubComment,
  disableForm,
  width = '100%',
  comment,
  parentPage,
  parentCommentID
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    filter,
    isFetching,
    comments,
    deleteComment,

    ...commentsContext
  } = useContext(CommentsContext);
  const [ isRepliesOpened, setIsRepliesOpened ] = useState(false);
  const [ isUpdate, setIsUpdate ] = useState(false);
  const [ isEditExpired, setIsEditExpired ] = useState(false);
  const currentUser = useSelector(({ profile }) => profile.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const initialState = {
    filter: {
      parent_type: 'comment',
      parent_id: comment.id
    }
  };

  const removeComment = () => {
    isEditExpired
      ? deleteComment(comment)
      : enqueueSnackbar('The ability to delete a comment has expired.');
  };

  const restoreComment = () => {
    isEditExpired
      ? commentsContext.restoreComment(comment)
      : enqueueSnackbar('The ability to restore a comment has expired.');
  };

  const toggleIsShowSub = () => {
    setIsRepliesOpened(!isRepliesOpened);
  };

  const updateComment = () => {
    isEditExpired
      ? setIsUpdate(!isUpdate)
      : enqueueSnackbar('The ability to edit a comment has expired.');
  };

  return (
    <div className={classes.root}>
      <UserLink
        disableName
        parentProps={{ alignItems: 'flex-start' }}
        user={comment.user}
        className={classes.user}
      />

      <div className={classes.main}>
        <div className={classes.header}>
          <div className={classes.contentHeader}>
            <UserLink
              noAvatar
              disableName={isMobile}
              variant="h5"
              user={comment.user}
            />

            <Typography className={classes.contentDate}>
              {moment.unix(comment.created_at).format('L')} at {moment.unix(comment.created_at).format('h:mm a')}
            </Typography>

            {(!parentPage || width > widthBreakpointSmall) &&
              <TimeCounter
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                setIsEditExpired={setIsEditExpired}
                comment={comment}
              />
            }
          </div>

          {currentUser?.id === comment?.user?.id && isEditExpired && (comment.deleted_at ? (
            <Button
              color="primary"
              className={classes.restoreIcon}
              onClick={preventDefault(restoreComment)}
            >
              Restore <BackupIcon className={classes.backupIcon}/>
            </Button>
          ) : (
            <div className={classes.rowAction}>
              <IconButton size="small" onClick={preventDefault(updateComment)}>
                <EditIcon fontSize="small" />
              </IconButton>

              <IconButton size="small" onClick={preventDefault(removeComment)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          ))}
        </div>

        {comment.deleted_at ?
          <div className={classes.trash}>
            <DeleteIcon className={classes.trashIcon} />

            <Typography>
              Deleted message
            </Typography>
          </div>
          :
          isUpdate ?
            <EditForm
              setIsUpdate={setIsUpdate}
              isUpdate={isUpdate}
              comment={comment}
            />
            :
            <Typography className={classes.contentText}>
              <LinkableText target="_blank">
                {comment.text}
              </LinkableText>
            </Typography>
        }

        {!comment.deleted_at && !isUpdate &&
          <ReplyForm
            disableForm={disableForm}
            comment={comment}
            parentCommentID={isSubComment ? parentCommentID : comment.id}
            recipientCommentID={comment.id}
          />
        }

        {comment.sub_comments_count > 0 &&
          <div>
            <Button
              size="small"
              className={classes.fetchSubButton}
              onClick={preventDefault(toggleIsShowSub)}
            >
              {!isRepliesOpened ?
                <>
                  {comment?.sub_comments_count > 1
                    ? `View ${comment.sub_comments_count} replies`
                    : `View ${comment.sub_comments_count} reply`
                  }
                  <ArrowDown fontSize="small" style={{ marginLeft: 10 }} />
                </>
                :
                <>
                  Hide replies
                  <ArrowUp fontSize="small" style={{ marginLeft: 10 }} />
                </>
              }
            </Button>
          </div>
        }

        {comment.sub_comments_count > 0 &&
          <Collapse in={isRepliesOpened}>
            <CommentsContextProvider initialState={initialState}>
              <SubComments
                width={width}
                parentPage={parentPage}
                isRepliesOpened={isRepliesOpened}
                parentCommentID={comment.id}
              />
            </CommentsContextProvider>
          </Collapse>
        }
      </div>
    </div>
  );
};
