import { useContext } from 'react';
import { omit, values } from 'lodash';
import { mdiFilterPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Box, IconButton, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { isEmptyDeep } from '../../../helpers/isEmptyDeep';
import * as filtersApi from '../../../api/filters';
import { useModal } from '../../ModalsProvider';
import { Popper } from '../../Popper';
import { Badge } from '../../Badge';
import { Fab } from '../../Fab';
import { SavedFiltersContext, SavedFiltersModal } from '../../saved-filters';

export const SavedFiltersWidget = ({
  modalWidth,
  filterKey,
  hiddenFields,
  onApplySavedFilter,
  relationsForFilter,
  CardContent,
  children
}) => {
  const theme = useTheme();
  const { openModal } = useModal();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { filter, addSavedFilter, deleteAction, addAction } = useContext(SavedFiltersContext);
  const isDisabled = isEmptyDeep(values(omit(relationsForFilter, [ 'order_by' ])));

  const saveCurrentFilter = () => {
    filtersApi.createFilter({
      entity: filter.entity,
      route: 'route',
      params: relationsForFilter
    }).then((data) => {
      addSavedFilter(data);
    });
  };

  const openSavedFiltersModal = () => {
    openModal(SavedFiltersModal, {
      payload: {
        filterKey,
        hiddenFields,
        relationsForFilter,
        onApplySavedFilter,
        CardContent,
        onFilterAdd: addAction,
        onFilterRemove: deleteAction
      }
    });
  };

  return (!isMobile || !!CardContent) && (
    <Popper
      placement="bottom-start"
      anchorRenderer={({ anchorRef, handleToggle }) => (
        <Badge color="success" badgeContent={filter.total}>
          <IconButton
            color="primary"
            edge="start"
            buttonRef={isMobile ? null : anchorRef}
            onClick={isMobile ? openSavedFiltersModal : handleToggle}
          >
            <SvgIcon>
              <Icon path={mdiFilterPlus} />
            </SvgIcon>
          </IconButton>
        </Badge>
      )}
    >
      {({ handleClose }) => (
        <Box minWidth={modalWidth} py={2}>
          <Box p={2} pt={0}>
            <Fab
              disabled={isDisabled}
              variant="extended"
              color="primary"
              startIcon={<AddIcon />}
              onClick={saveCurrentFilter}
            >
              Save current filter
            </Fab>
          </Box>

          {children({ handleClose })}
        </Box>
      )}
    </Popper>
  );
};
