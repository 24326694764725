import { api } from '../../api';

export const fetchCodes = (config) => {
  return api.get('/procedure-codes/procedure-codes-cpt', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchCode = (id, config) => {
  return api.get(`/procedure-codes/procedure-codes-cpt/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createCode = (data) => {
  return api.post('/procedure-codes/procedure-codes-cpt', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createCustomCode = (data) => {
  return api.post('/procedure-codes/procedure-codes-cpt/create-custom', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateCode = ({ id, ...data }) => {
  return api.put(`/procedure-codes/procedure-codes-cpt/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteCode = (id) => {
  return api.delete(`/procedure-codes/procedure-codes-cpt/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteCodes = (IDs) => {
  return api.post('/procedure-codes/procedure-codes-cpt/delete', { ids: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const importCode = (data) => {
  return api.post('/procedure-codes/procedure-codes-cpt/import', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const exportCode = (config) => {
  return api.get('/procedure-codes/procedure-codes-cpt/export?format=csv', config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error.data;
  });
};

export const downloadFileCodes = (config) => {
  return api.get('/procedure-codes/procedure-codes-cpt/export/download', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data;});
};

export const fetchTOSCodes = (config) => {
  return api.get('/procedure-codes/tos-codes', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchCodesModifier = (config) => {
  return api.get('/procedure-codes/modifier', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
