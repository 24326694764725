import { api } from '../api';

export const fetchFilesList = (fileId, config = {}) => {
  return api.get(`/ai/files/${fileId}/list`, config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const sendFile = (data) => {
  return api.post('/ai/run', data).then(({ data }) => data);
};

export const downloadPdf = (id, config) => {
  return api.get(`/ai/run/${id}/pdf`, {
    ...config,

    responseType: 'blob'
  });
};

export const downloadDocx = (id, config) => {
  return api.get(`/ai/run/${id}/docx`, {
    ...config,

    responseType: 'blob'
  });
};
