import { memo, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import {
  makeStyles,
  Box,
  Paper,
  Button,
  FormControlLabel,
  Switch,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { eventBus, eventBusEvents } from '../../../utils/eventBus';
import { IconButton } from '../../IconButton';
import { WidgetsMenu } from '../WidgetsMenu';
import { WidgetsPopper } from './WidgetsPopper';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ActionsBar = memo(({
  widgets,
  breakpoint,
  widgetsMap,
  checkedWidgetsTypes,
  onReset,
  onTakeItem,
  onPutItem,
  actionsBarLeftContent
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const [ checkedCount, setCheckedCount ] = useState({ checked: 0, total: 0 });
  const [ preventCollision, setPreventCollision ] = useState(false);
  const toolboxList = widgets?.toolbox?.[breakpoint] || [];
  const totalLength = Object.keys(widgetsMap).length;

  useEffect(() => {
    setCheckedCount({
      checked: totalLength - toolboxList.length,
      total: totalLength
    });
  }, [ widgets ]);

  const handleChangePreventCollision = (event) => {
    eventBus.dispatch(eventBusEvents.preventCollision, !event.target.checked);
  };

  const toggleChangePreventCollision = () => {
    eventBus.dispatch(eventBusEvents.preventCollision, !preventCollision);
  };

  useEffect(() => {
    const togglePreventCollision = (checked) => {
      setPreventCollision(checked);
    };

    eventBus.on(eventBusEvents.preventCollision, togglePreventCollision);

    return () => {
      eventBus.remove(eventBusEvents.preventCollision, togglePreventCollision);
    };
  }, []);

  return (
    <Paper square={false} className={classes.root}>
      {!!actionsBarLeftContent &&
        <Box flexGrow={1} pr={isMobile ? 0 : 2}>
          {actionsBarLeftContent}
        </Box>
      }

      <Box display="flex" alignItems="center">
        <WidgetsPopper
          checked={checkedCount.checked}
          total={checkedCount.total}
          checkedWidgetsTypes={checkedWidgetsTypes}
        >
          <WidgetsMenu
            widgets={widgets}
            widgetsMap={widgetsMap}
            checkedWidgetsTypes={checkedWidgetsTypes}
            onReset={onReset}
            onTakeItem={onTakeItem}
            onPutItem={onPutItem}
            setCheckedCount={setCheckedCount}
          />
        </WidgetsPopper>

        {!isMobile ? (
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={!preventCollision}
                onChange={handleChangePreventCollision}
              />
            }
            label={preventCollision ? 'Unlock' : 'Lock'}
            labelPlacement="start"
            className={classes.actionSwitcher}
          />
        ) : (
          <Button
            variant="text"
            color="primary"
            startIcon={preventCollision ? <LockOpenIcon color="primary" /> : <LockIcon color="primary" />}
            onClick={toggleChangePreventCollision}
          >
            <Switch
              color="primary"
              checked={!preventCollision}
            />
          </Button>
        )}

        {!isMobile ? (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={onReset}
          >
            Reset
          </Button>
        ) : (
          <IconButton
            edge="end"
            color="primary"
            onClick={onReset}
          >
            <BackupIcon />
          </IconButton>
        )}
      </Box>
    </Paper>
  );
}, isEqual);
