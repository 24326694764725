import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import moment from 'moment';
import {
  Grid,
  Box,
  Button,
  Collapse,
  SvgIcon,
  Typography,
  Dialog,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import * as timeApi from '../../../api/time-tracks';
import { TimeTrackingProvider } from '../../TimeTracking';
import { setTracksLastGlobalAction } from '../../../store/globalActions';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import TrackerPlusSvg from '../../icons/chronometer-add.svg?react';
import { TextField } from '../../FormField';
import * as types from '../../TimeTracking/timeTrackerHelpers/types';
import { Loader } from '../../Loader';
import { UsedTimeTracks, validationSchema } from '../LogTimeModal';
import { TimeContent } from '../LogTimeModal/TimeContent';
import { tracksTypes, tracksTypesOptions } from './tracksTypesOptions';

export const EditLogTimeModal = ({
  DialogProps,
  payload: {
    caseItem = null,
    initialValues
  },
  handleModalReject,
  handleModalResolve
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();
  const { pathname } = useLocation();
  const currentUser = useSelector(({ profile: { user } }) => user);
  const duration = initialValues.time && moment.duration(initialValues.time, 'seconds');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isModelFields = pathname.includes('profile');

  const updateTrack = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.billing_time = moment.duration(`${values.hours || 0}:${values.minutes || 0}`).asSeconds() || null;
    values.time_track_model_id = values?.model_id?.id || values?.model_id;
    values.model = values?.model?.value || values?.model;
    values.model_id = values?.model_id?.value || values?.model_id?.id || values?.model_id;

    return timeApi.updateTrack({ id: initialValues.id, ...values }).then(({ data }) => {
      dispatchRedux(setTracksLastGlobalAction({ type: types.UPDATE_TRACK, payload: data }));
      enqueueSnackbar('Timetrack successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ data: { errors, code } }) => {
      if (code === 'time_track.cannot_edit_used') {
        enqueueSnackbar('Can\'t edit used timetrack', { variant: 'error' });
      } else {
        enqueueSnackbar('Timetrack not updated', { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        model: tracksTypesOptions.find(({ value }) => value === initialValues.model),
        model_id: tracksTypes?.[initialValues.model]?.transform({
          ...initialValues.time_trackingable,

          started_at: initialValues.started_at
        }),
        started_at: initialValues.started_at,
        finished_at: initialValues.finished_at,
        hours: duration.hours() || 0,
        minutes: duration.minutes() || 0,
        company_id: currentUser?.work?.company,
        category_id: initialValues?.category,
        user_id: initialValues?.user || null
      }}
      validationSchema={validationSchema}
      onSubmit={updateTrack}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><TrackerPlusSvg /></SvgIcon>}
              onClose={handleModalReject}
            >
              Edit Log Time
            </ModalHeader>

            <ModalBody>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={12} container spacing={3} alignItems="flex-start">
                  <TimeContent
                    caseItem={caseItem}
                    isModelFields={isModelFields}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    name="description"
                    label="Note"
                    margin="normal"
                    variant="filled"
                    InputProps={{
                      disableUnderline: true
                    }}
                    rows={4}
                  />
                </Grid>
              </Grid>

              <Box mt={3}>
                <Collapse in={moment(values.started_at, 'X', true)?.isValid()}>
                  <Typography variant="h6" gutterBottom>List of tracks</Typography>

                  <TimeTrackingProvider>
                    <UsedTimeTracks event={initialValues.event} />
                  </TimeTrackingProvider>
                </Collapse>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
