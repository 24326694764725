import { SvgIcon } from '@material-ui/core';
import CardMembershipIcon from '@material-ui/icons/CardMembershipOutlined';
import { mdiAccountCogOutline, mdiAccountKeyOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import LockUserSvg from '../../../../../../components/icons/lock-user-icon.svg?react';

export const membersMenu = [
  {
    name: 'Members',
    icon: CardMembershipIcon,
    path: '/members',
    routes: [
      {
        name: 'Users',
        icon: (props) => (
          <SvgIcon {...props}>
            <Icon path={mdiAccountCogOutline} />
          </SvgIcon>
        ),
        path: '/members/users'
      },
      {
        name: 'Roles',
        icon: (props) => (
          <SvgIcon {...props}>
            <Icon path={mdiAccountKeyOutline} />
          </SvgIcon>
        ),
        path: '/members/roles'
      },
      {
        name: 'Feature Flags',
        icon: (props) => (
          <SvgIcon {...props}>
            <LockUserSvg />
          </SvgIcon>
        ),
        path: '/members/feature-flags'
      }
    ]
  }
];
