import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_SUPPORT_TICKETS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_SUPPORT_TICKETS_SUCCESS]: (
    { filter, supportTickets, ...state },
    { data: newSupportTicket, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      supportTickets: (page > 1
        ? supportTickets.filter(({ id }) => {
          return !newSupportTicket.find((loadedClaim) => id === loadedClaim.id);
        }).concat(newSupportTicket)
        : newSupportTicket
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_SUPPORT_TICKETS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_SUPPORT_TICKET]: (state, supportTicket) => {
    return {
      ...state,

      supportTickets: state.supportTickets.map((item) => {
        return item.id === supportTicket.id ? { ...item, ...supportTicket } : item;
      })
    };
  },

  [types.ADD_SUPPORT_TICKET]: (state, supportTicket) => {
    const { pagination, filter, supportTickets } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((supportTickets.length + 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      supportTickets: [ supportTicket, ...supportTickets ]
    };
  },

  [types.DELETE_SUPPORT_TICKET]: (state, supportTicketID) => {
    const { pagination, filter, supportTickets } = state;
    const filteredSupportTickets = supportTickets.filter(({ id }) => supportTicketID !== id);

    if (filteredSupportTickets.length === supportTickets.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((supportTickets.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      supportTickets: filteredSupportTickets
    };
  }
});
