import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../listConfig';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.number}>
          Ticket # / Description
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.category}>
          Category
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.status}>
          Status
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.creationDate}>
          Creation date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.latestUpdates}>
          Latest updates
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.office}>
          Office
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.creator}>
          Creator
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.assignee}>
          Assignee
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
