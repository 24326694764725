import { SvgIcon } from '@material-ui/core';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import { mdiFileAccountOutline, mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import TemplateSvg from '../../../../../../components/icons/template.svg?react';

export const medicalFormsMenu = [
  {
    path: '/medical-forms',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileAccountOutline} /></SvgIcon>,
    name: 'Medical Forms'
  },
  {
    path: '/diagnosis',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>,
    name: 'Diagnosis',
    routes: [
      {
        exact: true,
        icon: (props) => <SvgIcon {...props}><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>,
        name: 'Diagnosis List',
        path: '/diagnosis'
      },
      {
        icon: (props) => (
          <SvgIcon {...props}>
            <TemplateSvg />
          </SvgIcon>
        ),
        name: 'Templ. Med Reports',
        path: '/diagnosis/templates'
      }
    ]
  },
  {
    path: '/prescriptions',
    icon: PostAddOutlinedIcon,
    name: 'Prescriptions',
    routes: [
      {
        exact: true,
        icon: PostAddOutlinedIcon,
        name: 'Prescriptions List',
        path: '/prescriptions'
      },
      {
        icon: (props) => (
          <SvgIcon {...props}>
            <TemplateSvg />
          </SvgIcon>
        ),
        name: 'Templ. Prescriptions',
        path: '/prescriptions/templates'
      }
    ]
  }
];
