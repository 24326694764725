import { useEffect } from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Label } from '../../../../../../components/Label';
import { Loader } from '../../../../../../components/Loader';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { categoryLabelsMap } from '../../../../../../components/support/categoriesOptions';
import { statusColorsMap, statusLabelsMap } from '../../../../../../components/support/statusesOptions';
import { useSupportTicket } from '../../../../../../components/support/useSupportTicket';
import { UserLink } from '../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../helpers/dom';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../components/ListRow';
import { IconButton } from '../../../../../../components/IconButton';
import { Popper } from '../../../../../../components/Popper';
import { Menu } from '../../Menu';
import { columnsWidths } from '../listConfig';

export const Row = ({ item: ticket, isLoaded, recalculateHeight }) => {
  const { filesLength, openSupportTicketPreviewModal } = useSupportTicket(ticket);

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={stopPropagation(openSupportTicketPreviewModal)}>
      <ListRowCell grow noWrap flexBasis={columnsWidths.number}>
        #{ticket?.number}
        &nbsp;
        {!!filesLength &&
          <Typography component="span" variant="body2" color="textSecondary">
            <AttachFileOutlinedIcon fontSize="small" color="inherit" />{filesLength}
          </Typography>
        }
        &nbsp;
        &nbsp;
        {!!ticket?.comments_count &&
          <Typography component="span" variant="body2" color="textSecondary">
            <CommentOutlinedIcon fontSize="small" color="inherit" /> {ticket?.comments_count}
          </Typography>
        }

        <Typography noWrap>{ticket?.description}</Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.category}>
        {categoryLabelsMap[ticket?.category] || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.status}>
        <Label label={statusLabelsMap[ticket?.status]} color={statusColorsMap[ticket?.status]} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.creationDate}>
        {moment.utc(ticket?.created_at).local().format('L LT')}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.latestUpdates}>
        {moment.utc(ticket?.changed_at).local().format('L LT')}
        <br />
        <UserLink size="xs" user={ticket?.modifier} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.office}>
        <OfficeLink office={ticket?.office} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.creator}>
        <UserLink user={ticket?.creator} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.assignee}>
        <UserLink user={ticket?.assignee} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <ListRowCellActions>
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton
                  edge="end"
                  buttonRef={anchorRef}
                  onClick={stopPropagation(handleToggle)}
                >
                  <MoreVertIcon/>
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <Menu supportTicket={ticket} onClose={handleClose} />
            )}
          </Popper>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
