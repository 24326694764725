import { createContext, useRef, useEffect, useReducer } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../api';
import { usePrevious } from '../../../helpers/hooks';
import * as supportTicketsApi from '../../../api/support';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const SupportTicketsContext = createContext();

export const SupportTicketsProvider = ({
  filter: filterProp = {},
  children
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    supportTickets
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchSupportTickets = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_SUPPORT_TICKETS_REQUEST });

    supportTicketsApi.fetchSupportTickets({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_SUPPORT_TICKETS_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchSupportTickets({ page: filter.page + 1 });
    }
  };

  const resetSupportTickets = (newFilter) => {
    dispatch({ type: types.RESET_SUPPORT_TICKETS, payload: newFilter });

    fetchSupportTickets({ page: 1, ...newFilter });
  };

  const addTicket = (supportTicket) => {
    dispatch({ type: types.ADD_SUPPORT_TICKET, payload: supportTicket });
  };

  const updateSupportTicket = (supportTicket) => {
    dispatch({ type: types.UPDATE_SUPPORT_TICKET, payload: supportTicket });
  };

  const deleteSupportTicket = (supportTicketId) => {
    dispatch({ type: types.DELETE_SUPPORT_TICKET, payload: supportTicketId });
  };

  const providerValue = {
    isFetched,
    defaultFilter: filterProp,
    isFetching,
    supportTickets,
    filter: filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    resetSupportTickets,
    fetchSupportTickets,
    loadNextPage,
    applyFilter,
    addTicket,
    updateSupportTicket,
    deleteSupportTicket
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetSupportTickets(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <SupportTicketsContext.Provider value={providerValue}>
      {children}
    </SupportTicketsContext.Provider>
  );
};
