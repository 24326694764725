import { useContext, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { SupportTicketsContext } from '../../../../components/support/SupportTicketsProvider';
import { Grid } from './Grid';
import { List } from './List';

export const MainContent = () => {
  const { viewVariant } = useContext(LayoutContext);
  const { resetSupportTickets } = useContext(SupportTicketsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    resetSupportTickets();
  }, []);

  return isMobile
    ? <Grid />
    : viewVariant === viewVariantsMap.list
      ? <List />
      : <Grid />;
};
