import { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Box, Button, Typography, Link } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import * as authApi from '../../api/auth';
import { useQueryParams } from '../../helpers/hooks/useQueryParams';
import { Yup } from '../../utils/validation';
import { TextField } from '../../components/FormField';
import LogoSVG from '../Dashboard/Layout/Sidebar/SidebarContent/logo.svg?react';
import { Loader } from '../../components/Loader';
import { Page, PageBody } from '../../components/Page';

const initialValues = {
  code: null
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    padding: spacing(1.5, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.grey[600]
  },

  logo: {
    width: 64,
    verticalAlign: 'bottom'
  }
}));

export const FilesSharingPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const { token } = useQueryParams();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isRequestingNewCode, setIsRequestingNewCode ] = useState(false);

  const requestNewCode = () => {
    setIsRequestingNewCode(true);

    authApi.publicAccessConfirmation(null, {
      headers: {
        authorization: `Bearer ${token}`
      }
    }).then(() => {
      setIsLoading(false);
      setIsRequestingNewCode(false);

      enqueueSnackbar('Сode has been sent, please check your email', {
        variant: 'success'
      });
    });
  };

  const handleCodeSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return authApi.publicAccessRenew(values, {
      headers: {
        authorization: `Bearer ${token}`
      }
    }).then(() => {
      history.push(`/public-share/files?token=${token}`);
    }).catch(() => {
      enqueueSnackbar('The code is incorrect or has expired, we resubmitted a new code', {
        variant: 'warning'
      });
    });
  };

  useEffect(() => {
    requestNewCode();
  }, []);

  return (
    <Page>
      <Loader loading={isLoading} p={2} render={
        () => (
          <PageBody fullHeight disablePaddings>
            <div className={classes.header}>
              <NavLink to="/">
                <LogoSVG className={classes.logo} />
              </NavLink>
            </div>

            <Box pt={4}>
              <Typography variant="h3" align="center">
                Please check your email inbox and enter security code below
              </Typography>
            </Box>

            <Box maxWidth={600} mx="auto">
              <Box my={4}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={Yup.object({ code: Yup.string().nullable().required() })}
                  onSubmit={handleCodeSubmit}
                >
                  {({ isSubmitting, handleSubmit }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <TextField
                        required
                        name="code"
                        label="Code"
                        placeholder="Enter code..."
                        margin="dense"
                      />

                      <Box my={1}>
                        <Loader fullWidth surface loading={isSubmitting} render={
                          () => (
                            <Button
                              fullWidth
                              disabled={isSubmitting}
                              type="submit"
                              variant="contained"
                              color="primary"
                              endIcon={<ArrowForwardIcon />}
                            >
                              Next
                            </Button>
                          )}
                        />
                      </Box>
                    </form>
                  )}
                </Formik>

                <Loader fullWidth surface loading={isRequestingNewCode} render={
                  () => (
                    <Typography color="textSecondary">
                      Didn't receive your code?
                      &nbsp;
                      <Link variant="subtitle1" onClick={requestNewCode}>
                        Get a new one
                      </Link>
                    </Typography>
                  )}
                />
              </Box>
            </Box>
          </PageBody>
        )}
      />
    </Page>
  );
};
