import { SvgIcon } from '@material-ui/core';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import HistoryIcon from '@material-ui/icons/History';
import { Icon } from '@mdi/react';
import {
  mdiFileMultipleOutline,
  mdiFileReplaceOutline,
  mdiTrashCanOutline,
  mdiCogs,
  mdiMicrophoneMessage
} from '@mdi/js';
import SearchFileSvg from '../../../components/icons/file-search.svg?react';
import TemplateSvg from '../../../components/icons/template.svg?react';
import { filtersKeysMap } from '../../../store/lastFilters';
import { FilesSettingsPage } from '../FilesSettingsPage';
import { TagsPage } from '../SystemSettings';
import { PrinterConfiguration } from './PrinterConfiguration';
import { TemplateCreationPage } from './TemplatesPage/TemplateCreationPage';
import { SearchDocumentsPage } from './SearchDocumentsPage';
import { AccessControlPage } from './AccessControlPage';
import { RecentFilesPage } from './RecentFilesPage';
import { SharedFilesPage } from './SharedFilesPage';
import { TemplatesPage } from './TemplatesPage';
import { MyFilesPage } from './MyFilesPage';
import { MergePdf } from './MergePdf';
import { TranscribesPage } from './TranscribesPage';
import { Trash } from './Trash';

export const routes = [
  {
    exact: true,
    path: '/files',
    icon: <SvgIcon><Icon path={mdiFileMultipleOutline} /></SvgIcon>,
    label: 'My Files',
    component: (props) => <MyFilesPage filterKey={filtersKeysMap.files_myFiles} {...props} />
  },
  {
    path: '/files/shared',
    icon: <PeopleOutlineOutlinedIcon />,
    label: 'Shared with me',
    component: (props) => <SharedFilesPage filterKey={filtersKeysMap.files_shared} {...props} />
  },
  {
    path: '/files/recent',
    icon: <HistoryIcon />,
    label: 'Recent',
    component: (props) => <RecentFilesPage filterKey={filtersKeysMap.files_recent} {...props} />
  },
  {
    path: '/files/search',
    icon: <SvgIcon><SearchFileSvg /></SvgIcon>,
    label: 'Search',
    component: SearchDocumentsPage
  },
  {
    path: '/files/merge-pdf',
    icon: <SvgIcon><Icon path={mdiFileReplaceOutline} /></SvgIcon>,
    label: 'Merge PDF',
    component: MergePdf
  },
  {
    path: '/files/transcribes',
    icon: <SvgIcon><Icon path={mdiMicrophoneMessage} /></SvgIcon>,
    label: 'Transcribes',
    component: TranscribesPage
  },
  {
    path: '/files/recycle-bin',
    icon: <SvgIcon><Icon path={mdiTrashCanOutline} /></SvgIcon>,
    label: 'Recycle Bin',
    component: Trash
  },
  {
    path: '/files/templates/new',
    icon: null,
    label: null,
    component: TemplateCreationPage
  },
  {
    path: '/files/templates',
    icon: <SvgIcon><TemplateSvg /></SvgIcon>,
    label: 'Templates',
    component: TemplatesPage
  },
  {
    exact: true,
    path: '/files/from-printers',
    icon: <PrintOutlinedIcon />,
    label: 'Printers',
    component: PrinterConfiguration
  },
  {
    path: '/files/access-control',
    icon: <MailOutlinedIcon />,
    label: 'Access Control',
    component: AccessControlPage
  },
  {
    path: '/files/system-settings/tags',
    icon: null,
    label: null,
    component: TagsPage
  },
  {
    path: '/files/system-settings/configuration',
    icon: null,
    label: null,
    component: PrinterConfiguration
  },
  {
    path: '/files/system-settings',
    icon: <SvgIcon><Icon path={mdiCogs} /></SvgIcon>,
    label: 'Settings',
    component: FilesSettingsPage
  }
];
