import { useState } from 'react';
import { Formik } from 'formik';
import {
  Button,
  InputAdornment,
  IconButton,
  SvgIcon,
  Dialog
} from '@material-ui/core';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../components/Modal';
import { TextField } from '../../../../components/FormField';
import { VerticalDivider } from '../../../../components/VerticalDivider';
import * as videoApi from '../../../../api/videos';
import { Loader } from '../../../../components/Loader';
import { Yup } from '../../../../utils/validation';
import EditSvg from '../../../../components/icons/edit.svg?react';

export const RenameVideoModal = ({
  DialogProps,
  payload: { file },
  handleModalResolve,
  handleModalReject
}) => {
  const [ initialName ] = useState(file.original_filename);

  const resetFileName = (setFieldValue) => () => {
    setFieldValue('original_filename', initialName);
  };

  const renameFile = ({ original_filename }, { setErrors, setSubmitting }) => {
    file.original_filename = original_filename;

    videoApi.renameVideo(file).then(() => {
      handleModalResolve();
    }).catch(({ status, data: { errors } }) => {
      setSubmitting(false);

      if (status === 422) {
        setErrors({
          ...errors,
          original_filename: errors.extension || errors.original_filename
        });
      } else if (status === 409) {
        setErrors({
          original_filename: [ 'File with same name is now exist' ]
        });
      }
    });
  };

  return (
    <Dialog maxWidth="md" {...DialogProps}>
      <Formik
        initialValues={{
          original_filename: initialName
        }}
        validationSchema={
          Yup.object().shape({ original_filename: Yup.string().required() })
        }
        onSubmit={renameFile}
      >
        {({ values: { original_filename }, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject} icon={<SvgIcon><EditSvg /></SvgIcon>}>
              Rename file
            </ModalHeader>

            <ModalBody>
              <TextField
                name="original_filename"
                label="New name"
                placeholder="Enter file name..."
                margin="dense"
                InputProps={{
                  autoFocus: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <VerticalDivider />

                      <IconButton
                        title="Reset"
                        disabled={original_filename === initialName}
                        onClick={resetFileName(setFieldValue)}
                      >
                        <SettingsBackupRestoreIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </ModalBody>

            <ModalFooter>
              <Loader loading={false} surface render={
                () => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={false}
                    onClick={handleModalReject}
                  >
                    Skip
                  </Button>
                )}
              />

              <Loader  surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || original_filename === initialName}
                  >
                    Rename
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
