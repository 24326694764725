import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as casesApi from '../../../../../../../../api/cases';
import EditIcon from '../../../../../../../../components/icons/square-edit-icone.svg?react';
import { Loader } from '../../../../../../../../components/Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../../../components/Modal';
import { Body } from './Body';
import { validationSchema } from './validationSchema';

export const EditDirectReferralModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    dirreffInfo = {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateCase = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.updateCase({ id: dirreffInfo.id, ...values }).then((data) => {
      enqueueSnackbar('Case successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        dirreff_attorney: dirreffInfo.dirreff_attorney > 0 ? '1' : '0',
        direct_attorney_company_id: dirreffInfo.direct_attorney_company,
        direct_ref_address: dirreffInfo?.direct_attorney_company?.full_address || null
      }}
      validationSchema={validationSchema}
      onSubmit={handleUpdateCase}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Edit Direct Referral to attorney
            </ModalHeader>

            <ModalBody>
              <Body />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
