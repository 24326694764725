import { useEffect } from 'react';
import cn from 'classnames';
import {
  makeStyles,
  Typography,
  SvgIcon,
  Link
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserIcon from '@material-ui/icons/PermIdentity';
import { apiModelsMap } from '../../../../dataMaps/apiModels';
import { amFormatDuration } from '../../../../helpers/dates';
import { stopPropagation } from '../../../../helpers/dom';
import { Label } from '../../../../app/Dashboard/TasksPage/MainWindow/Label';
import { CurrencyFormat } from '../../../CurrencyFormat';
import TrackerPlusSvg from '../../../icons/chronometer.svg?react';
import { ListRow, ListRowCell } from '../../../ListRow';
import { CompanyLink } from '../../../CompanyLink';
import { IconButton } from '../../../IconButton';
import { useModal } from '../../../ModalsProvider';
import { ViewScheduleEventModal } from '../../../schedule-events';
import { UserLink } from '../../../UserLink';
import { Tooltip } from '../../../Tooltip';
import { Popper } from '../../../Popper';
import { Loader } from '../../../Loader';
import { Date } from '../../../Date';
import { getProps } from '../helper';
import { columnsWidths, minRowHeight, widthBreakpointSmall } from '../TimeTracking';
import { trackModelsColorsMap, trackModelsMap } from '../trackModelsMap';
import { Menu } from '../Menu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  item: track = {},
  width,
  caseItem,
  disableActions = false,
  isLoaded,
  recalculateHeight
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const nameProps = getProps(track, classes);
  const total = track.billing_total || track.total;
  const scheduleEventProps = track.model !== apiModelsMap.schedule_event ? {} : {
    component: Link,
    color: 'primary',
    onClick: () => {
      openModal(ViewScheduleEventModal, {
        payload: {
          eventId: track.time_trackingable?.id
        }
      });
    }
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, width ]);

  return !isLoaded ? <Loader className={classes.loader} /> : (
    <ListRow
      className={
        cn(
          classes.root,
          { [classes.root_widget]: width < widthBreakpointSmall }
        )
      }
      minHeight={minRowHeight}
    >
      <ListRowCell flexBasis={columnsWidths.actionIcon}>
        {track.is_manual ?
          <UserIcon className={cn(classes.trackTypeIcon, classes.trackTypeIcon_user)} />
          :
          <SvgIcon className={cn(classes.trackTypeIcon, classes.trackTypeIcon_tracker)}>
            <TrackerPlusSvg />
          </SvgIcon>
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.parent}>
        <Label name={trackModelsMap[track.model]} color={trackModelsColorsMap[track.model]} />
      </ListRowCell>

      <ListRowCell grow column flexBasis={columnsWidths.parent_note}>
        <Typography noWrap {...nameProps} {...scheduleEventProps}>
          {track?.time_trackingable?.name || 'No info'}
        </Typography>

        <Tooltip
          isExistTooltip={!!track.description || !!track.note}
          title={track.description || track.note}
        >
          <Typography noWrap color="textSecondary" variant="caption">
            {track.description}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.user}>
        <UserLink noWrap user={track.user} />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.company}>
        <CompanyLink noWrap company={track?.user?.work?.company} />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.category}>
        <Typography noWrap>
          {track.category?.name || ''}
        </Typography>
      </ListRowCell>

      <ListRowCell column flexBasis={columnsWidths.startedTime}>
        <div>
          <Date noWrap disableIcon date={track.started_at} format="L LT" />
          {' -'}
        </div>

        <Date noWrap disableIcon date={track.finished_at} format="L LT" />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.duration}>
        {track.finished_at ? amFormatDuration(track.finished_at - track.started_at) : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.total}>
        <CurrencyFormat value={total} />
      </ListRowCell>

      {!disableActions && (
        <ListRowCell flexBasis={columnsWidths.action}>
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                  <MoreVertIcon />
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <Menu
                disableActions={disableActions}
                track={track}
                caseItem={caseItem}
                onClose={handleClose}
              />
            )}
          </Popper>
        </ListRowCell>
      )}
    </ListRow>
  );
};
