import { mdiFaceAgent } from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch } from 'react-redux';
import {
  Box,
  SvgIcon,
  useTheme,
  makeStyles,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { useLocation } from 'react-router-dom';
import { useModal } from '../../../../components/ModalsProvider';
import { CreateSupportTicketModal } from '../../../../components/support/CreateSupportTicketModal';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { toggleSidebar, toggleUserInfo } from '../../../../store/dashboard/layout';
import { hasRole } from '../../../../utils/hasRole';
import { AppBar } from '../../../../components/AppBar';
import ChatSquaredSvg from '../../../../components/icons/chat-squared.svg?react';
import { FeedsProvider } from '../../../../components/feeds/FeedsProvider';
import { ActivityTimer } from '../../TimeTracking/ActivityTimer';
import { SearchProvider } from './Search/SearchProvider';
import { UploadsWidget } from './UploadsWidget';
import { Breadcrumbs } from './Breadcrumbs';
import { WorkClock } from './WorkClock';
// Don't shorten path, compiler don't see file for some reason
import { ProfileMenu } from './ProfileMenu/ProfileMenu';
import { Search } from './Search';
import { Feeds } from './Feeds';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ toggleChatWidget }) => {
  const { openModal } = useModal();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobileXS = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileXs));
  const showButton = pathname.includes('profile')
    || pathname.includes('patients/')
    || pathname.includes('members/users/');

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  const handleUserInfoToggle = () => {
    dispatch(toggleUserInfo());
  };

  const addSupportTicket = () => {
    openModal(CreateSupportTicketModal);
  };

  return (
    <AppBar
      className={classes.appBar}
      ToolbarProps={{ variant: 'regular', className: classes.toolbar }}
    >
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        edge="start"
        className={classes.menuButton}
        onClick={handleSidebarToggle}
      >
        <MenuIcon />
      </IconButton>

      {showButton && (
        <IconButton
          color="primary"
          aria-label="Open user information"
          edge="end"
          className={classes.menuButton}
          onClick={handleUserInfoToggle}
        >
          <AccountBoxIcon />
        </IconButton>
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent={!isTablet ? 'space-between' : 'flex-end'}
        flexGrow={1}
        ml={isMobile ? 1 : 3}
      >
        {!isTablet && (
          <Box display="flex" alignItems="center">
            <Breadcrumbs />

            <Box ml={3}>
              <SearchProvider>
                <Search />
              </SearchProvider>
            </Box>
          </Box>
        )}

        <Box display="flex" alignItems="center">
          {!hasRole(rolesMap.client, rolesMap.patient) && (
            <Box mr={isMobile ? 1 : 2}>
              <WorkClock/>
            </Box>
          )}

          {!isMobile && (
            <>
              <IconButton onClick={addSupportTicket}>
                <SvgIcon>
                  <Icon path={mdiFaceAgent} />
                </SvgIcon>
              </IconButton>

              <FeedsProvider>
                <Feeds />
              </FeedsProvider>

              <UploadsWidget />

              {!hasRole(rolesMap.medic, rolesMap.doctor) && <ActivityTimer />}
            </>
          )}
        </Box>
      </Box>

      <Box ml="auto">
        {!isMobileXS && (
          <IconButton onClick={toggleChatWidget}>
            <SvgIcon>
              <ChatSquaredSvg />
            </SvgIcon>
          </IconButton>
        )}
      </Box>

      <ProfileMenu toggleChatWidget={toggleChatWidget} />
    </AppBar>
  );
};
