import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Dialog, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { api } from '../../../api';
import * as userApi from '../../../api/users';
import { caseUserRolesMap } from '../../../dataMaps';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { apiModelsMap, itemsRoutesByModels } from '../../../dataMaps/apiModels';
import { hasRole } from '../../../utils/hasRole';
import { useChatActions } from '../../../utils/useChatActions';
import ChatSvg from '../../icons/chat-squared.svg?react';
import { IconButton } from '../../IconButton';
import { Loader } from '../../Loader';
import { ModalBody, ModalContainer, ModalHeader, ModalHeaderActions } from '../../Modal';
import { Tooltip } from '../../Tooltip';
import { Body } from './Body';
import { CaseUserBody } from './CaseUserBody';
import { Header } from './Header';

export const UserPreviewModal = ({
  DialogProps,
  handleModalReject,
  payload: { id, caseUser = null }
}) => {
  const { isChatOpening, openChatWithUser } = useChatActions();
  const [ user, setUser ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const cancelFetch = useRef(() => {});
  const role = caseUser?.case_role || user?.role || '-';
  const pageRoute = pathname.includes('members')
    ? itemsRoutesByModels[apiModelsMap.user](user?.id)
    : user?.role === rolesMap.patient
      ? itemsRoutesByModels[apiModelsMap.patient](user?.id)
      : itemsRoutesByModels[apiModelsMap.user](user?.id);

  const openChat = () => {
    openChatWithUser(user?.id, true);
  };

  useEffect(() => {
    setIsFetched(false);

    userApi.fetchUser(id, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      setUser(data);
      setIsFetched(true);
    }).catch((error) => {
      if (error) {
        enqueueSnackbar('User not found', { variant: 'warning' });
        setIsFetched(true);
      }
    });

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <ModalContainer>
        <Loader loading={!isFetched} p={3} render={
          () => (
            <>
              <ModalHeader onClose={handleModalReject}>
                {!!user && !hasRole(rolesMap.patient) &&
                  <ModalHeaderActions disableGutters>
                    <Loader surface loading={isChatOpening}>
                      <Tooltip title="Open chat with user">
                        <IconButton onClick={openChat}>
                          <SvgIcon>
                            <ChatSvg />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </Loader>

                    <Tooltip title="Open page on new window">
                      <IconButton component={RouterLink} to={pageRoute} target="_blank">
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  </ModalHeaderActions>
                }
              </ModalHeader>

              <ModalBody>
                {user ?
                  <>
                    <Header disableOffice={caseUser?.id} user={user} role={role} />

                    {caseUser?.id && caseUserRolesMap[caseUser?.case_role]
                      ? <CaseUserBody user={user} caseUser={caseUser} />
                      : <Body user={user} />
                    }
                  </>
                  :
                  <Box pb={5}>
                    <Typography align="center" color="textSecondary">User not found</Typography>
                  </Box>
                }
              </ModalBody>
            </>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
