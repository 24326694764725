import { useState } from 'react';
import { isString } from 'lodash';
import { Yup } from '../../../../utils/validation';
import { UsersSelect } from '../../../users';
import { AddOption } from './AddOption';
import { Label } from './Label';

const renderOption = (option) => {
  return !option?.isCreatableOption ? (
    <Label user={option} />
  ) : (
    <AddOption option={option} />
  );
};

export const EmailsSelect = ({ params = {}, ...props }) => {
  const [ isCreatable, setIsCreatable ] = useState(false);

  const handleCreate = (inputValue) => {
    return Promise.resolve(inputValue);
  };

  const handleInputChange = (event, inputValue) => {
    Yup.string().email().validate(inputValue).then(() => {
      setIsCreatable(true);
    }).catch(() => {
      setIsCreatable(false);
    });
  };

  return (
    <UsersSelect
      isCreatable={isCreatable}
      label="Email"
      placeholder="Enter email..."
      params={{ ...params, with_email: 1 }}
      renderOption={renderOption}
      onCreate={handleCreate}
      getOptionLabel={(option) => isString(option) ? option : (option?.email || '')}
      getOptionValue={(option) => option?.email || option}
      getOptionSelected={(option, value) => option?.email === (value?.email || value)}
      onInputChange={handleInputChange}

      {...props}
    />
  );
};
