import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, IconButton, Typography, useTheme, Box } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { CaseMenu } from '../../../../app/Dashboard/CasesPage/Cases/CaseMenu';
import { itemsRoutesByModels } from '../../../../dataMaps/apiModels';
import { stopPropagation } from '../../../../helpers/dom';
import { Date } from '../../../Date';
import { Label } from '../../../Label';
import { ListRow, ListRowCell } from '../../../ListRow';
import { Loader } from '../../../Loader';
import { Members } from '../../../Members';
import { Popper } from '../../../Popper';
import { columnsWidths } from '../columnsWidths';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item: caseItem = {}, isLoaded, recalculateHeight }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const types = caseItem.case_type && caseItem.case_type.map(({ name }) => name).join(', ');
  const owner = caseItem?.case_users?.find(({ case_role }) => case_role === 'creator');

  const getCasePage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.case(caseItem.id));
    }
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={getCasePage}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Box mr={1}>
          <WorkIcon className={classes.caseIcon} />
        </Box>

        <Typography noWrap title={caseItem.file_number}>
          {caseItem.file_number || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        <Date date={caseItem.opened_at} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.type}>
        <Typography noWrap title={types}>
          {types}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        {caseItem.case_status
          ? <Label label={caseItem.case_status.name} color={theme.palette.primary.main} />
          : '-'
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.members}>
        <Members
          noWrap
          isParent
          maxVisible={4}
          owner={owner}
          users={caseItem.case_users || []}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
              <MoreVertIcon />
            </IconButton>
          )}
        >
          {({ handleClose }) => (
            <CaseMenu
              caseItem={caseItem}
              onClose={handleClose}
            />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
