import { Route } from 'react-router-dom';
import { Box } from '@material-ui/core';
import FullLogoSVG from '../Dashboard/Layout/Sidebar/SidebarContent/logo-full.svg?react';
import { LandingWrapper } from './LandingWrapper';

export const LandingPage = () => {
  return (
    <LandingWrapper>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Route path="/">
          <Box width="50%">
            <FullLogoSVG />
          </Box>
        </Route>
      </Box>
    </LandingWrapper>
  );
};
