import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Dialog, Button, SvgIcon } from '@material-ui/core';
import * as filesApi from '../../../../api/files';
import { tagsActionsMap } from '../../../../api/files/tags';
import { getFolderParentName } from '../../../../components/Tags/getFolderParentName';
import { setFilesLastGlobalAction } from '../../../../store/globalActions';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../components/Modal';
import LabelSvg from '../../../../components/icons/label.svg?react';
import { TagsSelect } from '../../../../components/Tags/TagsSelect';
import { Select } from '../../../../components/FormField';
import { Loader } from '../../../../components/Loader';
import * as filesActionTypes from '../FilesContext/types';

const actionsOptions = [
  { value: tagsActionsMap.add, label: 'Add selected tags to current' },
  { value: tagsActionsMap.delete, label: 'Delete from all selected files' },
  { value: tagsActionsMap.replace, label: 'Replace all current tags to selected' }
];

export const ChangeFilesTagsModal = ({
  payload: { filesIDs },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const dispatch = useDispatch();
  const [ myTags, setMyTags ] = useState([]);
  const [ globalTags, setGlobalTags ] = useState([]);
  const [ folder, setFolder ] = useState(null);

  const handleTagsChange = (setTags) => (tags) => {
    setTags(tags || []);
  };

  const changeTags = ({ actionType }) => {
    const tags = myTags.concat(globalTags, folder || []);

    filesApi.changeTags({ files: filesIDs, tags: tags.map(({ id }) => id), actionType }).then(() => {
      dispatch(setFilesLastGlobalAction({
        type: filesActionTypes.CHANGE_FILES_TAGS,
        payload: { filesIDs, tags, actionType }
      }));

      handleModalResolve();
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          myTags: null,
          globalTags: null,
          folder: null,
          actionType: actionsOptions[0]
        }}
        onSubmit={changeTags}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject} icon={<SvgIcon><LabelSvg /></SvgIcon>}>
              Change tags
            </ModalHeader>

            <ModalBody>
              <Select
                formattedValue
                name="actionType"
                label="Select action type"
                placeholder="Select tags..."
                options={actionsOptions}
                TextFieldProps={{ margin: 'dense' }}
              />

              <TagsSelect
                multiple
                name="myTags"
                label="Select my tags"
                placeholder="Select my tags..."
                margin="dense"
                value={myTags}
                params={{ is_system: 0 }}
                onChange={handleTagsChange(setMyTags)}
              />

              <TagsSelect
                multiple
                name="globalTags"
                label="Select global tags"
                placeholder="Select global tags..."
                margin="dense"
                value={globalTags}
                params={{ is_system: 1 }}
                onChange={handleTagsChange(setGlobalTags)}
              />

              <TagsSelect
                name="folder"
                label="Select folder"
                placeholder="Select folder..."
                margin="dense"
                value={folder}
                params={{ is_folder: 1 }}
                getOptionLabel={getFolderParentName}
                onChange={setFolder}
              />
            </ModalBody>

            <ModalFooter>
              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || (!values.globalTags && !values.myTags && !values.folder)}
                  >
                    Change
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
