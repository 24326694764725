import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CasesContext } from '../../../app/Dashboard/CasesPage/Cases/CasesContext';
import { CustomScrollbars } from '../../CustomScrollbars';
import { InfiniteListLoader } from '../../InfiniteLoader';
import { Loader } from '../../Loader';
import { Header } from './Header';
import { Row } from './Row';

export const Cases = () => {
  const {
    cases,
    isFetching,
    isFetched,
    loadNextPage,
    resetCases,
    filter: { per_page, last_page, page }
  } = useContext(CasesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetCases();
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Loader p={3} loading={!isFetched} render={
        () => (!cases.length ? (
          <Box p={3}>
            <Typography align="center">No cases found</Typography>
          </Box>
        ) : (
          <>
            <Header />

            <Box flexGrow={1} overflow="hidden">
              <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
                <MuiList disablePadding>
                  <InfiniteListLoader
                    items={cases}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    pagination={{ per_page, last_page, page }}
                    RowComponent={Row}
                    onNeedNextPage={loadNextPage}
                    scrollElementRef={scrollElementRef}
                  />
                </MuiList>
              </CustomScrollbars>
            </Box>
          </>
        ))}
      />
    </Box>
  );
};
