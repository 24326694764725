import axios from 'axios';
import { getAddressComponents } from '../components/FormField/AddressAutocomplete/transformPlaceDetailsToAddress';

export const getGeolocation = () => {
  return new Promise((resolve) => {
    if (!navigator.geolocation) {
      resolve({
        status: 'error',
        message: 'Geolocation not supported',
        geolocation: {}
      });

      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const location = {
          latitude: '' + position.coords.latitude,
          longitude: '' + position.coords.longitude,
          accuracy: position.coords.accuracy
        };

        axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
          params: {
            key: import.meta.env.PUBLIC_GOOGLE_API_KEY,
            latlng: `${position.coords.latitude},${position.coords.longitude}`,
            sensor: true
          }
        }).then(({ data }) => {
          if (data.status === 'OK') {
            const addressComponents = getAddressComponents(data.results[0].address_components);

            resolve({
              status: 'success',
              geolocation: {
                location,
                full_address: data.results[0].formatted_address,
                address: {
                  city: addressComponents.city || '',
                  state: addressComponents.state || '',
                  street:addressComponents.street || '',
                  zip: addressComponents.zip || ''
                }
              }
            });
          } else {
            resolve({
              status: 'success',
              geolocation: {
                location
              }
            });
          }
        }).catch(() => {
          resolve({
            status: 'success',
            geolocation: {
              location
            }
          });
        });
      },
      (error) => {
        resolve({
          status: 'error',
          message: `Geolocation unavailable: ${error.message}`,
          geolocation: {}
        });
      }
    );
  });
};
