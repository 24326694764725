import { useContext } from 'react';
import { useModal as useModalHook } from 'react-modal-hook';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { useModal } from '../../../../components/ModalsProvider';
import {
  downloadFile as download,
  getFileNameWithoutExtension
} from '../../../../helpers/files';
import { FilePreviewModal } from '../../files-common';
import { menuOptionsTypesMap } from '../../files-common/menuOptionsTypes';
import { EditTranscribeModal } from './EditTranscribeModal';
import { TranscribesContext } from './TranscribesProvider';

const getFileMenuOptions = (file) => [
  {
    type: menuOptionsTypesMap.menuItem,
    label: 'Download file',
    Icon: DownloadIcon,
    onClick: () => {
      download({ src: file.url, name: file.original_filename });
    }
  }
];

export const useTranscribe = (transcribe = {}) => {
  const { openModal } = useModal();
  const { updateTranscribe } = useContext(TranscribesContext);
  const text = transcribe.formatted_text || transcribe.text;
  const [ openAudioFilePreview, closeAudioFilePreview ] = useModalHook(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeAudioFilePreview
      }}
      payload={{
        disableFetch: true,
        file: {
          id: {},
          original_filename: transcribe.filename,
          url: transcribe.audio_url,
          aggregate_type: 'audio'
        },
        singleView: true
      }}
      getFileMenuOptions={getFileMenuOptions}
    />
  ), [ transcribe.audio_url ]);
  const [ openTextFilePreview, closeTextFilePreview ] = useModalHook(({ in: open, onExited }) => {
    const blob = new Blob([ text ], {
      type : 'text/plain;charset=utf-8'
    });

    return (
      <FilePreviewModal
        DialogProps={{
          open,
          onExited,
          onCancel: closeTextFilePreview
        }}
        payload={{
          disableFetch: true,
          file: {
            id: {},
            original_filename: getFileNameWithoutExtension(transcribe.filename) + '.md',
            extension: 'md',
            url: URL.createObjectURL(blob),
            aggregate_type: 'text'
          },
          singleView: true
        }}
        getFileMenuOptions={getFileMenuOptions}
      />
    );
  }, [ transcribe.text ]);

  const editTranscribe = () => {
    openModal(EditTranscribeModal, {
      payload: {
        transcribe,
        markdown: text
      },
      onModalResolved: (transcribe) => {
        updateTranscribe(transcribe);
      }
    });
  };

  return {
    text,
    openTextFilePreview,
    openAudioFilePreview,
    editTranscribe
  };
};
