import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { ViewSupportTicketModal } from '../../../../../components/support/ViewSupportTicketModal';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import * as ticketsApi from '../../../../../api/support';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { EditSupportTicketModal } from '../../../../../components/support/EditSupportTicketModal';
import { SupportTicketsContext } from '../../../../../components/support/SupportTicketsProvider';
import { useModal } from '../../../../../components/ModalsProvider';

export const Menu = ({ supportTicket, onClose = () => {} }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { updateSupportTicket, deleteSupportTicket } = useContext(SupportTicketsContext);

  const editSupportTicket = () => {
    openModal(EditSupportTicketModal, {
      payload: {
        initialValues: supportTicket
      },

      onModalResolved: (data) => {
        updateSupportTicket(data);
      }
    });
  };

  const handleSupportTicketDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        ticketsApi.deleteSupportTicket(supportTicket.id).then(() => {
          deleteSupportTicket(supportTicket.id);
          enqueueSnackbar('Ticket successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Ticket not deleted', { variant: 'error' });
        });
      }
    });
  };

  const openPreviewModal = () => {
    openModal(ViewSupportTicketModal, {
      payload: {
        ticket: supportTicket
      }
    });
  };

  return (
    <List onClick={onClose}>
      <MenuItem onClick={openPreviewModal}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {hasRole(rolesMap.admin, rolesMap.supervisor) &&
        <>
          <MenuItem onClick={editSupportTicket}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit" />
          </MenuItem>

          {!hasRole(rolesMap.patient) &&
            <MenuItem onClick={handleSupportTicketDelete}>
              <ListItemIcon>
                <DeleteIcon color="error" fontSize="inherit"/>
              </ListItemIcon>

              <ListItemText primary="Delete" />
            </MenuItem>
          }
        </>
      }
    </List>
  );
};
