import { Autocomplete } from '../FormField';
import { categoriesOptions } from './categoriesOptions';

const getOptionLabel = (option) => {
  return option?.label || categoriesOptions.find(({ value }) => option === value)?.label;
};

export const SupportTicketCategoriesSelect = (props) => {
  return (
    <Autocomplete
      name="category"
      label="Category"
      placeholder="Select category..."
      options={categoriesOptions}
      getOptionLabel={getOptionLabel}

      {...props}
    />
  );
};
