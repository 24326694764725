import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export const AddOption = ({ option }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" mr={1}>
        <AddIcon color="primary" />
      </Box>

      {option?.inputValue ? `Add "${option?.inputValue}"` : 'Add email'}
    </Box>
  );
};
