import { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteGridLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { ScrollbarsCustom } from '../../../../../components/ScrollbarsCustom';
import { SupportTicketsContext } from '../../../../../components/support/SupportTicketsProvider';
import { Card } from './Card';

export const Grid = ({ autoHeight, hiddenColumns, Cell = Card, CellProps = {}, ...props }) => {
  const {
    isFetching,
    isFetched,
    supportTickets,
    meta,
    loadNextPage
  } = useContext(SupportTicketsContext);
  const scrollElementRef = useRef();

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} autoHeight={autoHeight}>
      <Box p={2}>
        <Loader loading={!isFetched} render={
          () => !supportTickets.length ? (
            <Typography align="center">No tickets found</Typography>
          ) : (
            <MuiList disablePadding>
              <InfiniteGridLoader
                autoSize
                cellWidth={380}
                items={supportTickets}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                CellComponent={Cell}
                CellProps={{ hiddenColumns, ...CellProps }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
