import cn from 'classnames';
import { Box, Grid, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import UserIcon from '@material-ui/icons/PermIdentity';
import { Label } from '../../../../app/Dashboard/TasksPage/MainWindow/Label';
import { amFormatDuration } from '../../../../helpers/dates';
import { CurrencyFormat } from '../../../CurrencyFormat';
import TrackerPlusSvg from '../../../icons/chronometer.svg?react';
import { CardItem, CardHeader } from '../../../Cards';
import { CompanyLink } from '../../../CompanyLink';
import { Tooltip } from '../../../Tooltip';
import { UserLink } from '../../../UserLink';
import { Date } from '../../../Date';
import { getProps } from '../helper';
import { styles } from '../Row/styles';
import { Menu } from '../Menu';
import {
  trackModelsColorsMap,
  trackModelsMap
} from '../trackModelsMap';

const useStyles = makeStyles(styles);

export const Card = ({ item: track = {}, disableActions = false, caseItem }) => {
  const classes = useStyles();
  const nameProps = getProps(track, classes);
  const duration = track.finished_at ? amFormatDuration(track.finished_at - track.started_at) : '-';
  const total = track.billing_total || track.total;

  return (
    <CardItem disableHover py={1} px={2}>
      <CardHeader
        pb={2}
        disableCheckBox
        disableCardMenu={disableActions}
        menuComponent={Menu}
        menuComponentProps={{ track, disableActions, caseItem }}
      >
        <CompanyLink
          variant="h5"
          company={track?.user?.work?.company || track?.user?.profile?.work?.company}
        />
      </CardHeader>

      <Grid container spacing={2}>
        <Grid item sm={4} xs={5}>
          <Box display="flex" alignItems="center" justifyContent="space-around">
            {track.is_manual ?
              <UserIcon className={cn(classes.trackTypeIcon, classes.trackTypeIcon_user)} />
              :
              <SvgIcon className={cn(classes.trackTypeIcon, classes.trackTypeIcon_tracker)}>
                <TrackerPlusSvg />
              </SvgIcon>
            }

            <Label name={trackModelsMap[track.model]} color={trackModelsColorsMap[track.model]} />
          </Box>
        </Grid>

        <Grid item sm={8} xs={7}>
          <Box display="flex" flexDirection="column" minHeight={38}>
            <Typography noWrap {...nameProps}>
              {track?.time_trackingable?.name || 'No info'}
            </Typography>

            <Tooltip
              isExistTooltip={!!track.description || !!track.note}
              title={track.description || track.note}
            >
              <Typography noWrap color="textSecondary" variant="caption">
                {track.description}
              </Typography>
            </Tooltip>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            className={classes.cardBody}
            py={1}
            display="flex"
            alignItems="stretch"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column" justifyContent="space-around" px={1.75} width="40%">
              <Typography gutterBottom>Started at</Typography>
              <Typography>Finished at</Typography>
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="space-around" pr={1.5} width="60%">
              <Date
                noWrap
                variant="h5"
                gutterBottom
                disableIcon
                date={track.started_at}
                format="L LT"
              />

              <Date
                noWrap
                variant="h5"
                disableIcon
                date={track.finished_at}
                format="L LT"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} component={Box} pl={1.75} pt={2}>
        <Grid item xs={7}>
          <Box display="flex" flexDirection="column">
            <Typography>
              {duration}
            </Typography>

            <Typography variant="caption" color="textSecondary">Duration</Typography>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" flexDirection="column">
            <CurrencyFormat value={total} />

            <Typography variant="caption" color="textSecondary">Total</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} component={Box} pt={2} pb={1}>
        <Grid item xs={8}>
          <UserLink user={track.user} childrenProps={{ mt: -0.5 }}>
            <Typography noWrap variant="caption">
              {track.category?.name || ''}
            </Typography>
          </UserLink>
        </Grid>
      </Grid>
    </CardItem>
  );
};
