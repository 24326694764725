import { Icon } from '@mdi/react';
import {
  mdiCogs,
  mdiCircleMedium,
  mdiCashMultiple,
  mdiFileDocumentEditOutline
} from '@mdi/js';
import { SvgIcon } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import InvoiceIcon from '../../../../../../components/icons/invoice-icon.svg?react';
import BillingSvg from '../../../../../../components/icons/billing-icon.svg?react';

export const billing = {
  name: 'Billing',
  routes: [
    {
      name: 'Dashboard',
      icon: DashboardIcon,
      path: '/billing/dashboard'
    },
    {
      name: 'Invoices',
      icon: (props) => (
        <SvgIcon {...props}>
          <InvoiceIcon />
        </SvgIcon>
      ),
      path: '/billing/invoices'
    },
    {
      name: 'Payments',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiCashMultiple} />
        </SvgIcon>
      ),
      path: '/billing/payments'
    },
    {
      name: 'Claims',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiFileDocumentEditOutline} />
        </SvgIcon>
      ),
      path: '/billing/claims'
    },
    {
      name: 'Settings',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiCogs} />
        </SvgIcon>
      ),
      path: '/billing/system-settings',
      routes: [
        {
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          name: 'Expense Preference',
          path: '/billing/system-settings/expense-preference'
        },
        {
          name: 'Time-track category',
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          path: '/billing/system-settings/timetrack-category'
        },
        {
          name: 'Financial Class/Med',
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          path: '/billing/system-settings/codes/financial-class'
        },
        {
          name: 'Payer/Med',
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          path: '/billing/system-settings/codes/payer'
        }
      ]
    }
  ],
  icon: (props) => (
    <SvgIcon {...props}>
      <BillingSvg />
    </SvgIcon>
  ),
  path: '/billing'
};
