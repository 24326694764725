import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { List, ListItemIcon, ListItemText, MenuItem, SvgIcon } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { mdiAccountConvert } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as usersApi from '../../../../../../../../api/users';
import * as casesApi from '../../../../../../../../api/cases';
import { caseUserRolesAvailableToReplace } from '../../../../../../../../dataMaps';
import { preventDefault } from '../../../../../../../../helpers/dom';
import { useChatActions } from '../../../../../../../../utils/useChatActions';
import { setCasesLastGlobalAction } from '../../../../../../../../store/globalActions';
import ChatSquaredSvg from '../../../../../../../../components/icons/chat-squared.svg?react';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { ReplaceCaseUserModal } from '../../../../ReplaceCaseUserModal';
import * as types from '../../../../CasesContext/types';

export const ContactsMenu = ({ caseUser, caseItem, onCaseItemUpdate }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { openChatWithUser } = useChatActions();
  const currentUser = useSelector(({ profile }) => profile.user);
  const canReplace = !!caseUserRolesAvailableToReplace.find((role) => (
    role.value === caseUser.case_role
  ));

  const onUpdate = () => {
    if (currentUser?.id === caseUser?.user?.id) {
      history.push('/profile/edit');
    } else {
      history.push(`/members/users/${caseUser?.user?.id}/edit`);
    }
  };

  const onDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        casesApi.deleteUserFromCase(caseUser.id).then(() => {
          const newCase = {
            ...caseItem,
            case_users: caseItem.case_users.filter(({ id }) => id !== caseUser.id)
          };

          onCaseItemUpdate(newCase);
          enqueueSnackbar('User successfully deleted from case', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('User is not deleted!', { variant: 'error' });
        });
      }
    });
  };

  const updateCases = (data) => {
    usersApi.fetchUser(data.to_user_id).then((updatedUser) => {
      caseItem.case_users.map((caseUserItem) => {
        if (caseUserItem.user.id === data.from_user_id) {
          caseUserItem.user = updatedUser;

          return caseUserItem;
        } else {
          return caseUserItem;
        }
      });

      dispatch(setCasesLastGlobalAction({ type: types.UPDATE_CASE_IN_LIST, payload: caseItem }));
    });
  };

  const onReplaceUser = () => {
    openModal(ReplaceCaseUserModal, {
      payload: {
        selectedCases: [ caseItem ],
        fromUser: caseUser
      },
      onModalResolved: (data) => {
        updateCases(data);
      }
    });
  };

  const startChatWithUser = () => {
    openChatWithUser(caseUser?.user?.id);
  };

  return (
    <List>
      <MenuItem onClick={onUpdate}>
        <ListItemIcon>
          <EditIcon color="primary" />
        </ListItemIcon>

        <ListItemText primary="Edit user profile" />
      </MenuItem>

      <MenuItem button onClick={startChatWithUser}>
        <ListItemIcon>
          <SvgIcon><ChatSquaredSvg /></SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Send Message" />
      </MenuItem>

      {canReplace &&
        <MenuItem onClick={onReplaceUser}>
          <ListItemIcon>
            <SvgIcon color="primary" fontSize="inherit"><Icon path={mdiAccountConvert} /></SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Replace case user" />
        </MenuItem>
      }

      <MenuItem onClick={preventDefault(onDelete)}>
        <ListItemIcon>
          <DeleteIcon color="error" />
        </ListItemIcon>

        <ListItemText primary="Remove" />
      </MenuItem>
    </List>
  );
};
