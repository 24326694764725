import io from 'socket.io-client';
import LaravelEcho from 'laravel-echo';

export const Echo = new LaravelEcho({
  broadcaster: 'socket.io',
  host: import.meta.env.PUBLIC_API_URL,
  client: io
});

export const loginSocket = (authToken) => {
  Echo.connector.options.auth.headers.authorization = `Bearer ${authToken}`;
};

export const logoutSocket = () => {
  Echo.connector.options.auth.headers.authorization = null;
};
