import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Attachments } from '../../../app/Dashboard/MedicalReports/ClinicalNotes/Attachments';
import { AttachmentsContainer } from '../../../app/Dashboard/TasksPage/AttachmentsContainer';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { OfficesLocationSelect, TextField } from '../../FormField';
import { hasRole } from '../../../utils/hasRole';
import { UsersSelect } from '../../users';
import { SupportTicketCategoriesSelect } from '../SupportTicketCategoriesSelect';
import { SupportTicketStatusesSelect } from '../SupportTicketStatusesSelect';

export const SupportTicketForm = ({ id, files }) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const filesInitialFilter = {
    owner_type: 'support_ticket',
    owner_id: id
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SupportTicketCategoriesSelect
          name="category"
          label="Category"
        />
      </Grid>

      {hasRole(rolesMap.admin) &&
        <>
          <Grid item xs={6}>
            <SupportTicketStatusesSelect
              disableClearable
              name="status"
              label="Status"
            />
          </Grid>

          <Grid item xs={6}>
            <UsersSelect
              name="assignee_id"
              label="Assignee"
              params={{ roles: [ rolesMap.admin, rolesMap.supervisor ] }}
            />
          </Grid>
        </>
      }

      <Grid item xs={12}>
        <OfficesLocationSelect
          name="office_id"
          label="Office"
          params={{ company_id: currentUser?.work?.company_id }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          multiline
          rows={6}
          variant="filled"
          name="description"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item xs={12}>
        {id ? (
          <AttachmentsContainer
            disableFetching
            disableVirtual
            ownerType="support_ticket"
            ownerID={id}
            files={files}
            filter={filesInitialFilter}
          />
        ) : (
          <Attachments />
        )}
      </Grid>
    </Grid>
  );
};
