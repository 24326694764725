import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, useTheme, useMediaQuery } from '@material-ui/core';
import * as supportApi from '../../../api/support';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../Modal';
import { Loader } from '../../Loader';
import { SupportTicketForm, validationSchema } from '../SupportTicketForm';

export const EditSupportTicketModal = ({
  DialogProps,
  payload: {
    initialValues
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const updateTicket = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return supportApi.updateSupportTicket(values.id, values).then((ticket) => {
      enqueueSnackbar(`Ticket "#${ticket.id}" successfully updated`, { variant: 'success' });
      handleModalResolve(ticket);
    }).catch(({ data: { errors } = {} }) => {
      setErrors(errors);

      enqueueSnackbar('Ticket not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,

        office_id: initialValues.office,
        assignee_id: initialValues.assignee
      }}
      validationSchema={validationSchema}
      onSubmit={updateTicket}
    >
      {({ isSubmitting, values, handleSubmit }) => (
        <Dialog disableEnforceFocus fullScreen={isMobile} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Edit ticket
            </ModalHeader>

            <ModalBody>
              <SupportTicketForm {...values} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
