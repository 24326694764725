import { Formik } from 'formik';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as casesApi from '../../../../../../../../api/cases';
import EditIcon from '../../../../../../../../components/icons/square-edit-icone.svg?react';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../../../components/Modal';
import { Loader } from '../../../../../../../../components/Loader';
import { validationSchema } from './validationSchema';
import { Body } from './Body';

export const EditCaseClosureContent = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    caseInfo = {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateCase = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.updateCase({ id: caseInfo.id, ...values }).then((data) => {
      enqueueSnackbar('Case successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        physical_file_scanned_at: caseInfo.physical_file_scanned_at || '',
        subbed_out: caseInfo.subbed_out ? 1 : 0,
        subbed_out_at: caseInfo.subbed_out_at || '',
        closed_by: caseInfo.closed_by,
        substituted_out: null,
        substituted_out_company_id: caseInfo?.substituted_out,
        substituted_out_company_phone: caseInfo?.substituted_out?.phone,
        substituted_out_company_address: caseInfo?.substituted_out?.full_address
      }}
      validationSchema={validationSchema}
      onSubmit={handleUpdateCase}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog  maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Edit Case closure information
            </ModalHeader>

            <ModalBody>
              <Body caseItem={caseInfo} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
