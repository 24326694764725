import { useModal as useModalHook } from 'react-modal-hook';
import { useFormikContext } from 'formik';
import filesize from 'filesize';
import {
  makeStyles,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Link,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { getAggregateTypeFromMIME } from '../../../../../helpers/files';
import { IconButton } from '../../../../../components/IconButton';
import { getFileMenuOptions } from '../../../BillingPage/Invoices/Expenses/List/getFileMenuOptions';
import { FilePreviewModal, FileTypeIcon } from '../../../files-common';

const useStyles = makeStyles({
  secondaryAction: {
    right: 0
  }
});

export const Row = ({ file, index, onFileDelete = () => {} }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { openModal } = useModal();
  const [ openFilesPreview, closeFilesPreview ] = useModalHook(({ in: open, onExited }) => (
    <FilePreviewModal
      previewMenuOptions={getFileMenuOptions(handleDeleteFile, true)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file: values.files?.[index],
        files: values.files
      }}
    />
  ), [ values.files?.length ]);

  const handleDeleteFile = (file) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        onFileDelete(file);
      }
    });
  };

  return (
    <ListItem disableGutters>
      <FileTypeIcon
        file={{
          aggregate_type: getAggregateTypeFromMIME(file.type),

          ...file
        }}
      />

      <ListItemText primaryTypographyProps={{ noWrap: true }}>
        <Typography
          noWrap
          component={file?.id ? Link : Typography}
          onClick={file?.id ? openFilesPreview : undefined}
        >
          {file.original_filename || file.name}
        </Typography>
      </ListItemText>

      <ListItemText
        primary={filesize(file.size)}
        primaryTypographyProps={{ align: 'right' }}
      />

      <ListItemSecondaryAction className={classes.secondaryAction}>
        <IconButton color="error" onClick={handleDeleteFile(file)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
