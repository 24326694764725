import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import * as medicalProvidersApi from '../../../../api/profile';
import * as apptBooksApi from '../../../../api/codes/appointments/appointment-books';
import { generateAddress } from '../../../../utils/generateAddress';
import { CreateApptBookModal } from '../../../appointments/appointment-books/CreateApptBookModal';
import { useModal } from '../../../ModalsProvider';
import { Autocomplete } from '../../Autocomplete';

const fetchApptBooksList = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return apptBooksApi.fetchApptBooksList({
    params: {
      search,
      page: page + 1,
      is_active: 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const fetchGuestApptBooksList = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return medicalProvidersApi.fetchGuestApptBooksList({
    params: {
      search,
      page: page + 1,
      is_active: 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const fetchApptBooks = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return apptBooksApi.fetchAppointmentBooks({
    params: {
      search,
      page: page + 1,
      is_active: 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => {
  return !option?.isCreatableOption ? (
    <Typography component="span">
      {option.appointment_book_name}

      <Typography
        component="span"
        variant="body2"
        color="textSecondary"
        title={option?.office?.full_address || generateAddress(option?.office)}
      >
        <Box component="span" ml={0.5}>
          <LocationOnIcon color="inherit" fontSize="small" />
        </Box>

        {option?.office?.full_address || generateAddress(option?.office)}
      </Typography>
    </Typography>
  ) : (
    <>
      <Box display="flex" mr={1}>
        <AddIcon color="primary" />
      </Box>

      Add appt. book
    </>
  );
};

export const ApptBooksSelect = ({ allBooks, multiple = false, params = {}, creatablePayload, ...props }) => {
  const { openModal } = useModal();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);

  const addUser = () => {
    return new Promise((resolve, reject) => {
      openModal(CreateApptBookModal, {
        DialogProps: {
          disableRestoreFocus: true
        },
        payload: creatablePayload,
        onModalResolved: resolve,
        onModalRejected: reject
      });
    });
  };

  return (
    <Autocomplete
      isAsync
      multiple={multiple}
      label="Appointment book"
      placeholder="Search appt. book..."
      onNeedFetch={
        isAuthenticated
          ? allBooks ? fetchApptBooksList(params) : fetchApptBooks(params)
          : fetchGuestApptBooksList(params)
      }
      renderOption={renderOption}
      getOptionLabel={(option) => option?.appointment_book_name}
      getOptionValue={(appointmentBook) => appointmentBook?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}
      onCreate={addUser}

      {...props}
    />
  );
};
