export const transformRelationsToFilter = (relationsForFilter) => {
  return {
    ...relationsForFilter,

    status: relationsForFilter?.status?.value,
    office_id: relationsForFilter?.office_id?.id,
    assignee_id: relationsForFilter?.assignee_id?.id,
    creator_id: relationsForFilter?.creator_id?.id,
    category: relationsForFilter?.category?.value
  };
};
