import { SvgIcon } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import WorkIcon from '@material-ui/icons/WorkOutlineOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendarOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import {
  mdiCalendarClockOutline,
  mdiFileDocumentEditOutline
} from '@mdi/js';
import { Icon } from '@mdi/react';
// TODO: hide for future settings
// import TransferSvg from '../../../../../../components/icons/transfer.svg?react';
import TimerSvg from '../../../../../../components/icons/chronometer.svg?react';
import ActivitySvg from '../../../../../../components/icons/activity.svg?react';
import BillingSvg from '../../../../../../components/icons/billing-icon.svg?react';
import LitigationSvg from '../../../../../../components/icons/litigation.svg?react';
import NegotiationsSvg from '../../../../../../components/icons/negotiations.svg?react';

export const casesMenuList = [
  {
    name: 'Cases',
    icon: WorkIcon,
    path: '/cases'
  }
];

export const caseMenuList = [
  {
    exact: true,
    name: 'General Case info',
    icon: DashboardIcon,
    path: '/cases/:caseID'
  },
  {
    name: 'Case Notes',
    icon: QuestionAnswerOutlinedIcon,
    path: '/cases/:caseID/comments'
  },
  {
    name: 'Litigation',
    icon: (props) => <SvgIcon {...props}><LitigationSvg /></SvgIcon>,
    path: '/cases/:caseID/litigation'
  },
  {
    name: 'Negotiations',
    icon: (props) => <SvgIcon {...props}><NegotiationsSvg /></SvgIcon>,
    path: '/cases/:caseID/negotiation'
  },
  {
    name: 'Files',
    icon: FolderIcon,
    path: '/cases/:caseID/files'
  },
  {
    name: 'Schedule',
    icon: DateRangeOutlinedIcon,
    path: '/cases/:caseID/schedule'
  },
  {
    name: 'Reminders',
    icon: NotificationsActiveOutlinedIcon,
    path: '/cases/:caseID/reminders'
  },
  {
    name: 'Appointments',
    icon: InsertInvitationOutlinedIcon,
    path: '/cases/:caseID/appointments'
  },
  {
    name: 'Call Reports',
    icon: PhoneOutlinedIcon,
    path: '/cases/:caseID/call-reports'
  },
  {
    name: 'Emails',
    icon: EmailIcon,
    path: '/cases/:caseID/emails'
  },
  {
    name: 'Сontacts',
    icon: PermContactCalendarIcon,
    path: '/cases/:caseID/contacts'
  },
  {
    name: 'Medical Claims',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>,
    path: '/cases/:caseID/claims'
  },
  {
    name: 'Discovery',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiCalendarClockOutline} /></SvgIcon>,
    path: '/cases/:caseID/discovery'
  },
  {
    name: 'Billing',
    icon: (props) => <SvgIcon {...props}><BillingSvg /></SvgIcon>,
    path: '/cases/:caseID/billing'
  },
  {
    name: 'Activity',
    icon: (props) => <SvgIcon {...props}><ActivitySvg /></SvgIcon>,
    path: '/cases/:caseID/activity'
  },
  {
    name: 'Tasks',
    icon: PlaylistAddCheckIcon,
    path: '/cases/:caseID/tasks'
  },
  {
    name: 'Time Tracking',
    icon: (props) => <SvgIcon {...props}><TimerSvg /></SvgIcon>,
    path: '/cases/:caseID/time-tracking'
  }
  // TODO: hide for future settings
  // {
  //   name: 'Case Transfer Information',
  //   icon: (props) => <SvgIcon {...props}><TransferSvg /></SvgIcon>,
  //   path: '/cases/:caseID/case-transfer'
  // }
];
