import { Icon } from '@mdi/react';
import { mdiUmbrellaOutline, mdiFuel } from '@mdi/js';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SvgIcon } from '@material-ui/core';
import ParkingIcon from '../icons/parking-icon.svg?react';
import MoneyIcon from '../icons/money-icon.svg?react';
import { palette } from '../../themes/palette';

export const expensesTypesMap = {
  approved: 'Approved',
  submit: 'Submit'
};

export const expensesStatusIDsMap = {
  approved: 3
};

// TODO: Add IDs to map after protect in on backend
export const expensesStatusesMap = {
  'all': {
    name: 'All expenses',
    color: palette().secondary.dark
  },
  'pending': {
    name: 'Pending',
    color: palette().amberyellow
  },
  'approved': {
    name: 'Approved',
    color: palette().primary.main
  },
  'reimbursement': {
    name: 'Reimbursed',
    color: palette().secondary.light
  },
  'submit': {
    name: 'Submit',
    color: palette().lightgreen
  },
  'unproved': {
    name: 'Unproved',
    color: palette().slategray
  }
};

export const expensesCategoryMap = {
  'lodging': {
    name: 'Lodging',
    color: palette().lightgreen,
    icon: <LocalHotelIcon />
  },
  'transportation/travel': {
    name: 'Transportation/Travel',
    color: palette().darkgreen,
    icon:  <LocalAirportIcon />
  },
  'meals': {
    name: 'Meals',
    color: palette().deeporange,
    icon:  <RestaurantIcon />
  },
  'phone': {
    name: 'Phone',
    color: palette().amberyellow,
    icon: <LocalPhoneIcon />
  },
  'car pmt': {
    name: 'Car pmt',
    color: palette().secondary.light,
    icon: <DirectionsCarIcon />
  },
  'insurance': {
    name: 'Insurance',
    color: palette().lightPurple,
    icon: <SvgIcon><Icon path={mdiUmbrellaOutline} /></SvgIcon>
  },
  'fuel': {
    name: 'Fuel',
    color: palette().secondary.main,
    icon: <SvgIcon><Icon path={mdiFuel} /></SvgIcon>
  },
  'entertainment': {
    name: 'Entertainment',
    color: palette().pink,
    icon: <LocalActivityIcon/>
  },
  'parking': {
    name: 'Parking',
    color: palette().purple,
    icon: <SvgIcon><ParkingIcon/></SvgIcon>
  },
  'copies': {
    name: 'Copies',
    color: palette().secondary.dark,
    icon: <FileCopyIcon/>
  },
  'miscell': {
    name: 'Miscell',
    color: palette().slategray,
    icon: <SvgIcon><MoneyIcon/></SvgIcon>
  }
};
