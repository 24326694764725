export const styles = ({
  spacing,
  palette: { grey, error },
  typography: { pxToRem },
  breakpoints
}) => ({
  root: {
    display: 'flex',
    marginBottom: spacing(),

    [breakpoints.down('md')]: {
      padding: spacing(0, 2)
    },

    [breakpoints.down('sm')]: {
      padding: spacing()
    }
  },

  main: {
    flexGrow: 1,
    marginLeft: spacing()
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  contentHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(0.5),
    marginBottom: spacing()
  },

  contentDate: {
    display: 'flex',
    color: grey[400],
    fontSize: pxToRem(12),
    paddingLeft: spacing()
  },

  contentText: {
    color: grey[500],
    wordWrap: 'break-word',
    maxWidth: '98%',
    overflowWrap: 'break-word'
  },

  contentTextNotification: {
    color: 'inherit',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflow: 'hidden'
  },

  rowAction: {
    display: 'flex',
    alignItems: 'center'
  },

  restoreIcon: {
    fontSize: pxToRem(12),
    marginRight: spacing(1),
    textTransform: 'capitalize'
  },

  backupIcon: {
    marginLeft: spacing(),
    fontSize: pxToRem(18)
  },

  trash: {
    display: 'flex',
    alignItems: 'center',
    color: error.main,
    marginBottom: spacing(0.5)
  },

  trashIcon: {
    fontSize: pxToRem(16),
    marginRight: spacing(0.5)
  },

  fetchSubButton: {
    marginLeft: spacing(-1),
    textTransform: 'capitalize'
  }
});
