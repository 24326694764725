import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme, Box, Tooltip } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Badge } from '../../../../../../../components/Badge';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { transformToOption } from '../../../../../../../helpers/fetchDataForAsyncSelect';
import { useResizeObserver } from '../../../../../../../helpers/hooks';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { hasRole } from '../../../../../../../utils/hasRole';
import { SvgIcon } from '../../../../../../../components/SvgIcon';
import ActivityIcon from '../../../../../../../components/icons/activity.svg?react';
import { FiltersBarModal } from '../../../../../../../components/FiltersBarModal/FiltersBarModal';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { ClaimsContext, ClaimsProvider } from '../../../../../../../components/claims';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { IconButton } from '../../../../../../../components/IconButton';
import { Grid } from '../../../../../BillingPage/ClaimsPage/Claims/Grid';
import { List } from '../../../../../BillingPage/ClaimsPage/Claims/List';
import { claimsHiddenColumns, claimsMobileHiddenColumns } from '../../claimsHiddenColumns';
import { initialMedicalClaimFilters } from '../Billing/MainContent/Filters';
import { getFilterFields, getFiltersValues } from './getFilterFields';

export const MedicalClaims = ({
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},
  widgetFilters = {},
  updateFilters = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const theme = useTheme();
  const { openModal } = useModal();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = width <= theme.breakpoints.values.tablet;
  const [ filters, setFilters ] = useState({
    ...initialMedicalClaimFilters,
    ...widgetFilters?.medicalClaims
  });
  const initialFilter = {
    patients: hasRole(rolesMap.patient) ? [ currentUser.id ] : null,
    case_id: caseItem?.id || null,
    cases: [ caseItem.id ],
    ...getFiltersValues(widgetFilters?.medicalClaims)
  };
  const hiddenColumns = width < 1140 ? claimsHiddenColumns : claimsMobileHiddenColumns;

  const onFiltersUpdate = (values) => {
    setFilters(values);
    updateFilters({ medicalClaims: values });
  };

  const handleResetFilter = (applyFilter) => () => {
    applyFilter(initialMedicalClaimFilters);
    onFiltersUpdate(initialMedicalClaimFilters);
  };

  const handleFiltersChange = (filter, applyFilter) => () => {
    openModal(FiltersBarModal, {
      payload: {
        title: 'Claims Filters',
        initialValues: {
          ...filters,

          statuses: filters?.statuses?.map(transformToOption)
        },
        fields: getFilterFields({ applyFilter }),
        setFilters: onFiltersUpdate
      },
      onModalResolved: (values) => {
        const filterValues = getFiltersValues(values);

        applyFilter(filterValues);
      }
    });
  };

  return (
    <ClaimsProvider filter={initialFilter}>
      <ClaimsContext.Consumer>
        {({ filter, applyFilter }) => (
          <ContentCard
            fullHeight={!props.autoHeightMax}
            isMovable={!props.autoHeightMax}
            disableContentPaddings
            title="Medical Claims"
            leftActions={[
              <SvgIcon color="inherit" fontSize="small">
                <ActivityIcon/>
              </SvgIcon>
            ]}
            rightActions={[
              <WidgetsActionButtons
                isMinimizable
                type={props?.item?.i}
                page={page}
                isDisabledOpen={isDisabledOpen}
                onMinimize={onMinimize}
                onMaximized={onMaximized}
                onClose={onClose}
                onOpen={onOpen}
                onPutItem={onPutItem}
              />
            ]}
            endIcon={(
              <Box display="flex" alignItems="center">
                <Tooltip title="Set Filter">
                  <IconButton
                    color="primary"
                    onClick={handleFiltersChange(filter, applyFilter)}
                  >
                    <Badge
                      invisible={isEmptyDeep(filters)}
                      badgeContent={
                        <Tooltip title="Filter is used">
                          <SvgIcon fontSize="small" color="success">
                            <CheckCircleIcon/>
                          </SvgIcon>
                        </Tooltip>
                      }
                    >
                      <SvgIcon fontSize="small">
                        <Icon path={mdiFilter}/>
                      </SvgIcon>
                    </Badge>
                  </IconButton>
                </Tooltip>

                <Tooltip title="Reset Filter">
                  <IconButton
                    color="primary"
                    onClick={handleResetFilter(applyFilter)}
                  >
                    <BackupIcon fontSize="small"/>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {...props}
          >
            <Box
              ref={rootRef}
              display="flex"
              flexDirection="column"
              height={isMobile ? height : '100%'}
              overflow="hidden"
            >
              {isMobile
                ? <Grid view="modal" />
                : <List view="modal" hiddenColumns={hiddenColumns} />}
            </Box>
          </ContentCard>
        )}
      </ClaimsContext.Consumer>
    </ClaimsProvider>
  );
};
