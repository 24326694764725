import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  Dialog,
  Grid,
  Typography,
  IconButton,
  SvgIcon,
  Box,
  useMediaQuery
} from '@material-ui/core';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarmsOutlined';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import DomainIcon from '@material-ui/icons/Domain';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { ColorPreview } from '../../ColorPreview';
import { Loader } from '../../Loader';
import { Tooltip } from '../../Tooltip';
import { setTracksLastGlobalAction } from '../../../store/globalActions';
import * as eventsApi from '../../../api/schedule-events';
import { AddressLink } from '../../AddressLink';
import TimerSvg from '../../icons/chronometer.svg?react';
import { LogTimeModal } from '../../LogTime';
import * as types from '../../TimeTracking/timeTrackerHelpers/types';
import { UserLink } from '../../UserLink';
import { useModal } from '../../ModalsProvider';
import { ConfirmationModal } from '../../ConfirmationModal';
import { ModalHeader, ModalHeaderActions, ModalBody, ModalContainer } from '../../Modal';
import { ModelLink } from '../../../app/Dashboard/files-common';
import { ChangeEventOrganizerModal } from '../ChangeEventOrganizerModal';
import { DeleteScheduleEventModal } from '../DeleteScheduleEventModal';
import { EditScheduleEventModal } from '../EditScheduleEventModal';
import { ParticipationBadge } from '../ParticipationBadge';
import { ConfirmationParticipation } from './ConfirmationParticipation/ConfirmationParticipation';
import { styles } from './styles';
import { TabsContent } from './TabsContent';

const useStyles = makeStyles(styles);

export const ViewScheduleEventModal = ({
  DialogProps,
  payload: {
    hideApplyFields = false,
    disableActions = false,
    eventId,
    event: eventPayload,
    onUpdate = () => {},
    onDelete = () => {}
  },
  handleModalReject
}) => {
  const [ event, setEvent ] = useState(eventPayload);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileSm));
  const currentUser = useSelector(({ profile }) => profile.user);
  const isCreator = currentUser.id === event?.creator?.id;

  const handleParticipationUpdate = (event) => {
    onUpdate(event);
    setEvent(event);
  };

  const changeOrganizer = () => {
    openModal(ChangeEventOrganizerModal, {
      payload: {
        eventId: event.id,
        initialValues: {
          owner_id: event.creator
        }
      },

      onModalResolved: (event) => {
        onUpdate(event);
        setEvent(event);
      }
    });
  };

  const editEvent = () => {
    openModal(EditScheduleEventModal, {
      payload: {
        hideApplyFields,
        event
      },

      onModalResolved: (event) => {
        onUpdate(event);
        setEvent(event);
      }
    });
  };

  const openLogTimeModal = () => {
    openModal(LogTimeModal, {
      payload: {
        event,
        initialValues: {
          time_track_model: 'schedule_event',
          time_track_model_id: event.id,
          started_at: event.started_at,
          finished_at: event.finished_at
        }
      },
      onModalResolved: (track) => {
        dispatch(setTracksLastGlobalAction({ type: types.ADD_TRACK, payload: track }));
      }
    });
  };

  const deleteEvent = () => {
    if (!event.is_recurring_dummy) {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          handleModalReject();

          eventsApi.deleteEvent(event.id).then(() => {
            onDelete(event);
            enqueueSnackbar(`Event "${event.title}" successfully deleted`, { variant: 'success' });
          });
        }
      });
    } else {
      openModal(DeleteScheduleEventModal, {
        payload: { event },
        onModalResolved: () => {
          onDelete(event);
          handleModalReject();
        }
      });
    }
  };

  useEffect(() => {
    if (!eventId) {
      return;
    }

    eventsApi.fetchEvent(eventId).then((event) => {
      setEvent(event);
    }).catch(() => {
      enqueueSnackbar('Something wrong with opening that event', { variant: 'error' });
      handleModalReject();
    });
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Event details

          {!disableActions && !!event && (
            <ModalHeaderActions disableGutters>
              {(isCreator || !hasRole(rolesMap.medic, rolesMap.doctor)) && (
                <Tooltip title="Add time track">
                  <IconButton onClick={openLogTimeModal}>
                    <SvgIcon><TimerSvg /></SvgIcon>
                  </IconButton>
                </Tooltip>
              )}

              {(isCreator || hasRole(rolesMap.admin, rolesMap.supervisor)) &&
                <Tooltip title="Change organizer">
                  <IconButton onClick={changeOrganizer}>
                    <SupervisorAccountOutlinedIcon />
                  </IconButton>
                </Tooltip>
              }

              {(isCreator || hasRole(rolesMap.admin, rolesMap.supervisor)) &&
                <Tooltip title="Edit event">
                  <IconButton onClick={editEvent}>
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              }

              {(isCreator || hasRole(rolesMap.admin, rolesMap.supervisor)) &&
                <Tooltip title="Delete event">
                  <IconButton onClick={deleteEvent}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              }
            </ModalHeaderActions>
          )}
        </ModalHeader>

        <ModalBody disableScrollbars>
          <Loader loading={!event} render={
            () => (
              <>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Title
                        </Typography>
                      </th>

                      <td>
                        <Typography variant="subtitle2">
                          {event.title}
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Description
                        </Typography>
                      </th>

                      <td>
                        <Typography
                          variant="subtitle2"
                          dangerouslySetInnerHTML={{ __html: event.description || '-' }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Type
                        </Typography>
                      </th>

                      <td>
                        {event.type ? (
                          <Grid container spacing={1} alignItems="center">
                            <Grid item component={Box} display="flex">
                              <ColorPreview color={event.type?.color} />
                            </Grid>

                            <Grid item>
                              <Typography variant="subtitle2">
                                {event.type?.name || '-'}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          <Typography variant="subtitle2">
                            -
                          </Typography>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Start date
                        </Typography>
                      </th>

                      <td>
                        <Grid container spacing={4} alignItems="center">
                          <Grid item>
                            <Grid container spacing={1}>
                              <Grid item>
                                <EventOutlinedIcon fontSize="small" />
                              </Grid>

                              <Grid item>
                                <Typography variant="subtitle2">
                                  {moment.unix(event.started_at).format('L')}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Grid container spacing={1}>
                              <Grid item>
                                <AccessAlarmsIcon fontSize="small" />
                              </Grid>

                              <Grid item>
                                <Typography variant="subtitle2">
                                  {moment.unix(event.started_at).format('LT')}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          End date
                        </Typography>
                      </th>

                      <td>
                        <Grid container spacing={4} alignItems="center">
                          <Grid item>
                            <Grid container spacing={1}>
                              <Grid item>
                                <EventOutlinedIcon fontSize="small" />
                              </Grid>

                              <Grid item>
                                <Typography variant="subtitle2">
                                  {moment.unix(event.finished_at).format('L')}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Grid container spacing={1}>
                              <Grid item>
                                <AccessAlarmsIcon fontSize="small" />
                              </Grid>

                              <Grid item>
                                <Typography variant="subtitle2">
                                  {moment.unix(event.finished_at).format('LT')}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Duration
                        </Typography>
                      </th>

                      <td>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <TimelapseIcon fontSize="small" />
                          </Grid>

                          <Grid item>
                            <Typography variant="subtitle2">
                              {moment.duration(event.finished_at - event.started_at, 'seconds').humanize()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </td>
                    </tr>

                    {!hideApplyFields &&
                      <tr>
                        <th className={classes.th}>
                          <Typography variant="body1" color="textSecondary">
                            Applied to
                          </Typography>
                        </th>

                        <td>
                          <Typography variant="subtitle2">
                            {!event.parent ? '-' : (
                              <ModelLink modelType={event.parent_type} model={event.parent}/>
                            )}
                          </Typography>
                        </td>
                      </tr>
                    }

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Organizer
                        </Typography>
                      </th>

                      <td>
                        <Grid container wrap="nowrap" spacing={1} alignItems="center">
                          <Grid item>
                            <ParticipationBadge user={event.creator}>
                              <UserLink disableName user={event.creator} />
                            </ParticipationBadge>
                          </Grid>

                          <Grid item>
                            <UserLink noAvatar user={event.creator} />
                          </Grid>
                        </Grid>
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Participants
                        </Typography>
                      </th>

                      <td>
                        <div className={classes.participants}>
                          {event?.users?.length ? event?.users?.map((user) => (
                            <div key={user.id} className={classes.participant}>
                              <ParticipationBadge user={user}>
                                <UserLink disableName user={user} />
                              </ParticipationBadge>
                            </div>
                          )) : (
                            <Typography variant="subtitle2">-</Typography>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Office
                        </Typography>
                      </th>

                      <td>
                        {event.office ?
                          <>
                            <Grid container spacing={1}>
                              <Grid item>
                                <DomainIcon fontSize="small" />
                              </Grid>

                              <Grid item>
                                <Typography variant="subtitle2">
                                  {event.office?.name}
                                </Typography>
                              </Grid>
                            </Grid>

                            {!!event.office?.full_address &&
                              <Grid container spacing={1}>
                                <Grid item>
                                  <LocationOnOutlinedIcon fontSize="small" />
                                </Grid>

                                <Grid item>
                                  <AddressLink address={event.office?.full_address} variant="subtitle2">
                                    {event.office?.full_address}
                                  </AddressLink>
                                </Grid>
                              </Grid>
                            }
                          </>
                          :
                          <Typography variant="subtitle2">
                            -
                          </Typography>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>

                {!disableActions && (
                  <Box py={3}>
                    <TabsContent event={event} />
                  </Box>
                )}
              </>
            )}
          />
        </ModalBody>

        {!disableActions && !!event &&
          <ConfirmationParticipation event={event} onChange={handleParticipationUpdate} />
        }
      </ModalContainer>
    </Dialog>
  );
};
