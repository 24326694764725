import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { PatientsSelect } from '../../../../../components/users/PatientsSelect';
import { toggleSidebar } from '../../../../../store/dashboard/layout';
import { updateUserStorage } from '../../../../../store/globalUser/operations';

const useStyles = makeStyles({
  input: {
    color: 'rgba(255, 255, 255, 0.8)'
  }
});

export const UserInfo = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const storageInfo = useSelector(({ globalUser }) => globalUser?.data);
  const [ user, setUser ] = useState(null);
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const handlePatientChange = (option) => {
    if (!option) {
      return;
    }

    const user = storageInfo?.users?.find(({ id }) => id === option?.id);

    if (!user) {
      dispatch(updateUserStorage({
        users: storageInfo?.users.concat(option)
      }));
    }

    if (option?.id) {
      history.push(`/patients/${option?.id}/medical-info/medical-forms`);
    }

    setUser(option);

    if (isMobile) {
      dispatch(toggleSidebar());
    }
  };

  useEffect(() => {
    const storageUser = storageInfo?.users?.find(({ id }) => id === user?.id);

    if (storageUser) {
      setUser(null);
    }
  }, [ user, storageInfo?.users ]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        user_id: user
      }}
      onSubmit={null}
    >
      {() => (
        <PatientsSelect
          label={false}
          name="user_id"
          placeholder="Select patient"
          margin="dense"
          hiddenOptions={storageInfo?.users}
          TextFieldProps={{
            InputProps: {
              classes: {
                root: classes.input
              }
            }
          }}
          onChange={handlePatientChange}
        />
      )}
    </Formik>
  );
};
