import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import * as authApi from '../../../../../api/auth';
import { Loader } from '../../../../../components/Loader';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { UsersSelect } from '../../../../../components/users';
import { loginSuccess } from '../../../../../store/auth/actions';
import { fetchProfile, setProfileData } from '../../../../../store/dashboard/profile';
import { fetchUserStorage } from '../../../../../store/globalUser/operations';
import { Yup } from '../../../../../utils/validation';

const initialValues = {
  userId: null
};

const validationSchema = Yup.object().shape({
  userId: Yup.mixed().nullable().required()
});

export const LoginAsModal = ({ handleModalReject, DialogProps, onClose }) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ userId }) => {
    return authApi.loginAs(userId).then(({ user, token }) => {
      return new Promise(() => {
        dispatch(loginSuccess({ token }));
        dispatch(setProfileData(user));
        dispatch(fetchUserStorage());
        dispatch(fetchProfile()).then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
      });
    });
  };

  return (
    <Dialog maxWidth="sm" {...DialogProps}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={onClose}>
              Select user to login
            </ModalHeader>

            <ModalBody>
              <Alert severity="info">
                When switching accounts, the current session will be ended. Use the login form to resume your session
              </Alert>

              <UsersSelect
                name="userId"
                label="Select user to login as"
                placeholder="Search user..."
                margin="normal"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Login
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
