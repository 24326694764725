import { Box, Typography } from '@material-ui/core';
import { UserLink } from '../../../UserLink';

export const Label = ({ user: userOption }) => {
  const isUser = typeof userOption !== 'string';

  return (
    <Box width="100%" py={0}>
      <UserLink
        allowPropagation
        size="sm"
        variant="h5"
        disableLink
        disablePreview
        disableName={!isUser || !!userOption?.value}
        user={userOption}
      >
        <Typography variant="body2" color="textSecondary">
          {userOption?.value || userOption?.email || 'No email found'}
        </Typography>
      </UserLink>
    </Box>
  );
};
