import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useFormikContext, ErrorMessage } from 'formik';
import {
  Box,
  Typography,
  Collapse,
  List
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '../../../../../components/Fab';
import { Attachments as AttachmentsComponent } from '../../../../../components/Attachments';
import { Row } from './Row';

export const Attachments = ({ name = 'files', titleProps = {}, onFilesChange = () => {} }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const [ selectedFiles, setSelectedFiles ] = useState(values.files || []);

  const handleFileDelete = (file) => {
    setSelectedFiles(selectedFiles.filter(({ original_filename }) => {
      return original_filename !== file.original_filename;
    }));
  };

  useEffect(() => {
    if (!isEqual(values.files, setSelectedFiles)) {
      setFieldValue(name, selectedFiles);
      onFilesChange(selectedFiles);
    }
  }, [ selectedFiles ]);

  return (
    <div>
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Typography variant="h3" {...titleProps}>Attachments</Typography>
        </Box>

        <AttachmentsComponent selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles}>
          <Fab
            size="small"
            color="primary"
            variant="extended"
            startIcon={<AddIcon/>}
          >
            Add
          </Fab>
        </AttachmentsComponent>

        {errors.files &&
          <Box ml={3}>
            <Typography color="error"><ErrorMessage name="files" /></Typography>
          </Box>
        }
      </Box>

      <Collapse unmountOnExit in={!!selectedFiles?.length} timeout="auto">
        <List disablePadding>
          {selectedFiles.map((file, index) => (
            <Row key={index} file={file} index={index} onFileDelete={handleFileDelete} />
          ))}
        </List>
      </Collapse>
    </div>
  );
};
