import { api } from '../api';

export const fetchSipNumbers = (config) => {
  return api.get('/sip/numbers/vacant', config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const fetchSipUser = (id) => {
  return api.get(`/sip/users/${id}`).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const createSipUser = (data) => {
  return api.post('/sip/users', data).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const updateSipUser = (user) => {
  return api.put(`/sip/users/${user?.id}`, user).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};
