import { createContext, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTheme } from '@material-ui/core';
import { travelModesMap } from './travelModesMap';

export const MapProviderContext = createContext();

export const MapProvider = ({
  address: initialAddress,
  lat,
  lng,
  locationName,
  addresses = [],
  children,
  initialAddressKey,
  onError
}) => {
  const [ selectedAddressKey, setSelectedAddressKey ] = useState(initialAddressKey);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const mapsRef = useRef(null);
  const mapRef = useRef(null);
  const directionsServiceRef = useRef(null);
  const directionsDisplayRef = useRef(null);
  const [ isGoogleApiLoaded, setIsGoogleApiLoaded ] = useState(false);
  const [ address, setAddress ] = useState(initialAddress);
  const [ currentLocation, setCurrentLocation ] = useState(null);
  const [ currentAddress, setCurrentAddress ] = useState(null);
  const [ isRouteNotFound, setIsRouteNotFound ] = useState(true);
  const [ distance, setDistance ] = useState(null);
  const [ duration, setDuration ] = useState(null);
  const [ location, setLocation ] = useState(null);
  const [ center, setCenter ] = useState(null);
  const markerIsInitialized = useRef(false);
  const googleMapRef = useRef(null);
  const lastRouteOptionsRef = useRef({});
  const routePolylineRef = useRef(null);

  const handleMapChange = ({ center }) => {
    if (!markerIsInitialized.current) {
      setLocation(center);
      markerIsInitialized.current = true;
    }

    setCenter(center);
  };

  const goToLocation = () => {
    setCenter(location);
  };

  const changeRoute = (options = {}) => {
    if (isEqual(lastRouteOptionsRef.current, options)) {
      return;
    }

    lastRouteOptionsRef.current = options;

    directionsServiceRef.current.route({
      origin: currentLocation,
      destination: location,
      travelMode: travelModesMap.driving,

      ...options
    }, (response, status) => {
      routePolylineRef.current?.setMap(null);

      if (status === 'OK') {
        routePolylineRef.current = new mapsRef.current.Polyline({
          path: response.routes[0].overview_path,
          strokeColor: theme.palette.primary.main,
          strokeOpacity: 0.8,
          strokeWeight: 4
        });

        setCurrentAddress(response.routes?.[0]?.legs?.[0]?.start_address);
        setDistance(response.routes?.[0]?.legs?.[0]?.distance);
        setDuration(response.routes?.[0]?.legs?.[0]?.duration);
        directionsDisplayRef.current.setDirections(response);
        routePolylineRef.current.setMap(mapRef.current);
        setIsRouteNotFound(false);
      } else {
        setIsRouteNotFound(true);
      }
    });
  };

  const handleGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    mapsRef.current = maps;
    directionsServiceRef.current = new maps.DirectionsService();
    directionsDisplayRef.current = new maps.DirectionsRenderer();

    setIsGoogleApiLoaded(true);
  };

  useEffect(() => {
    if (isGoogleApiLoaded && currentLocation && location) {
      changeRoute({
        origin: currentLocation,
        destination: location
      });
    }
  }, [ isGoogleApiLoaded, currentLocation, location ]);

  useEffect(() => {
    const address = addresses.find(({ key }) => key === selectedAddressKey);

    if (address) {
      setLocation({
        lat: +address.location.lat,
        lng: +address.location.lng
      });
    }
  }, [ selectedAddressKey ]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setCurrentLocation({
          lat: coords.latitude,
          lng: coords.longitude
        });
      });
    }

    if (lat && lng) {
      const location = {
        lat: +lat,
        lng: +lng
      };

      setCenter(location);
      setLocation(location);

      return;
    }

    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}`, {
      params: { key: import.meta.env.PUBLIC_GOOGLE_API_KEY }
    }).then(({ data }) => {
      if (data.status === 'OK') {
        const location = data.results?.[0]?.geometry?.location;

        setCenter(location);
        setLocation(location);
      } else {
        enqueueSnackbar('Address no found or something went wrong...', { variant: 'error'  });
        onError();
      }
    });
  }, []);

  return (
    <MapProviderContext.Provider
      value={{
        isRouteNotFound,
        distance,
        duration,
        address,
        locationName,
        currentLocation,
        currentAddress,
        location,
        center,
        googleMapRef,
        addresses,

        handleMapChange,
        goToLocation,
        handleGoogleApiLoaded,
        changeRoute,
        onSelectedAddressChange: setSelectedAddressKey,
        onAddressChange: setAddress
      }}
    >
      {children}
    </MapProviderContext.Provider>
  );
};
