import { useEffect } from 'react';
import { Box, Dialog, SvgIcon, useMediaQuery } from '@material-ui/core';
import { InvoicesContextProvider } from '../../../app/Dashboard/BillingPage/Invoices/InvoicesContext';
import { FiltersBar } from '../../../app/Dashboard/BillingPage/Invoices/InvoicesPage';
import { Grid } from '../../../app/Dashboard/BillingPage/Invoices/InvoicesPage/InvoicesContent/Grid';
import { List } from '../../../app/Dashboard/BillingPage/Invoices/InvoicesPage/InvoicesContent/List';
import { filtersKeysMap } from '../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import InvoiceSvg from '../../icons/invoice-icon.svg?react';
import { ModalBody, ModalContainer, ModalHeader, ModalHeaderActions } from '../../Modal';

export const InvoicesListModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    toggleFiltersBar
  } = useMobileFilterCollapse();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  const handleInvoiceSelect = (invoice) => {
    handleModalResolve(invoice);
  };

  useEffect(() => {
    toggleFiltersBar();
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader
          icon={<SvgIcon fontSize="small"><InvoiceSvg /></SvgIcon>}
          onClose={handleModalReject}
        >
          Choose Invoice

          <ModalHeaderActions>
            {isTablet && filterOpenButton}
          </ModalHeaderActions>
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <InvoicesContextProvider>
            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                <FiltersBar
                  disableInitialFilters
                  filterKey={filtersKeysMap.invoices_modal}
                />
              </FilterBarWrapper>
            </ScrollWrapper>

            <Box height="100%" display="flex" flexDirection="column" minHeight={500}>
              {!isMobile
                ? <List onInvoiceSelect={handleInvoiceSelect} />
                : <Grid onInvoiceSelect={handleInvoiceSelect} />
              }
            </Box>
          </InvoicesContextProvider>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
