import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_TRANSCRIBES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_TRANSCRIBES_SUCCESS]: (
    { filter, transcribes, ...state },
    { data: newFaxes, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      transcribes: (page > 1 ?
        transcribes.filter(({ id }) => !newFaxes.find((loadedEmail) => id === loadedEmail.id)).concat(newFaxes)
        :
        newFaxes
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_TRANSCRIBES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_TRANSCRIBE]: (state, updatedTranscribe) => {
    return {
      ...state,

      transcribes: state.transcribes.map((transcribe) => {
        return transcribe.id === updatedTranscribe.id ? updatedTranscribe : transcribe;
      })
    };
  },

  [types.DELETE_TRANSCRIBE]: (state, transcribeID) => {
    const { pagination, filter, transcribes } = state;
    const filteredTranscribes = transcribes.filter(({ id }) => transcribeID !== id);

    if (filteredTranscribes.length === transcribes.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((transcribes.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      transcribes: filteredTranscribes
    };
  }
});
