import { useFormikContext } from 'formik';
import { Box, Button, makeStyles } from '@material-ui/core';
import { SimpleSelect, EmailsSelect } from '../../../../../components/FormField';
import { getSignature } from '../signatureHelpers';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmailsConfigSettings = ({
  originalSignature = '',
  accounts = [],
  accountsOptions,
  ccFieldVisible,
  bccFieldVisible,
  onCcFieldVisibleUpdate = () => {},
  onBccFieldVisibleUpdate = () => {}
}) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const handleChangeConfig = (event) => {
    const option = accountsOptions?.find(({ value }) => value === event.target.value);

    setFieldValue('body', getSignature(option?.data?.signature_line?.html) + originalSignature);
  };

  return (
    <>
      {!!accounts?.length &&
        <SimpleSelect
          name="config_id"
          label="From"
          options={accountsOptions}
          onChange={handleChangeConfig}
        />
      }

      <Box display="flex" alignItems="flex-end">
        <Box flexGrow={1}>
          <EmailsSelect
            required
            multiple
            name="to"
            label="To"
            margin="dense"
          />
        </Box>

        {!ccFieldVisible &&
          <Box mx={1}>
            <Button
              color="primary"
              className={classes.fieldToggleButton}
              onClick={onCcFieldVisibleUpdate}
            >
              CC
            </Button>
          </Box>
        }

        {!bccFieldVisible &&
          <Button
            color="primary"
            className={classes.fieldToggleButton}
            onClick={onBccFieldVisibleUpdate}
          >
            BCC
          </Button>
        }
      </Box>

      {ccFieldVisible &&
        <EmailsSelect
          multiple
          name="cc"
          label="CC"
          margin="dense"
        />
      }

      {bccFieldVisible &&
        <EmailsSelect
          multiple
          isCreatable
          name="bcc"
          label="BCC"
          margin="dense"
        />
      }
    </>
  );
};
