import { SvgIcon, Box } from '@material-ui/core';
import ActivityIcon from '../../../../../../../components/icons/activity.svg?react';
import { ActivityProvider } from '../../../../../../../components/Activities';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { List } from '../../../CaseActivity';

export const Activity = ({
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      title="Activity"
      leftActions={[
        <SvgIcon color="inherit" fontSize="small">
          <ActivityIcon/>
        </SvgIcon>
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          type={props?.item?.i}
          page={page}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <ActivityProvider filter={{ model: 'case', model_id: caseItem.id }}>
        <Box height={height || '100%'}>
          <List parentType="case" parent={caseItem} />
        </Box>
      </ActivityProvider>
    </ContentCard>
  );
};
