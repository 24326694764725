import { getGeolocation } from '../../utils/getGeolocation';
import { api } from '../api';

export const resetPassword = (data) => {
  return api.post('/password/reset', data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const confirmPassword = async (data) => {
  const { geolocation } = await getGeolocation();

  return api.post('/password/confirm', {
    ...geolocation,
    ...data
  })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const resendRegister = (userID) => {
  return api.post(`/users/${userID}/confirmation-resend`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};
