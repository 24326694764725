import { useContext } from 'react';
import moment from 'moment';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import { Badge } from '../../../../../../../../../components/Badge';
import { IconButton } from '../../../../../../../../../components/IconButton';
import { SvgIcon } from '../../../../../../../../../components/SvgIcon';
import DocumentSvg from '../../../../../../../../../components/icons/mimetypes/document.svg?react';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { DocumentsContext } from '../../../../Documents/DocumentsProvider';
import { SignedUsers } from '../../../../Documents/SignedUsers';
import { DocumentsModal } from '../../../../Documents/DocumentsModal';
import { columnsWidths, minRowHeight } from '../MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ document }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { fetchDocuments } = useContext(DocumentsContext);

  const openDocumentModal = () => {
    openModal(DocumentsModal, {
      payload: {
        documentID: document.id,
        fetchDocuments
      }
    });
  };

  return (
    <ListRow minHeight={minRowHeight} className={classes.root}>
      <ListRowCell grow actions flexBasis={columnsWidths.name}>
        <Badge
          invisible={!document.is_signed}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          badgeContent={<CheckCircleIcon className={classes.badgeIcon} />}
          classes={{ badge: classes.badge }}
        >
          <SvgIcon fontSize="large">
            <DocumentSvg />
          </SvgIcon>
        </Badge>

        <Typography noWrap title={document.name}>
          {document.name}
        </Typography>
      </ListRowCell>

      <ListRowCell actions flexBasis={columnsWidths.created_at}>
        <Typography className={classes.date}>
          {moment.unix(document.created_at).format('L')}
        </Typography>

        <Typography color="textSecondary">
          {moment.unix(document.created_at).format('LT')}
        </Typography>
      </ListRowCell>

      <ListRowCell actions flexBasis={columnsWidths.updated_at}>
        <Typography className={classes.date}>
          {moment.unix(document.updated_at).format('L')}
        </Typography>

        <Typography color="textSecondary">
          {moment.unix(document.updated_at).format('LT')}
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.signed_users}>
        <SignedUsers document={document} />
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
        <Tooltip title="Show document">
          <IconButton color="primary" onClick={openDocumentModal}>
            <EyeIcon />
          </IconButton>
        </Tooltip>
      </ListRowCell>
    </ListRow>
  );
};
