import { Box, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as insurancesApi from '../../../../api/profile/insurances';
import { AddInsuranceInfoModal } from '../../../../app/Dashboard/ProfilePage/insurance';
import { useModal } from '../../../ModalsProvider';
import { ShortInsuranceInfo } from '../../../users/ShortInsuranceInfo';
import { Autocomplete } from '../../Autocomplete';

const useStyles = makeStyles({
  input: {
    '& .MuiInput-input': {
      opacity: 0
    },

    '&.Mui-focused .MuiInput-input': {
      opacity: 1
    }
  },

  startAdornment: {

  }
});

const fetchInsurances = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return insurancesApi.fetchInsurances({
    params: {
      search,
      page: page + 1,
      only_active: 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => option?.isCreatableOption ? (
  <>
    <Box display="flex" mr={1}>
      <AddIcon color="primary" />
    </Box>

    Add insurance
  </>
) : (
  <ShortInsuranceInfo insurance={option} />
);

const getOptionValue = (option) => option?.id;

const getOptionSelected = (option, value) => option.id === value?.id;

export const InsurancesSelect = ({
  multiple = false,
  params = {},
  user,
  insuranceInfo = {},

  ...props
}) => {
  const { openModal } = useModal();
  const classes = useStyles();

  const handleCreate = () => {
    return new Promise((resolve, reject) => {
      openModal(AddInsuranceInfoModal, {
        DialogProps: {
          disableRestoreFocus: true
        },
        payload: {
          user,
          insuranceInfo,
          disableScan: params?.disableScan,
          status_type: params?.status_type || null,
          type_insurance: params.type_insurance,
          medical_id: params.medical_id
        },
        onModalResolved: resolve,
        onModalRejected: reject
      });
    });
  };

  return (
    <Autocomplete
      isAsync
      multiple={multiple}
      label="Insurance"
      placeholder="Select insurance..."
      getOptionValue={getOptionValue}
      getOptionLabel={() => null}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      onNeedFetch={fetchInsurances(params)}
      onCreate={handleCreate}
      getInputProps={(value) => !(!multiple && value) ? null : ({
        className: value && classes.input,

        startAdornment: (!multiple && value) && (
          <ShortInsuranceInfo insurance={value} />
        )
      })}

      {...props}
    />
  );
};
