export const fieldsKeysMap = {
  search: 'search',
  status: 'status',
  office_id: 'office_id',
  assignee_id: 'assignee_id',
  creator_id: 'creator_id',
  category: 'category'
};

export const filterLabels = {
  [fieldsKeysMap.search]: 'Search',
  [fieldsKeysMap.status]: 'Status',
  [fieldsKeysMap.assignee_id]: 'Assignee',
  [fieldsKeysMap.office_id]: 'Office',
  [fieldsKeysMap.creator_id]: 'Creator',
  [fieldsKeysMap.category]: 'Category'
};
