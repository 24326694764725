import { useContext } from 'react';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { Badge } from '../../../../../../../../components/Badge';
import { IconButton } from '../../../../../../../../components/IconButton';
import { SvgIcon } from '../../../../../../../../components/SvgIcon';
import DocumentSvg from '../../../../../../../../components/icons/mimetypes/document.svg?react';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { Popper } from '../../../../../../../../components/Popper';
import { DocumentsModal } from '../../DocumentsModal';
import { DocumentsContext } from '../../DocumentsProvider';
import { SignedUsers } from '../../SignedUsers';
import { DocumentsMenu } from '../DocumentsMenu';
import { columnsWidths, minRowHeight } from '../MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ document }) => {
  const classes = useStyles();
  const { selectedIDs, fetchDocuments, toggleItemSelection } = useContext(DocumentsContext);
  const isSelected = selectedIDs.indexOf(document.id) !== -1;
  const { openModal } = useModal();

  const handleItemSelection = () => {
    toggleItemSelection(document.id);
  };

  const openDocumentModal = () => {
    openModal(DocumentsModal, {
      payload: {
        documentID: document.id,
        fetchDocuments
      }
    });
  };

  return (
    <ListRow button minHeight={minRowHeight} onClick={openDocumentModal}>
      <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />

      <ListRowCell grow actions flexBasis={columnsWidths.name}>
        <Badge
          invisible={!document.is_signed}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          badgeContent={<CheckCircleIcon className={classes.badgeIcon} />}
          classes={{ badge: classes.badge }}
        >
          <SvgIcon fontSize="large">
            <DocumentSvg />
          </SvgIcon>
        </Badge>

        <Typography noWrap title={document.name}>
          {document.name}
        </Typography>
      </ListRowCell>

      <ListRowCell actions flexBasis={columnsWidths.created_at}>
        <Typography variant="h5">
          {moment.unix(document.created_at).format('L')}
        </Typography>

        <Typography color="textSecondary">
          {moment.unix(document.created_at).format('LT')}
        </Typography>
      </ListRowCell>

      <ListRowCell actions flexBasis={columnsWidths.updated_at}>
        <Typography variant="h5">
          {moment.unix(document.updated_at).format('L')}
        </Typography>

        <Typography color="textSecondary">
          {moment.unix(document.updated_at).format('LT')}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.signed_users}>
        <SignedUsers document={document} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
              <MoreVertIcon/>
            </IconButton>
          )}
        >
          {({ handleClose }) => (
            <DocumentsMenu document={document} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
