import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import moment from 'moment';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Date } from '../../components/Date';
import * as eventsApi from '../../api/schedule-events';
import LogoSVG from '../Dashboard/Layout/Sidebar/SidebarContent/logo.svg?react';
import { Loader } from '../../components/Loader';
import { Divider } from '../../components/Divider';
import { Page, PageBody } from '../../components/Page';
import { ConfirmationSection } from './ConfirmationSection';

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    padding: spacing(1.5, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.grey[600]
  },

  logo: {
    width: 64,
    verticalAlign: 'bottom'
  }
}));

export const EventConfirmationPage = () => {
  const classes = useStyles();
  const [ eventData, setEventData ] = useState({});
  const [ isLoading, setIsLoading ] = useState(true);
  const { token } = useParams();
  const isExpired = moment().isAfter(moment(eventData?.expired_at));
  const event = eventData?.event || {};

  const handleConfirm = (confirmation) => {
    setEventData((data) => ({ ...data, confirmation }));
  };

  useEffect(() => {
    eventsApi.fetchEventData(token).then((data) => {
      setEventData(data);
      setIsLoading(false);
    });
  }, []);

  return (
    <Page>
      <Loader loading={isLoading} p={2} render={
        () => (
          <PageBody fullHeight disablePaddings>
            <div className={classes.header}>
              <NavLink to="/">
                <LogoSVG className={classes.logo} />
              </NavLink>
            </div>

            <Box maxWidth={600} mx="auto">
              <Box my={4}>
                {!isExpired && (
                  <Box mt={1.5}>
                    <Typography variant="h4">You have a new event</Typography>
                  </Box>
                )}
              </Box>

              <Typography variant="h2">{event.title}</Typography>
              <Typography dangerouslySetInnerHTML={{ __html: event.description }} />

              <Divider gutter={1} />

              <Box py={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <Typography noWrap variant="h4">
                      When:
                    </Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <Date noWrap variant="h4" date={event.started_at} format="L, LT" />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography noWrap variant="h4">
                      Finish:
                    </Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <Date noWrap variant="h4" date={event.finished_at} format="L, LT" />
                  </Grid>
                </Grid>
              </Box>

              <Divider gutter={1} />

              <ConfirmationSection
                isExpired={isExpired}
                event={event}
                token={token}
                onConfirm={handleConfirm}
              />
            </Box>
          </PageBody>
        )}
      />
    </Page>
  );
};
