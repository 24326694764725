import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Dialog, Button, InputAdornment, IconButton, SvgIcon } from '@material-ui/core';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { getFileNameWithoutExtension } from '../../../../helpers/files';
import { Yup } from '../../../../utils/validation';
import { setFilesLastGlobalAction } from '../../../../store/globalActions';
import * as filesApi from '../../../../api/files';
import EditSvg from '../../../../components/icons/edit.svg?react';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../components/Modal';
import { TextField } from '../../../../components/FormField';
import { Loader } from '../../../../components/Loader';
import { VerticalDivider } from '../../../../components/VerticalDivider';
import * as filesActionTypes from '../FilesContext/types';

export const RenameFileModal = ({
  DialogProps,
  payload: { file },
  handleModalResolve,
  handleModalReject
}) => {
  const dispatch = useDispatch();
  const initialName = getFileNameWithoutExtension(file.original_filename);
  const { enqueueSnackbar } = useSnackbar();

  const resetFileName = (setFieldValue) => () => {
    setFieldValue('original_filename', initialName);
  };

  const renameFile = ({ original_filename }, { setErrors }) => {
    file.original_filename = original_filename + '.' + file.extension;

    return filesApi.renameFile(file).then(() => {
      dispatch(setFilesLastGlobalAction({ type: filesActionTypes.UPDATE_FILE, payload: file }));
      handleModalResolve();
    }).catch(({ status, data: { errors } }) => {
      if (status === 422) {
        setErrors({
          ...errors,

          original_filename: errors.extension || errors.original_filename
        });
      } else if (status === 409) {
        setErrors({
          original_filename: [ 'File with same name is now exist' ]
        });
      } else {
        enqueueSnackbar('Something went wrong. Try again later...', {
          variant: 'error'
        });
      }
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ original_filename: initialName }}
        validationSchema={Yup.object().shape({ original_filename: Yup.string().required() })}
        onSubmit={renameFile}
      >
        {({ values: { original_filename }, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject} icon={<SvgIcon><EditSvg /></SvgIcon>}>
              Rename file
            </ModalHeader>

            <ModalBody>
              <TextField
                name="original_filename"
                label="New name"
                placeholder="Enter file name..."
                margin="dense"
                InputProps={{
                  autoFocus: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <VerticalDivider />

                      <IconButton
                        title="Reset"
                        disabled={original_filename === initialName}
                        onClick={resetFileName(setFieldValue)}
                      >
                        <SettingsBackupRestoreIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </ModalBody>

            <ModalFooter>
              <Loader loading={false} surface={true} render={
                () => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={false}
                    onClick={handleModalReject}
                  >
                    Skip
                  </Button>
                )}
              />

              <Loader  surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || original_filename === initialName}
                  >
                    Rename
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
