import { Grid, Typography } from '@material-ui/core';
import { Label } from '../../../../../components/Label';
import { OfficeLink } from '../../../../../components/OfficeLink';
import { statusColorsMap } from '../../../../../components/support/statusesOptions';
import { UserLink } from '../../../../../components/UserLink';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {!!params.search &&
        <>
          <Grid item xs={4}>
            <Typography>
              Search:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.search || '-'}
            </Typography>
          </Grid>
        </>
      }

      {!!params?.status &&
        <>
          <Grid item xs={4}>
            <Typography>
              Status:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Label label={params?.status?.label} color={statusColorsMap[params?.status?.value]} />
          </Grid>
        </>
      }

      {!!params?.category &&
        <>
          <Grid item xs={4}>
            <Typography>
              Category:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.category?.label || '-'}
            </Typography>
          </Grid>
        </>
      }

      {!!params?.office_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Office:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <OfficeLink office={params?.office_id} />
          </Grid>
        </>
      }

      {!!params?.creator_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Creator:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <UserLink user={params?.creator_id} />
          </Grid>
        </>
      }

      {!!params?.assignee_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Assignee:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <UserLink user={params?.assignee_id} />
          </Grid>
        </>
      }
    </Grid>
  );
};
