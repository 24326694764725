export const filtersKeysMap = {
  appointments_book: 'appointments_book',
  appointments_waiting_list: 'appointments_waiting_list',
  fax_history: 'fax_history',
  call_reports: 'call_reports',
  call_reports_cases: 'call_reports_cases',
  call_reports_users: 'call_reports_users',
  call_reports_profile: 'call_reports_profile',
  call_reports_modal: 'call_reports_modal',
  work_sessions: 'work_sessions',
  patients_main: 'patients_main',
  patients_workers: 'patients_workers',
  patient_medical_forms: 'patient_medical_forms',
  patient_medical_forms_unconfirmed: 'patient_medical_forms_unconfirmed',
  patient_medical_forms_confirmed: 'patient_medical_forms_confirmed',
  medical_info_claims: 'medical_info_claims',
  members_appointments: 'members_appointments',
  member_files: 'member_files',
  claims_select_modal: 'claims_select_modal',
  cases_main: 'cases_main',
  cases_appointments: 'cases_appointments',
  cases_timetracks: 'cases_timetracks',
  cases_payments_received: 'cases_payments_overdraft',
  cases_tasks: 'cases_tasks',
  cases_payments_overdraft: 'cases_payments_overdraft',
  calendar_appointments: 'calendar_appointments',
  calendar_schedule_main: 'calendar_schedule_main',
  files_myFiles: 'files_myFiles',
  files_recent: 'files_recent',
  files_shared: 'files_shared',
  contacts_companies: 'contacts_companies',
  contacts_offices: 'contacts_offices',
  contacts_users: 'contacts_users',
  billing_dashboard_main: 'billing_dashboard_main',
  billing_claims_main: 'billing_claims_main',
  emails_saved: 'emails_saved',
  emails_main: 'emails_main',
  billing_invoices: 'billing_invoices',
  billing_expenses: 'billing_expenses',
  billing_payments_main: 'billing_payments_main',
  billing_payments_received: 'billing_payments_received',
  billing_payments_overdraft: 'billing_payments_overdraft',
  billing_payments_history: 'billing_payments_history',
  users_main: 'users_main',
  profile_patients: 'profile_patients',
  profile_time_tracking: 'profile_time_tracking',
  profile_files: 'profile_files',
  profile_schedule: 'profile_schedule',
  profile_time_reports: 'profile_time_reports',
  time_tracking_widget: 'time_tracking_widget',
  tasks_main: 'tasks_main',
  tasks_widget: 'tasks_widget',
  tasks_assign_modal: 'tasks_assign_modal',
  modal_case_schedule: 'modal_case_schedule',
  diagnosis: 'diagnosis',
  prescriptions: 'prescriptions',
  immunizations: 'immunizations',
  medication_list: 'medication_list',
  tests: 'tests',
  clinical_notes: 'clinical_notes',
  surgeries_procedures: 'surgeries_procedures',
  mri: 'mri',
  weight_history: 'weight_history',
  consultation: 'consultation',
  codes_cpt: 'codes_cpt',
  codes_icd: 'codes_icd',
  codes_pos: 'codes_pos',
  codes_ndc: 'codes_ndc',
  codes_modifier: 'codes_modifier',
  codes_body_part: 'codes_body_part',
  timeReports: 'timeReports',
  transcribes: 'transcribes',
  invoices_modal: 'invoices_modal',
  expenses_modal: 'expenses_modal',
  schedule_events_field: 'schedule_events_field',
  activity: 'activity',
  support_tickets: 'support_tickets'
};
