import { useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Grid, SvgIcon, Typography, Hidden } from '@material-ui/core';
import {
  mdiAccountCheck,
  mdiHeartPulse,
  mdiThermometerLines,
  mdiWater,
  mdiWeightKilogram,
  mdiWeightPound
} from '@mdi/js';
import { Icon } from '@mdi/react';
import PressureSvg from '../../../../../../../components/icons/medical/pressure-icon.svg?react';
import { getStatus } from '../../../../../../../components/medical/forms/CreateMeasurementsModal/statusMap';
import { getHeightFormat } from '../../../../../../../helpers/generateHeight';
import {
  bmiTypes,
  bmiTypesColors,
  bmiTypesNames,
  getCategoryBMI,
  measureTypes
} from '../../../../../../../helpers/measureDataMap';
import { UserContext } from '../../../../../Members/User/UserProvider';
import { Label } from '../../../../../TasksPage/MainWindow/Label';
import BodySVG from '../../../MainInfo/LeftSidebar/Measurements/MainContent/body-black.svg?react';
import { CategorySlider } from '../../../MainInfo/LeftSidebar/Measurements/CategorySlider';

export const Body = ({ birthday, measurements }) => {
  const status = getStatus(measurements.systolic_blood_pressure || 0);
  const userContext = useContext(UserContext);
  const profile = useSelector(({ profile }) => profile);
  const user = userContext ? userContext.user : profile.user;
  const category = measurements.bmi > 0
    ? bmiTypesNames[getCategoryBMI(measurements.bmi)]
    : bmiTypesNames[bmiTypes.underweight];
  const color = measurements.bmi > 0
    ? bmiTypesColors[getCategoryBMI(measurements.bmi)]
    : bmiTypesColors[bmiTypes.underweight];
  const height = getHeightFormat({
    isFeet: measurements?.height_unit === measureTypes.feet
  })(measurements.height);
  const age = birthday || user?.birthday
    ? moment().diff(moment(birthday || user.birthday), 'years')
    : '-';

  return (
    <>
      <Grid container item sm={6} xs={12} spacing={1} alignItems="center">
        <Grid item xs={5}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography color="textSecondary">
              {`Weight (${measurements.weight_unit || 'lbs'})`}
            </Typography>

            <Box component="span" color="grey.600" fontSize={20} ml={1}>
              <SvgIcon fontSize="inherit" color="inherit">
                <Icon
                  path={measurements.weight_unit === measureTypes.kg
                    ? mdiWeightKilogram
                    : mdiWeightPound
                  }
                />
              </SvgIcon>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {measurements.weight || 0}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography color="textSecondary">
              {`Height (${measurements.height_unit || 'feet'})`}
            </Typography>

            <Box component="span" ml={1} color="grey.600" fontSize={30}>
              <SvgIcon color="inherit" fontSize="inherit">
                <BodySVG />
              </SvgIcon>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {height || 0}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography color="textSecondary">Age</Typography>

            <Box component="span" color="grey.600" fontSize={20} ml={1}>
              <SvgIcon fontSize="inherit" color="inherit">
                <SvgIcon fontSize="inherit" color="inherit">
                  <Icon path={mdiAccountCheck} />
                </SvgIcon>
              </SvgIcon>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">{age}</Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography color="textSecondary">BMI</Typography>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Typography variant="h5">
              {measurements.bmi > 0 ? +measurements.bmi : 0}
            </Typography>

            <Box ml={2}>
              <Typography variant="h5">
                <Box component="span">
                  <Label labelClass={getCategoryBMI(measurements.bmi)} name={category} color={color} />
                </Box>
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Hidden smUp>
          <Grid item xs={12}>
            <CategorySlider disableTitle color={color} categoryValue={measurements.bmi > 0 ? +measurements.bmi : 0} />
          </Grid>
        </Hidden>
      </Grid>

      <Grid container item sm={6} xs={12} spacing={1} alignItems="center">
        <Grid item xs={5}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography color="textSecondary">Body temperature</Typography>

            <Box component="span" color="grey.600" fontSize={20} ml={1}>
              <SvgIcon fontSize="inherit" color="inherit">
                <Icon path={mdiThermometerLines} />
              </SvgIcon>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {measurements.temperature || 0} &deg;F
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography color="textSecondary">Blood type</Typography>

            <Box component="span" color="grey.600" fontSize={20} ml={1}>
              <SvgIcon fontSize="inherit" color="inherit">
                <Icon path={mdiWater} />
              </SvgIcon>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {measurements.blood_type || 0}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography color="textSecondary">Heart rate (bmp)</Typography>

            <Box component="span" color="grey.600" fontSize={20} ml={1}>
              <SvgIcon fontSize="inherit" color="inherit">
                <Icon path={mdiHeartPulse} />
              </SvgIcon>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {measurements.heart_rate || 0}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography color="textSecondary">Pressure  sys/dia (mmHg)</Typography>

            <Box component="span" color="grey.600" fontSize={20} ml={1}>
              <SvgIcon fontSize="inherit" color="inherit">
                <PressureSvg />
              </SvgIcon>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">
              {`${measurements.systolic_blood_pressure || 0}/${measurements.diastolic_blood_pressure || 0}`}
            </Typography>

            {measurements.systolic_blood_pressure > 0 &&
              <Box component="span">
                <Label name={status.label} labelClass={status.value} />
              </Box>
            }
          </Box>
        </Grid>
      </Grid>

      <Hidden only="xs">
        <Grid item xs={12}>
          <CategorySlider disableTitle color={color} categoryValue={measurements.bmi > 0 ? +measurements.bmi : 0} />
        </Grid>
      </Hidden>
    </>
  );
};
