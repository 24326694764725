export const typesMap = {
  summary: 'summary',
  analyze: 'analyze',
  custom: 'custom'
};

export const typeLabelsMap = {
  [typesMap.summary]: 'Summary',
  [typesMap.analyze]: 'Analyze',
  [typesMap.custom]: 'Custom'
};

export const typesOptions = [
  {
    value: typesMap.summary,
    label: typeLabelsMap[typesMap.summary]
  },
  {
    value: typesMap.analyze,
    label: typeLabelsMap[typesMap.analyze]
  },
  {
    value: typesMap.custom,
    label: typeLabelsMap[typesMap.custom]
  }
];
