import moment from 'moment';
import * as workClockApi from '../../../api/work-clocks';
import { getGeolocation } from '../../../utils/getGeolocation';
import { store } from '../../store';
import { enqueueSnackbar } from '../../snackbars';
import * as actions from './actions';

let interval = null;

export const startTimer = (dispatch) => (data) => {
  dispatch(actions.workClockStartSuccess(data));

  clearInterval(interval);

  dispatch(actions.workClockTick());

  interval = setInterval(() => {
    const sessionStartedAt = store.getState()?.workClock?.workClock?.session?.started_at;
    const timerMoreThanLimit = moment().diff(moment.unix(sessionStartedAt), 'hours') === 24;

    if (timerMoreThanLimit) {
      clearInterval(interval);
      dispatch(actions.workClockStopSuccess());
      dispatch(enqueueSnackbar('Work session was automatically terminated after 24 hours', { variant: 'warning' }));

      return;
    }

    dispatch(actions.workClockTick());
  }, 1000);
};

export const workClockFetch = (payload) => (dispatch) => {
  dispatch(actions.workClockFetchRequest());

  return workClockApi.fetchTimer(payload).then(({ data }) => {
    if (data.is_active) {
      startTimer(dispatch)(data);
    } else {
      dispatch(actions.resetWorkClock());
    }
  });
};

export const workClockStart = (payload) => async (dispatch) => {
  dispatch(actions.workClockStartRequest());

  const { geolocation } = await getGeolocation();

  return workClockApi.startTimer({
    ...payload,
    ...geolocation
  }).then((session) => {
    const workClock = {
      is_active: true,
      session
    };

    startTimer(dispatch)(workClock);
  });
};

export const workClockStop = () => async (dispatch) => {
  dispatch(actions.workClockStopRequest());

  const { geolocation } = await getGeolocation();

  return workClockApi.stopTimer(geolocation).then(() => {
    dispatch(actions.workClockStopSuccess());
    clearInterval(interval);
  });
};
