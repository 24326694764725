import { useEffect } from 'react';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { CardItem } from '../../../../../../components/Cards';
import { Date } from '../../../../../../components/Date';
import { IconButton } from '../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../components/Tooltip';
import { FileTypeIcon } from '../../../../files-common';
import { transcribeStatusLabelsMap } from '../../transcribeStatusesMap';
import { useTranscribe } from '../../useTranscribe';

export const Card = ({
  item: transcribe = {},
  isLoaded,
  recalculateHeight
}) => {
  const {
    text,
    openAudioFilePreview,
    openTextFilePreview,
    editTranscribe
  } = useTranscribe(transcribe);

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return (
    <CardItem disableHover py={1} px={2}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Date:
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Date disableIcon date={transcribe.created_at} format="L LT" />
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Status:
          </Typography>
        </Grid>

        <Grid item xs={8} variant="subtitle1">
          <Typography variant="body1">{transcribeStatusLabelsMap[transcribe.status]}</Typography>
        </Grid>

        <Grid item container wrap="nowrap" alignItems="center">
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Text:
            </Typography>
          </Grid>

          <Grid item xs style={{ overflow: 'hidden' }}>
            {!transcribe.text
              ? '-'
              : <Link noWrap component="div" onClick={openTextFilePreview}>{transcribe.text}</Link>
            }
          </Grid>

          <Grid item>
            <Tooltip isExistTooltip={!text} title="No text found">
              <div>
                <IconButton disabled={!text} size="small" onClick={editTranscribe}>
                  <EditIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle1">
            File:
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Box display="flex" alignItems="center">
            <FileTypeIcon file={{ aggregate_type: 'audio' }} />

            <Tooltip title={transcribe.filename}>
              <Link noWrap variant="body1" onClick={openAudioFilePreview}>{transcribe.filename}</Link>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </CardItem>
  );
};
