import { Box, Typography, SvgIcon } from '@material-ui/core';
import CalendarSvg from './appointment-icon.svg?react';

export const AllSchedulesEmptyContent = ({ text = null }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      m="0 auto"
      textAlign="center"
      p={2}
    >
      <Box maxWidth={450}>
        {!text && (
          <Typography gutterBottom variant="h4">
            No plans today.
          </Typography>
        )}

        <Box component="span" color="info.main" width={390}>
          <Typography variant="h4" color="inherit">
            {text || 'To Add an Event, in the Account, click on the ‘+ Add’ button or go to the calendar.'}
          </Typography>
        </Box>
      </Box>

      <Box color="text.secondary" fontSize={85}>
        <SvgIcon color="inherit" fontSize="inherit"><CalendarSvg /></SvgIcon>
      </Box>

      {!text && (
        <Typography color="textSecondary">
          Designed to schedule the activities of the participants CaseArk. The system notifies
          about the visit one day and one hour before the appointment. If the user does not show up,
          the system will offer to send him an alert.
        </Typography>
      )}
    </Box>
  );
};
