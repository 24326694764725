import { SvgIcon } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiEye } from '@mdi/js';
import ThermometerIcon from '../../../../../../components/icons/medical/thermometer.svg?react';
import HearingIcon from '../../../../../../components/icons/medical/hearing-icon.svg?react';
import HeartPulsIcon from '../../../../../../components/icons/medical/heart-pulse-icon.svg?react';
import LungsIcon from '../../../../../../components/icons/medical/lungs.svg?react';
import UrinaryTractIcon from '../../../../../../components/icons/medical/urinary-tract.svg?react';
import ShoulderIcon from '../../../../../../components/icons/medical/shoulder-icon.svg?react';
import ChestIcon from '../../../../../../components/icons/medical/chest-icon.svg?react';
import BrainIcon from '../../../../../../components/icons/medical/brain-icon.svg?react';
import PsychiatricIcon from '../../../../../../components/icons/medical/psychiatric-icon.svg?react';
import BloodCellsIcon from '../../../../../../components/icons/medical/blood-cells-icon.svg?react';
import ImmunityIcon from '../../../../../../components/icons/medical/immunity-icon.svg?react';
import ThyroidGlandIcon from '../../../../../../components/icons/medical/thyroid-gland-icon.svg?react';

export const symptomsTypes = {
  constitutional: 'constitutional',
  eye_problems: 'eye_problems',
  ear_nose_throat: 'ear_nose_throat',
  cardiovascular: 'cardiovascular',
  respiratory: 'respiratory',
  genitourinary: 'genitourinary',
  musculoskeletal: 'musculoskeletal',
  skin_breast: 'skin_breast',
  neurological: 'neurological',
  psychiatric: 'psychiatric',
  hematological: 'hematological',
  immunologic: 'immunologic',
  endocrine: 'endocrine'
};

export const symptomsTypesList = [
  symptomsTypes.constitutional,
  symptomsTypes.eye_problems,
  symptomsTypes.ear_nose_throat,
  symptomsTypes.cardiovascular,
  symptomsTypes.respiratory,
  symptomsTypes.genitourinary,
  symptomsTypes.musculoskeletal,
  symptomsTypes.skin_breast,
  symptomsTypes.neurological,
  symptomsTypes.psychiatric,
  symptomsTypes.hematological,
  symptomsTypes.immunologic,
  symptomsTypes.endocrine
];

export const symptomsInjuryTypesList = [
  symptomsTypes.constitutional,
  symptomsTypes.eye_problems,
  symptomsTypes.ear_nose_throat,
  symptomsTypes.cardiovascular,
  symptomsTypes.respiratory,
  symptomsTypes.genitourinary,
  symptomsTypes.musculoskeletal
];

export const symptomsTypesMap = {
  constitutional: {
    name: 'Constitutional',
    icon: <SvgIcon><ThermometerIcon /></SvgIcon>
  },
  eye_problems: {
    name: 'Eye Problems',
    icon: <SvgIcon><Icon path={mdiEye} /></SvgIcon>
  },
  cardiovascular: {
    name: 'Cardiovascular',
    icon: <SvgIcon><HeartPulsIcon /></SvgIcon>
  },
  ear_nose_throat: {
    name: 'Ear/Nose/Throat',
    icon: <SvgIcon><HearingIcon /></SvgIcon>
  },
  respiratory: {
    name: 'Respiratory',
    icon: <SvgIcon><LungsIcon /></SvgIcon>
  },
  genitourinary: {
    name: 'Genitourinary',
    icon: <SvgIcon><UrinaryTractIcon /></SvgIcon>
  },
  musculoskeletal: {
    name: 'Musculoskeletal',
    icon: <SvgIcon><ShoulderIcon /></SvgIcon>
  },
  skin_breast: {
    name: 'Skin & Breast',
    icon: <SvgIcon><ChestIcon /></SvgIcon>
  },
  neurological: {
    name: 'Neurological',
    icon: <SvgIcon><BrainIcon /></SvgIcon>
  },
  psychiatric: {
    name: 'Psychiatric',
    icon: <SvgIcon><PsychiatricIcon /></SvgIcon>
  },
  hematological: {
    name: 'Hematological',
    icon: <SvgIcon><BloodCellsIcon /></SvgIcon>
  },
  immunologic: {
    name: 'Immunologic',
    icon: <SvgIcon><ImmunityIcon /></SvgIcon>
  },
  endocrine: {
    name: 'Endocrine',
    icon: <SvgIcon><ThyroidGlandIcon /></SvgIcon>
  }
};
